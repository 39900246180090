export const slideShowImages = [
  {
    id: 1,
    image:
      "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/Gurenter%20Landlord%20Web%20Promo%20Images%2FNovember%203rd%2C%202024%2FScreenshot%202024-11-03%20at%203.34.26%E2%80%AFPM.png?alt=media&token=55025c2f-2e81-4642-a282-16d5e090dcaa",
    title: "Screening Prospective Tenants",
    description:
      "Screen your prospective tenants with ease with our AI assistant.",
  },
  {
    id: 2,
    image:
      "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/Gurenter%20Landlord%20Web%20Promo%20Images%2FNovember%203rd%2C%202024%2FScreenshot%202024-11-03%20at%203.23.14%E2%80%AFPM.png?alt=media&token=65cf5b35-5bcb-48f8-869c-33ed238ac333",
    title: "Dashboard Overview",
    description: "See your portfolio at a glance.",
  },
  {
    id: 3,
    image:
      "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/Gurenter%20Landlord%20Web%20Promo%20Images%2FNovember%203rd%2C%202024%2FScreenshot%202024-11-03%20at%203.28.52%E2%80%AFPM.png?alt=media&token=96f8cfee-d6e4-4674-95c3-c6b42dc32b5e",
    title: "Manage Contractors",
    description: "Manage your contractors with ease.",
  },
  {
    id: 4,
    image:
      "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/Gurenter%20Landlord%20Web%20Promo%20Images%2FNovember%203rd%2C%202024%2FScreenshot%202024-11-03%20at%203.25.29%E2%80%AFPM.png?alt=media&token=26d37453-1ef4-448e-b043-dba734f548b1",
    title: "Manage Tickets",
    description: "Manage your tickets with ease.",
  },
  {
    id: 5,
    image:
      "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/Gurenter%20Landlord%20Web%20Promo%20Images%2FNovember%203rd%2C%202024%2FScreenshot%202024-11-03%20at%203.26.46%E2%80%AFPM.png?alt=media&token=da6096cc-1b40-4126-a94e-130e9c3c9c26",
    title: "Ticket Details",
    description: "Keep track of tenant requests.",
  },
  {
    id: 6,
    image:
      "https://firebasestorage.googleapis.com/v0/b/gurenter.appspot.com/o/Gurenter%20Landlord%20Web%20Promo%20Images%2FNovember%203rd%2C%202024%2FScreenshot%202024-11-03%20at%203.24.27%E2%80%AFPM.png?alt=media&token=ed92798c-096e-4bf3-8a4f-7347e0279d12",
    title: "Manage Lease Templates",
    description: "Create and manage your lease templates.",
  },
];
