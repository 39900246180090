import http from "../../../services/general/httpService";
import config from "../../../constants/config.json";

///sys_payment_rate_method/get_payment_rate_methods
export async function getPaymentRateMethods() {
      
     const fullApiEndpoint = config.apiEndpoint + `/sys_payment_rate_method/get_payment_rate_methods`;
     const {data: result} = await http.post(fullApiEndpoint);
    
     return result;
}

//get_payment_rate_method_by_id
export async function getPaymentRateMethodById(paymentRateMethodId) {
      
     const fullApiEndpoint = config.apiEndpoint + `/sys_payment_rate_method/get_payment_rate_method_by_id`;
     const {data: result} = await http.post(fullApiEndpoint, {payment_rate_method_id: paymentRateMethodId});
    
     return result;
}