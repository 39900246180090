import React from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Input from '../../../../../../reusable/form/Input';

const CreateTenantManuallyForm = ({
  isDarkMode,
  tenantDetail,
  handleSubmit,
  handleChange,
  errors,
  validate,
  contractDetail,
}) => {
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className='card-body'>
          <div className='live-preview'>
            <div className='row gy-4'>
              {/*Portfolio */}
              <div className='col-xxl-3 col-md-6'>
                <div>
                  <Input
                    name='portfolio_name'
                    label='Portfolio'
                    type={'text'}
                    isDisabled={true}
                    value={contractDetail.portfolio_name}
                    onChange={handleChange}
                    error={errors.portfolio_name}
                  />
                </div>
              </div>

              {/*Property */}

              <div className='col-xxl-3 col-md-6'>
                <div>
                  <Input
                    name='property_name'
                    label='Property'
                    type={'text'}
                    isDisabled={true}
                    value={contractDetail.property_name}
                    onChange={handleChange}
                    error={errors.property_name}
                  />
                </div>
              </div>

              {/*Property Unit */}

              <div className='col-xxl-3 col-md-6'>
                <div>
                  <Input
                    name='property_unit_name'
                    label='Property Unit'
                    type={'text'}
                    isDisabled={true}
                    value={contractDetail.property_unit_name}
                    onChange={handleChange}
                    error={errors.property_unit_name}
                  />
                </div>
              </div>

              <div className='col-xxl-3 col-md-6'>
                <div>
                  <Input
                    name='first_name'
                    label='First Name'
                    type={'text'}
                    value={tenantDetail.first_name}
                    onChange={handleChange}
                    error={errors.first_name}
                  />
                </div>
              </div>

              <div className='col-xxl-3 col-md-6'>
                <div>
                  <Input
                    name='last_name'
                    label='Last Name'
                    type={'text'}
                    value={tenantDetail.last_name}
                    onChange={handleChange}
                    error={errors.last_name}
                  />
                </div>
              </div>
              <div className='col-xxl-3 col-md-6'>
                <div>
                  <Input
                    name='email_address'
                    label='Email Address'
                    type={'text'}
                    value={tenantDetail.email_address}
                    onChange={handleChange}
                    error={errors.email_address}
                  />
                </div>
              </div>

              <div className='col-xxl-3 col-md-6'>
                <div>
                  <Input
                    name='phone_number'
                    label='Phone Number'
                    type={'text'}
                    value={tenantDetail.phone_number}
                    onChange={handleChange}
                    error={errors.phone_number}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer '>
            <div className='col-xxl-3 col-md-6 ms-auto text-end'>
              <button
                disabled={validate()}
                className='btn btn-success'
                type='submit'
              >
                Save Tenant
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default CreateTenantManuallyForm;
