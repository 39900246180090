import React from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../../services/utils/currencyUtils';

const RecentPropRevenueCard = ({ recentRevenue, defaultHouseImg }) => {
  return (
    <div className='card card-body border'>
      <div className='d-flex align-items-start'>
        <div className='flex-shrink-0'>
          <Link
            to={`/propertyunit/${recentRevenue.unit_details.property_unit_id}`}
          >
            <img
              src={defaultHouseImg}
              alt=''
              className='avatar-sm rounded material-shadow'
            />
          </Link>
        </div>
        <div className='flex-grow-1 ms-3'>
          <Link
            to={`/propertyunit/${recentRevenue.unit_details.property_unit_id}`}
          >
            <h6 className='fs-15 text-truncate task-title'>
              {recentRevenue.unit_details.unit_name}
            </h6>
          </Link>
          <div className='d-flex mb-0 align-items-center'>
            <div className='col-3 text-start'>
              <p className='text-muted'>
                <i className='ri-bill-line'></i>{' '}
                {recentRevenue.revenue_category}
              </p>
            </div>

            <div className='col-4 text-end'>
              <p className='text-muted fw-bold'>
                <i className='bx bx-money'></i>{' '}
                {formatCurrency(recentRevenue.revenue)}
              </p>
            </div>
          </div>
          <div className='text-start '>
            <p className='text-muted'>{recentRevenue.revenue_title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentPropRevenueCard;
