class BankAccount {
  constructor() {
this.bank_account_id = '';
this.bank_id = '';
this.currency_id = '';
this.account_name = '';
this.account_type = '';
this.account_subtype = '';
this.account_mask = '';
this.account_id = '';
this.available_balance = 0.0;
this.current_balance = 0.0;
this.is_default_account = false;
this.created_at = new Date();
this.updated_at = new Date();
this.bank_name = '';
this.routing_number = '';
this.routing_numbers = [];
this.logo = '';
this.institution_id = '';
this.url = '';
this.account_number = '';
this.bank_country = '';
  }
}

export default BankAccount;