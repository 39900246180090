import http from "../../../../services/general/httpService";
import config from "../../../../constants/config.json";

//after the tenant application process management is the rent and other fee set up and payment

// /tenant_rental_application/get_all_tenants_for_campaign_id
export async function getAllTenantsForCampaignId(campaignId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_all_tenants_for_campaign_id/${campaignId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//get_all_tenants_for_property_unit_id
export async function getAllTenantsForPropertyUnitId(propertyUnitId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_all_tenants_for_property_unit_id/${propertyUnitId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//get_tenant_for_tenant_id
export async function getTenantForTenantId(tenantId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_tenant_for_tenant_id/${tenantId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//get_tenant_full_application/{campaign_id}/{tenant_id}
//this is the full application for the tenant including the attachments
export async function getTenantFullApplication(campaignId, tenantId) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_tenant_full_application/${campaignId}/${tenantId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//update_tenant_application_status
export async function updateTenantApplicationStatus(updateData) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/update_tenant_application_status`;
  const { data: result } = await http.put(fullApiEndpoint, updateData);
  return result;
}

//analyze_tenant
export async function analyzeTenant(tenantId, campaignId) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenant_rental_application/analyze_tenant`;
  const { data: result } = await http.post(fullApiEndpoint, {
    tenant_id: tenantId,
    campaign_id: campaignId,
  });
  return result;
}

//add_private_notes_to_application
export async function addPrivateNotesToApplication(notes) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/add_private_notes_to_application`;
  const { data: result } = await http.post(fullApiEndpoint, notes);
  return result;
}

//get_all_private_notes_for_tenant_rental_application_id/{tenant_rental_application_id}
export async function getAllPrivateNotesForTenantRentalApplicationId(
  tenantRentalApplicationId
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/get_all_private_notes_for_tenant_rental_application_id/${tenantRentalApplicationId}`;
  const { data: result } = await http.get(fullApiEndpoint);
  return result;
}

//delete_private_note_for_tenant_rental_application_id/{tenant_rental_application_id}/{note_id}
export async function deletePrivateNoteForTenantRentalApplicationId(
  tenantRentalApplicationId,
  noteId
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/tenant_rental_application/delete_private_note_for_tenant_rental_application_id/${tenantRentalApplicationId}/${noteId}`;
  const { data: result } = await http.delete(fullApiEndpoint);
  return result;
}
