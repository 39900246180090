import React from 'react';

const Input = ({
  name,
  label,
  className = 'form-control',
  placeholder,
  labelClass,
  isFocused,
  value,
  onChange,
  type,
  error,
  isDisabled = false,
}) => {
  const handleChange = (event) => {
    if (type === 'date') {
      onChange(event.target.value);
    } else {
      onChange(event);
    }
  };

  const handleClick = (event) => {
    if (type === 'number' && event.target.value == '0') {
      event.target.value = '';
    }
  };

  return (
    <div className='form-group'>
      <label className={labelClass} htmlFor={name}>
        {label}
      </label>

      <input
        autoFocus={isFocused}
        value={value}
        onChange={handleChange}
        onClick={handleClick}
        placeholder={placeholder}
        id={name}
        name={name}
        type={type}
        className={className}
        disabled={isDisabled}
      />

      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default Input;
