import http from "../../../general/httpService";
import config from "../../../../constants/config.json";

///revenue_category/get_revenue_categories

export async function getRevenueCategories() {
      
     const fullApiEndpoint = config.apiEndpoint + `/revenue_category/get_revenue_categories`;
     const {data: result} = await http.get(fullApiEndpoint);
    
     return result;
}

//get_revenue_category
export async function getRevenueCategory(revenue_category_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/revenue_category/get_revenue_category/${revenue_category_id}`;
     const {data: result} = await http.get(fullApiEndpoint);
    
     return result;
}
