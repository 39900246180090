import http from "../../../services/general/httpService";
import config from "../../../constants/config.json";

///property_unit_audit/create_property_unit_audit
export async function createPropertyUnitAudit(audit) {
    const fullApiEndpoint = config.apiEndpoint + `/property_unit_audit/create_property_unit_audit`;
    const {data: result} = await http.post(fullApiEndpoint, audit);

    return result;
}

//get_audit_by_property_unit_id_for_last_7_days
export async function getAuditByPropertyUnitIdForLast7Days(property_unit_id) {
   
    const fullApiEndpoint = config.apiEndpoint + `/property_unit_audit/get_audit_by_property_unit_id_for_last_7_days`;
    const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id});

    return result;
}