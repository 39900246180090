import React from 'react';
import { Link } from 'react-router-dom';

const PropertyUnitCard = ({
  unit,
  defaultHouseImg,
  selectedCurrencyOption,
}) => {
  return (
    <div className='col-sm-2'>
      <div className='card card-body border'>
        <div className='d-flex align-items-start'>
          <div className='flex-shrink-0'>
            <Link to={`/propertyunit/${unit.property_unit_id}`}>
              <img
                src={defaultHouseImg}
                alt=''
                className='avatar-sm rounded material-shadow'
              />
            </Link>
            <h5 className='mb-1 pt-2'>
              {unit.is_rented ? (
                <span className='badge bg-success'>Rented</span>
              ) : (
                <span className='badge bg-danger'>Vacant</span>
              )}
            </h5>
          </div>
          <div className='flex-grow-1 ms-3'>
            <Link to={`/propertyunit/${unit.property_unit_id}`}>
              <h4 className='card-title mb-0 fs-4 fw-medium'>
                {unit.unit_name}
              </h4>
            </Link>
            <div className='d-flex mb-2 align-items-center'>
              <div className='col-2 text-start'>
                <h5 className='mb-1'>
                  <i className='bx bx-bed'></i> {unit.number_of_bedrooms}
                </h5>
              </div>
              <div className='col-2 text-start'>
                <h5 className='mb-1'>
                  <i className='bx bx-bath'></i> {unit.number_of_bathrooms}
                </h5>
              </div>
              <div className='col-3 text-start'>
                <h5 className='mb-1'>
                  <i className='bx bx-ruler'></i> {unit.square_footage}
                </h5>
              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className='col-12 text-start'>
                <h5 className='mb-1'>
                  <i className='bx bx-money'></i>{' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency:
                      selectedCurrencyOption?.data?.currency_code || 'USD',
                  }).format(unit.market_rent_amount)}{' '}
                  {selectedCurrencyOption?.data?.currency_code || 'USD'}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyUnitCard;
