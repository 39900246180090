import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

import { Button } from "react-bootstrap";
import { Tooltip } from "react-bootstrap"; // or whichever tooltip component you're using
import LoaderView from "../../../../../reusable/loading/loaderView";
import { getAdvertisementListingByCampaignId } from "../../../../../../services/propertyUnits/advertisment/advertismentManagement";
import { formatCurrency } from "../../../../../../services/utils/currencyUtils";
import { toast } from "react-toastify";
import PageTitleBox from "../../../../../reusable/title/pageTitleBox";
import { getAllBookedTimeSlotsByCampaignId } from "../../../../../../services/propertyUnits/advertisment/unitViewing/unitViewingManagement";
//add analytics checks here

//preview and statistics

const AdvertisementDetail = ({ isDarkMode }) => {
  const [productionUrl, setProductionUrl] = useState(
    "https://tenant.gurenter.com"
  );
  const [isLoading, setIsLoading] = useState();
  const { campaignId } = useParams();
  const [propertyUnitAdvert, setPropertyUnitAdvert] = useState({
    advert_id: "",
    landlord_id: "",
    property_unit_id: "",
    date_advert_ends: "",
    campaign_id: "",
    property_unit: {
      property_unit_id: "",
      property_id: "",
      market_rent_amount: 0,
      number_of_bathrooms: 0,
      number_of_bedrooms: 0,
      square_footage: 0,
      unit_name: "",
      //new
      number_of_half_bathrooms: "",
      number_of_rooms: "",
    },
    security_deposit: 0,
    screening_pack_id: "",
    currency_id: "",
    currency_name: "",
    is_advert_expired: false,
    rental_unit_price: 0,
    rental_unit_amt_currency: "",
    unit_advert_image_urls: [],
  });

  const [viewingSchedule, setViewingSchedule] = useState([
    {
      unit_viewing_id: "",
      start_time: "",
      end_time: "",
      duration: "",
      campaign_id: "",
      status: "",
      tenant_id: "",
      tenant_name: "",
      note: "",
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    getAdvertDetail();
    fetchBookingSchedule();
    //after loading

    setIsLoading(false);
  }, []);

  const getAdvertDetail = async () => {
    const advertFound = await getAdvertisementListingByCampaignId(campaignId);

    if (advertFound) {
      setPropertyUnitAdvert(advertFound);

      console.log("Advert Found:", advertFound);
    }
  };

  const fetchBookingSchedule = async () => {
    const viewingSchedule = await getAllBookedTimeSlotsByCampaignId(campaignId);

    if (viewingSchedule) {
      console.log("Viewing Schedule:", viewingSchedule);
      setViewingSchedule(viewingSchedule);
    }
  };

  //get the timeslot for the viewing schedule

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {!campaignId ? (
        <div
          className="page-content"
          data-bs-theme={isDarkMode ? "dark" : "light"}
          style={{ paddingBottom: "10%" }}
        >
          <div class="row">
            <div class="col-xl-8" id="card-slide">
              <div className="alert alert-warning" role="alert">
                <h4 className="alert-heading">Missing Campaign ID</h4>
                <p>
                  A Campaign ID is required in the URL to present an active
                  property unit for rent. Please ensure a valid Campaign ID is
                  provided.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="page-content"
          data-bs-theme={isDarkMode ? "dark" : "light"}
          style={{ paddingBottom: "10%" }}
        >
          <PageTitleBox
            pageTitle="Advertisement Details"
            previousPageTitle="My Advertisements"
            previousPageLink={`/myAdvertisements/${propertyUnitAdvert.property_unit_id}`}
          />

          <div class="row">
            <div class="col-xl-4" id="card-slide">
              {/*Carousel */}
              <Carousel data-bs-theme="dark">
                {propertyUnitAdvert &&
                  propertyUnitAdvert.unit_advert_image_urls.map(
                    (image, index) => (
                      <Carousel.Item key={index}>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "#f8f9fa", // Light gray background
                            minHeight: "150px",
                            maxHeight: "400px",
                            width: "100%",
                          }}
                        >
                          <img
                            className="d-block"
                            src={image.url}
                            alt={`Slide ${index + 1}`}
                            style={{
                              maxHeight: "400px",
                              objectFit: "contain",
                              maxWidth: "100%",
                            }}
                          />
                        </div>
                        <Carousel.Caption>
                          <h5>{`Slide ${index + 1}`}</h5>
                        </Carousel.Caption>
                      </Carousel.Item>
                    )
                  )}
              </Carousel>

              <div class="">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex position-relative">
                      <div>
                        <Button
                          hidden
                          size="sm"
                          href="javascript:void(0);"
                          class="btn btn-primary stretched-link"
                        >
                          Share
                        </Button>

                        <h5 class="mt-0" style={{ paddingTop: "1%" }}>
                          {propertyUnitAdvert?.property_unit?.unit_name
                            ?.toUpperCase()
                            .charAt(0) +
                            propertyUnitAdvert?.property_unit?.unit_name?.slice(
                              1
                            )}
                        </h5>
                        <p>
                          The location of the property{" "}
                          <b>
                            {" "}
                            {propertyUnitAdvert?.property_unit?.unit_name?.toUpperCase()}
                          </b>
                          . Will be provided only if you have been approved to
                          view the property. This will be done by an initial
                          screening process.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                        <h6 class="card-title mb-0">
                          {propertyUnitAdvert.property_unit && (
                            <b>
                              {formatCurrency(
                                propertyUnitAdvert.rental_unit_price
                              )}{" "}
                              {propertyUnitAdvert.currency_name} {"/"}
                              {" month"}
                            </b>
                          )}
                        </h6>
                      </div>
                      <div class="flex-shrink-0"></div>
                    </div>
                  </div>
                  <div class="card-body collapse show" id="collapseexample1">
                    <div class="d-flex">
                      <div class="flex-shrink-0">
                        <i class="ri-checkbox-circle-fill text-primary"></i>
                      </div>
                      <div class="flex-grow-1 ms-2 text-muted">
                        <div class="flex-grow-1 ms-2">
                          <p class="text-muted mb-0">Available Until</p>
                          <h5 class="card-title mb-1">
                            {new Date(
                              propertyUnitAdvert.date_advert_ends
                            ).toLocaleString("default", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="d-flex mt-2 col-6">
                        <div class="flex-shrink-0">
                          <i class="ri-checkbox-circle-fill text-primary"></i>
                        </div>
                        <div class="flex-grow-1 ms-2 text-muted">
                          <div class="flex-grow-1 ms-2">
                            <p class="text-muted mb-0">Security Deposit</p>
                            <h5 class="card-title mb-1">
                              {formatCurrency(
                                propertyUnitAdvert.security_deposit
                              )}{" "}
                              {propertyUnitAdvert.currency_name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Square Foot and things usefull */}
                    {propertyUnitAdvert.property_unit && (
                      <div class="row">
                        <div class="d-flex mt-2 col-6">
                          <div class="flex-shrink-0">
                            <i class="ri-checkbox-circle-fill text-primary"></i>
                          </div>
                          <div class="flex-grow-1 ms-2 text-muted">
                            <div class="flex-grow-1 ms-2">
                              <p class="text-muted mb-0">
                                Interior Square Footage
                              </p>
                              <h5 class="card-title mb-1">
                                {
                                  propertyUnitAdvert.property_unit
                                    .square_footage
                                }{" "}
                                sqft
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row mt-4">
                      <div className="col mb-3">
                        {propertyUnitAdvert.is_advert_expired ? (
                          <button
                            className="btn btn-success w-lg waves-effect waves-light"
                            disabled
                          >
                            Sign Up
                          </button>
                        ) : (
                          <a
                            href={`${productionUrl}/rentalSignUp/${propertyUnitAdvert.campaign_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-success w-lg waves-effect waves-light"
                          >
                            Sign Up
                          </a>
                        )}
                      </div>
                      <div className="col mb-3">
                        {/* Preview the advert */}
                        <a
                          href={`${productionUrl}/advert/${propertyUnitAdvert.campaign_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-info w-lg waves-effect waves-light"
                        >
                          Preview
                        </a>
                      </div>
                      <div className="col mb-3">
                        {/* Copy link to clipboard */}
                        <button
                          className="btn btn-primary w-lg waves-effect waves-light"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${productionUrl}/advert/${propertyUnitAdvert.campaign_id}`
                            );

                            toast.success("Link copied to clipboard");
                          }}
                        >
                          Copy Link
                        </button>
                      </div>
                      <div className="col mb-3">
                        {/* Manage View Scheduling*/}
                        <Link
                          to={`/unitViewingCalendar/${propertyUnitAdvert.campaign_id}`}
                          className="btn btn-dark w-lg waves-effect waves-light"
                        >
                          Manage Viewing
                        </Link>
                      </div>
                      <div className="col mb-3">
                        <Link
                          to={`/applicantPool/${propertyUnitAdvert.campaign_id}`}
                          className="btn btn-secondary w-lg waves-effect waves-light"
                        >
                          Applicant Pool
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      {propertyUnitAdvert.is_advert_expired ? (
                        <h4 style={{ color: "red" }}>
                          We are no longer accepting applicants
                        </h4>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>

            <div class="col-xxl-4 row-cols-lg-1 row-cols-1">
              <div class="row row-cols-xxl-3 row-cols-lg-3 row-cols-1">
                <div class="col">
                  <div class="card card-body">
                    <div class="d-flex mb-2 align-items-center">
                      <div class="flex-shrink-0">
                        <i class="las la-ruler-combined la-3x"></i>
                      </div>
                      <div class="flex-grow-1 ms-2">
                        <p class="text-muted mb-0">Square Footage</p>
                        <h5 class="card-title mb-1">
                          {propertyUnitAdvert?.property_unit.square_footage}{" "}
                          sqft
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class="col">
                  <div class="card card-body">
                    <div class="d-flex mb-2 align-items-center">
                      <div class="flex-shrink-0">
                        <i class="las la-bed la-3x"></i>
                      </div>
                      <div class="flex-grow-1 ms-2">
                        <p class="text-muted mb-0">Bedrooms</p>
                        <h5 class="card-title mb-1">
                          {propertyUnitAdvert?.property_unit.number_of_bedrooms}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class="col">
                  <div class="card card-body">
                    <div class="d-flex mb-2 align-items-center">
                      <div class="flex-shrink-0">
                        <i class="las la-bath la-3x"></i>
                      </div>
                      <div class="flex-grow-1 ms-2">
                        <p class="text-muted mb-0">Bathrooms</p>
                        <h5 class="card-title mb-1">
                          {
                            propertyUnitAdvert?.property_unit
                              .number_of_bathrooms
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class="col">
                  <div class="card card-body">
                    <div class="d-flex mb-2 align-items-center">
                      <div class="flex-shrink-0">
                        <i class="las la-toilet la-3x"></i>
                      </div>
                      <div class="flex-grow-1 ms-2">
                        <p class="text-muted mb-0">Half Bathrooms</p>
                        <h5 class="card-title mb-1">
                          {" "}
                          {
                            propertyUnitAdvert?.property_unit
                              ?.number_of_half_bathrooms
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class="col">
                  <div class="card card-body">
                    <div class="d-flex mb-2 align-items-center">
                      <div class="flex-shrink-0">
                        <i class="las la-door-open la-3x"></i>
                      </div>
                      <div class="flex-grow-1 ms-2">
                        <p class="text-muted mb-0">Rooms</p>
                        <h5 class="card-title mb-1">
                          {propertyUnitAdvert?.propertyUnit?.number_of_rooms}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
              </div>

              {/* end row */}
            </div>
            {/* end col */}

            <div className="col-xl-4 card">
              <div className="justify-content-between d-flex align-items-center mt-3 mb-4">
                <h5 className="mb-0 pb-1 text-decoration-underline">
                  {propertyUnitAdvert?.property_unit?.unit_name
                    ?.toUpperCase()
                    .charAt(0) +
                    propertyUnitAdvert?.property_unit?.unit_name?.slice(1)}{" "}
                  {"'s "}
                  Viewing Schedule
                </h5>
              </div>
              <div
                className="row row-cols-xxl-1 row-cols-lg-1 row-cols-1"
                style={{ maxHeight: "800px", overflowY: "auto" }}
              >
                {viewingSchedule.map((viewing, index) => (
                  <div className="col mb-3" key={index}>
                    <div className="card card-body">
                      <div className="d-flex mb-2 align-items-center">
                        <div className="flex-shrink-0">
                          <i className="las la-calendar-alt la-3x"></i>
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-muted mb-0">Date</p>
                              <h5 className="card-title mb-1">
                                {new Date(viewing.start_time).toLocaleString(
                                  "default",
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </h5>
                            </div>
                            <div className="col-6">
                              <p className="text-muted mb-0">Time</p>
                              <h5 className="card-title mb-1">
                                {new Date(viewing.start_time).toLocaleString(
                                  "default",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}
                              </h5>
                            </div>
                            <div className="col-6">
                              <p className="text-muted mb-0">Duration</p>
                              <h5 className="card-title mb-1">
                                {viewing.duration}
                              </h5>
                            </div>
                            <div className="col-6">
                              <p className="text-muted mb-0">Status</p>
                              <h5 className="card-title mb-1">
                                {viewing.status}
                              </h5>
                            </div>
                            <div className="col-6">
                              <p className="text-muted mb-0">Tenant</p>
                              <h5 className="card-title mb-1">
                                {viewing.tenant_name}
                              </h5>
                            </div>
                            <div className="col-6">
                              <p className="text-muted mb-0">Note</p>
                              <h5 className="card-title mb-1">
                                {viewing.note}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* end row */}
            </div>
            {/* end col */}

            {/*Another column like analytics could come here */}
          </div>
          {/*end row */}

          {/*Unit Name */}
          <div class="row"></div>
          {/*end row */}

          {/*Add the Calendar here and have a mini version on the dashboard*/}

          {/*Show a grid of the tenant prospect who are scheduled to view so far grouped by date */}

          <div class="row"></div>
        </div>
      )}
      {/* Optional Amenities Section */}
    </React.Fragment>
  );
};

export default AdvertisementDetail;
