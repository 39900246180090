import React from 'react';

const BankAccountCard = ({
  bankAccount,
  index,
  selectedBankAccountIndex,
  onClick,
}) => {
  return (
    <div
      key={index}
      className={`col-xl-3 ${
        selectedBankAccountIndex === index
          ? 'highlighted pt-3 pb-0 m-2'
          : 'pt-3 pb-0 m-2'
      }`}
      onClick={() => onClick(bankAccount, index)}
    >
      <div className='card card-height-100'>
        <div className='card-body'>
          <div className='d-flex align-items-start'>
            <div className='flex-grow-1 overflow-hidden'>
              <h5 className='contact-name fs-13 mb-1'>
                <a href='#' className='link text-body'>
                  {bankAccount?.bank_account?.account_name}
                </a>
              </h5>
              <p className='contact-born text-muted mb-0'>
                {bankAccount?.bank_account?.routing_number}
              </p>
            </div>
            <div className='flex-shrink-0 ms-2'>
              <div className='fs-11 text-muted'>
                {bankAccount?.bank_account?.bank_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountCard;
