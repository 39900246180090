import React, { useEffect, useState } from 'react';
import Joi from 'joi-browser';
import Input from '../../../../../reusable/form/Input';
import { toast } from 'react-toastify';
import Multistep from 'react-multistep';
import { useNavigate, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import PageTitleBox from '../../../../../reusable/title/pageTitleBox';
import CreateTenantManuallyForm from './sub/createTenantManuallyForm';
import SetTenantContractForm from './sub/setTenantContractForm';
import {
  getPropertyByPropertyId,
  getPropertyUnitById,
} from '../../../../../../services/property/retrieve/propertyRetrievalService';
import { getPortfolioByPortfolioId } from '../../../../../../services/portfolio/portfolioManagement';
import { getOwnershipStructuresByPortfolioId } from '../../../../../../services/portfolio/ownership/portfolioOwnershipManagement';
import { set } from 'date-fns';
import LoaderView from '../../../../../reusable/loading/loaderView';

import { multiStepStyles } from '../../../../../reusable/stylesheet/multiStepCustomCss.js';
import { createTenant } from '../../../../../../services/rent/tenant/invite/tenantRentalContractService';

const CreateExistingTenant = ({ isDarkMode }) => {
  const pageTitle = 'Create Existing Tenant';
  const navigate = useNavigate();
  const { propertyUnitId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  //the id that need to be tracked
  const [tenantId, setTenantId] = useState('');

  //navigating the multistep
  const [currentStep, setCurrentStep] = useState(0);

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
    console.log('Current Step: ' + currentStep);
    //alert();
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  //TODO: need an additional form to outline the details of the contract like end_of_lease_action
  const [contractDetail, setContractDetail] = useState({
    rental_contract_id: '',
    tenant_id: '',
    portfolio_id: '',
    property_id: '',
    property_unit_id: '',
    start_date: Date.now(),
    end_date: Date.now(),
    monthly_rent: 0,
    security_deposit: 0,
    pet_deposit: 0,
    pet_rent: 0,
    contract_template_id: '',
    contract_detail: '',
    created_at: Date.now(),
    updated_at: Date.now(),
    is_active: true,
    is_deleted: false,
    portfolio_ownership_id: '',
    property_name: '',
    property_unit_name: '',
    portfolio_name: '',
    owner_manager_user_id: '',
    owner_manager_name: '',
    owner_manager_email: '',
    end_of_lease_action: '',
  });

  const [portfolio, setPortfolio] = useState({
    portfolio_id: '',
    ownership_id: '',
    portfolio_name: '',
    created_at: Date.now(),
    updated_at: Date.now(),
  });

  const [property, setProperty] = useState({
    property_id: '',
    portfolio_id: '',
    currency_id: '',
    property_type_id: '',
    sub_property_type_id: '',
    property_alias_name: '',
    street_address_1: '',
    street_address_2: '',
    city_town: '',
    state_parish: '',
    zip_postal_code: '',
    country: '',
    country_id: '',
    created_at: Date.now(),
    updated_at: Date.now(),
  });

  const [propertyUnit, setPropertyUnit] = useState({
    property_unit_id: '',
    property_id: '',
    unit_name: '',
    number_of_bedrooms: 0,
    number_of_bathrooms: 0,
    square_footage: 0,
    market_rent_amount: 0,
    is_rented: false,
    created_at: Date.now(),
    updated_at: Date.now(),
  });

  const prevStyle = {
    background: '#333',
    color: '#fff',
    borderRadius: '5px',
    padding: '10px 20px',
  };

  const nextStyle = {
    background: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    padding: '10px 20px',
  };

  const saveProperty = () => {
    // Save property data
    // ...
  };

  const saveUnits = () => {
    // Save units data
    // ...
  };

  const buttonStyle = {
    backgroundColor: '#007bff', // Example background color
    color: 'white', // Text color
    padding: '10px 20px', // Padding inside the button
    border: 'none', // No border
    borderRadius: '5px', // Rounded corners
  };

  const prevButton = (
    <button style={buttonStyle} onClick={saveProperty}>
      Previous
    </button>
  );

  const nextButton = (
    <button style={buttonStyle} onClick={saveUnits}>
      Next
    </button>
  );

  const tenantSchema = {
    tenant_id: Joi.string().required(),
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    email_address: Joi.string().required(),
    phone_number: Joi.string().required(),
  };

  const [tenantDetail, setTenantDetail] = useState({
    tenant_id: uuidv4(),
    first_name: '',
    last_name: '',
    email_address: '',
    phone_number: '',
  });

  const [errors, setErrors] = useState({});

  const [portfolioOwnership, setPortfolioOwnership] = useState([{}]);

  //handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (errors) return;
    console.log('Submitted');
    console.log(tenantDetail);

    //like a validation check
    //first creates the tenant, if they already has a account then it will let the landlord know and just set the tenant id to be the one it found
    //NEXT create or test that the profile exist before the contract is done
    const response = await createTenant(tenantDetail);
    if (response.server_message === 'Account created.') {
      console.log('Tenant Created', response);
      toast.success(
        `${response.server_message}, the invite will be sent to them in the next step. Press the Next Button.`
      );

      //set the tenant_id
      updateTheTenantId(response.tenant_id);
      // Move to the next step after successful submission

      // Move to the next step after successful submission
    } else {
      console.log('Tenant Already Exists', response);
      toast.info(
        `${response.server_message}, the invite will be sent to them in the next step. Press the Next Button.`
      );
      //set the tenant_id
      updateTheTenantId(response.tenant_id);
      // Move to the next step after successful submission
    }

    //then the next network call is to create the lease and send the invitation to the tenant
    // Move to the next step after successful submission
  };

  const updateTheTenantId = (_tenant_id) => {
    setTenantId(_tenant_id);

    setTenantDetail({
      ...tenantDetail,
      tenant_id: _tenant_id,
    });

    setContractDetail({
      ...contractDetail,
      tenant_id: _tenant_id,
    });

    console.log('Tenant ID updated', _tenant_id);
  };

  //validate
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(tenantDetail, tenantSchema, options);
    if (!error) return null;

    console.log('Error', error);

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  //handleChange
  const handleChange = ({ currentTarget: input }) => {
    const data = { ...tenantDetail };
    data[input.name] = input.value;
    setTenantDetail(data);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    console.log('TenantID: ' + tenantDetail.tenant_id);
    fetchPropertyUnitDetail();
  }, []);

  //get the property from the propertyUnitId
  const fetchPropertyUnitDetail = async () => {
    // Fetch property unit
    const propertyUnit = await getPropertyUnitById(propertyUnitId);
    if (propertyUnit) {
      // Corrected from if (property) to if (propertyUnit)
      setPropertyUnit(propertyUnit);
      console.log('Property Unit', propertyUnit);

      // Fetch property
      fetchPropertyById(propertyUnit.property_id, propertyUnit);
    }
  };

  const fetchPropertyById = async (propertyId, _propertyUnit) => {
    const property = await getPropertyByPropertyId(propertyId);
    if (property) {
      setProperty(property);
      console.log('Property', property);

      // Fetch portfolio
      fetchPortfolioById(property.portfolio_id, property, _propertyUnit);
    }
  };

  const fetchPortfolioById = async (portfolioId, _property, _propertyUnit) => {
    const portfolio = await getPortfolioByPortfolioId(portfolioId);
    if (portfolio) {
      console.log('Portfolio', portfolio);
      setPortfolio(portfolio);

      // Fetch portfolio ownership
      fetchPortfolioOwnership(portfolioId, portfolio, _property, _propertyUnit);
    }
  };

  const fetchPortfolioOwnership = async (
    portfolioId,
    _portfolio,
    _property,
    _propertyUnit
  ) => {
    const portfolioOwnership = await getOwnershipStructuresByPortfolioId(
      portfolioId
    );
    if (portfolioOwnership) {
      console.log('Portfolio Ownership', portfolioOwnership);
      setPortfolioOwnership(portfolioOwnership);

      const controllingPartner = portfolioOwnership.find(
        (ownership) => ownership.is_controlling_partner === true
      );
      addThePropertyInfoForForm(
        controllingPartner,
        _portfolio,
        _property,
        _propertyUnit
      );

      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const addThePropertyInfoForForm = (
    _controllingPartner,
    _portfolio,
    _property,
    _propertyUnit
  ) => {
    // Set the property info to the form
    // Set the portfolio info to the form
    // Set the property unit info to the form

    const contractDetail = {
      rental_contract_id: uuidv4(),
      tenant_id: tenantDetail.tenant_id,
      portfolio_id: _portfolio.portfolio_id,
      property_id: _property.property_id,
      property_unit_id: _propertyUnit.property_unit_id,
      property_name: _property.property_alias_name,
      property_unit_name: _propertyUnit.unit_name,
      portfolio_name: _portfolio.portfolio_name,
      owner_manager_user_id: _controllingPartner?.user_id,
      owner_manager_name:
        _controllingPartner?.first_name + ' ' + _controllingPartner?.last_name,
      owner_manager_email: _controllingPartner?.email_address,
    };

    setContractDetail(contractDetail);

    console.log('Contract Detail', contractDetail);

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div data-bs-theme={isDarkMode ? 'dark' : 'light'}>
        <div className='container-fluid'>
          <PageTitleBox pageTitle={pageTitle} />
          <div className='row '>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Create New Tenant and Contract
                  </h4>
                  <div className='flex-shrink-0'></div>
                </div>
                <Multistep
                  title={'Tenant Contract Workflow'}
                  activeStep={currentStep}
                  showTitles={true}
                  showNavigation={true}
                  prevButton={{
                    title: 'Back',
                    className: 'button-outline-prev',

                    style: {
                      background: 'transparent', // Make background transparent for outline effect
                      color: '#007bff', // Use Bootstrap's primary color for text to match the border
                      padding: '10px 20px', // Similar padding to Bootstrap's buttons
                      borderRadius: '5px', // Rounded corners
                      border: '1px solid #007bff', // Use Bootstrap's primary color for border
                      margin: '20px',
                    },
                  }}
                  nextButton={{
                    title: 'Next',

                    style: {
                      background: 'transparent', // Make background transparent for outline effect
                      color: '#28a745', // Use Bootstrap's primary color for text to match the border
                      padding: '10px 20px', // Similar padding to Bootstrap's buttons
                      borderRadius: '5px', // Rounded corners
                      border: '1px solid #28a745', // Use Bootstrap's primary color for border
                      margin: '20px',
                    },
                  }}
                >
                  {/* Add Tenant*/}

                  <CreateTenantManuallyForm
                    title='Add Tenant'
                    isDarkMode={isDarkMode}
                    tenantDetail={tenantDetail}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    contractDetail={contractDetail}
                    errors={errors}
                    validate={validate}
                  />

                  {/*TODO next */}
                  {/* Select Contract*/}
                  <SetTenantContractForm
                    title='Select Contract'
                    isDarkMode={isDarkMode}
                    tenantDetail={tenantDetail}
                    contractDetail={contractDetail}
                  />
                </Multistep>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateExistingTenant;
