import React from "react";
import { Link } from "react-router-dom";
import TicketCard from "./reusable/ticketCard";
import ShimmerEffect from "../../../../reusable/loading/ShimmerEffect";
import { useSettings } from "../../../../../services/authentication/contexts/settings/settingsContext";

const TicketsKanbanBoard = ({
  isKanbanView,
  toggleView,
  newTicketsCount,
  inProgressTicketsCount,
  pendingTicketsCount,
  completedTicketsCount,
  newUnitTickets,
  inProgressUnitTickets,
  pendingUnitTickets,
  completedUnitTickets,
  propertyUnitId,
  isNewTicketsLoading,
  isInProgressTicketsLoading,
  isPendingTicketsLoading,
  isCompletedTicketsLoading,
}) => {
  //useSettings
  const { settings } = useSettings();

  const isDarkMode = settings.isDarkMode;
  const renderShimmerCards = () => (
    <>
      {[...Array(3)].map((_, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <div className="d-flex align-items-center mb-3">
              <ShimmerEffect
                type="circle"
                height="40px"
                width="40px"
                darkMode={isDarkMode}
              />
              <div className="ms-3 flex-grow-1">
                <ShimmerEffect
                  type="text"
                  height="20px"
                  width="60%"
                  darkMode={isDarkMode}
                />
                <ShimmerEffect
                  type="text"
                  height="15px"
                  width="40%"
                  darkMode={isDarkMode}
                  className="mt-2"
                />
              </div>
            </div>
            <ShimmerEffect
              type="text"
              height="15px"
              width="90%"
              darkMode={isDarkMode}
            />
            <ShimmerEffect
              type="text"
              height="15px"
              width="80%"
              darkMode={isDarkMode}
              className="mt-2"
            />
          </div>
        </div>
      ))}
    </>
  );

  return (
    <React.Fragment>
      {/* Kanban Start (will be a switch later on)*/}

      {/* Kanban Header Card */}

      <div className="card">
        <div className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">Tickets</h5>
            <div className="flex-shrink-0">
              <div className="d-flex flex-wrap gap-2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-wrap gap-2">
                    <button
                      onClick={toggleView}
                      className="btn btn-outline-primary me-2" // Added margin to the right
                    >
                      {isKanbanView ? (
                        <span>
                          <i
                            className="ri-list-check"
                            title="Switch to List View"
                          ></i>{" "}
                          List View
                        </span>
                      ) : (
                        <span>
                          <i
                            className="ri-layout-grid-fill"
                            title="Switch to Kanban View"
                          ></i>{" "}
                          Kanban View
                        </span>
                      )}
                    </button>
                    <Link
                      to={
                        propertyUnitId
                          ? `/newUnitTicket/${propertyUnitId}`
                          : "/newUnitTicket"
                      }
                      className="btn btn-info add-btn"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Ticket
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*end row*/}
        </div>
        {/*end card-body*/}
      </div>
      {/*end card*/}

      <div className="tasks-board mb-3 pb-5" id="kanbanboard">
        {/* New Tickets Section */}
        <div className="tasks-list">
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <h6 className="fs-14 text-uppercase fw-semibold mb-0">
                New{" "}
                <small className="badge bg-secondary align-bottom ms-1 totaltask-badge">
                  {newTicketsCount}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar className="tasks-wrapper px-3 mx-n3">
            <div id="todo-task" className="tasks">
              {isNewTicketsLoading ? (
                renderShimmerCards()
              ) : newUnitTickets.length === 0 ? (
                <div className="card mb-3">
                  <div className="card-body text-center text-muted">
                    No new tickets found
                  </div>
                </div>
              ) : (
                newUnitTickets.map((ticket, index) => (
                  <TicketCard ticket={ticket} key={index} />
                ))
              )}
            </div>
          </div>
        </div>

        {/* In Progress Section */}
        <div className="tasks-list">
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <h6 className="fs-14 text-uppercase fw-semibold mb-0">
                In Progress{" "}
                <small className="badge bg-warning align-bottom ms-1 totaltask-badge">
                  {inProgressTicketsCount}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar className="tasks-wrapper px-3 mx-n3">
            <div id="inprogress-task" className="tasks">
              {isInProgressTicketsLoading ? (
                renderShimmerCards()
              ) : inProgressUnitTickets.length === 0 ? (
                <div className="card mb-3">
                  <div className="card-body text-center text-muted">
                    No in-progress tickets found
                  </div>
                </div>
              ) : (
                inProgressUnitTickets.map((ticket, index) => (
                  <TicketCard ticket={ticket} key={index} />
                ))
              )}
            </div>
          </div>
        </div>

        {/* Pending Section */}
        <div className="tasks-list">
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <h6 className="fs-14 text-uppercase fw-semibold mb-0">
                Pending{" "}
                <small className="badge bg-info align-bottom ms-1 totaltask-badge">
                  {pendingTicketsCount}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar className="tasks-wrapper px-3 mx-n3">
            <div id="reviews-task" className="tasks">
              {isPendingTicketsLoading ? (
                renderShimmerCards()
              ) : pendingUnitTickets.length === 0 ? (
                <div className="card mb-3">
                  <div className="card-body text-center text-muted">
                    No pending tickets found
                  </div>
                </div>
              ) : (
                pendingUnitTickets.map((ticket, index) => (
                  <TicketCard ticket={ticket} key={index} />
                ))
              )}
            </div>
          </div>
        </div>

        {/* Completed Section */}
        <div className="tasks-list">
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <h6 className="fs-14 text-uppercase fw-semibold mb-0">
                Completed{" "}
                <small className="badge bg-success align-bottom ms-1 totaltask-badge">
                  {completedTicketsCount}
                </small>
              </h6>
            </div>
          </div>
          <div data-simplebar className="tasks-wrapper px-3 mx-n3">
            <div id="completed-task" className="tasks">
              {isCompletedTicketsLoading ? (
                renderShimmerCards()
              ) : completedUnitTickets.length === 0 ? (
                <div className="card mb-3">
                  <div className="card-body text-center text-muted">
                    No completed tickets found
                  </div>
                </div>
              ) : (
                completedUnitTickets.map((ticket, index) => (
                  <TicketCard ticket={ticket} key={index} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {/*end task-board*/}
    </React.Fragment>
  );
};

export default TicketsKanbanBoard;
