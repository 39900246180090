import React from "react";
import logo from "../../../../../styletheme/logos/gurenter_logo.png";
import { capitalizeFirstLetterOfEachWord } from "../../../../../services/utils/stringUtils";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../services/utils/dateUtils";

const BillingHeader = ({ invoice, subscription }) => {
  const companyName = "Gurenter";
  const companyStreetAddress = "PO BOX 543";
  const companyTownState = "AUBURN, Maine";
  const companyCountry = "United States";
  const companyZipCode = "04212";
  const companyPhoneNumber = "+1 207-659-3879";
  const companyEmail = "support@gurenter.com";
  const companyWebsite = "https://gurenter.com";

  let dateObject = invoice?.created
    ? new Date(invoice.created * 1000)
    : new Date(); // Default to current date if invoice or created is null

  let createdDate = dateObject.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }); // format: "23 Nov, 2021"
  let createdTime = dateObject.toLocaleTimeString(); // format: "hh:mm:ss AM/PM"

  return (
    <React.Fragment>
      <div className="row justify-content-center">
        <div className="col-xl-12">
          <div className="card" id="demo">
            <div className="row">
              <div className="col-lg-12">
                <div className="card-header border-bottom-dashed p-4">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <img
                        src={logo}
                        className="card-logo card-logo-dark"
                        alt="logo dark"
                        style={{ height: "80px" }}
                      />
                      <img
                        src={logo}
                        className="card-logo card-logo-light"
                        alt="logo light"
                        style={{ height: "80px" }}
                      />
                      <div className="row justify-content-between">
                        <div className="col-lg-6 ">
                          <div className="mt-sm-2 mt-4">
                            <h6 className="text-muted text-uppercase fw-semibold">
                              Company Address
                            </h6>
                            <p className="text-muted mb-1" id="address-details">
                              {companyStreetAddress}
                            </p>
                            <p className="text-muted mb-1" id="address-details">
                              {companyTownState}
                            </p>
                            <p className="text-muted mb-1" id="address-details">
                              {companyCountry}
                            </p>
                            <p className="text-muted mb-0" id="zip-code">
                              <span>Zip Code:</span> {companyZipCode}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ml-auto d-flex flex-column align-items-end">
                      <div className="flex-shrink-0 mt-sm-0 mt-3 text-end">
                        <h6>
                          <span className="text-muted fw-normal">
                            Company Name:{" "}
                          </span>
                          <span id="legal-register-no">{companyName}</span>
                        </h6>
                        <h6>
                          <span className="text-muted fw-normal">Email:</span>{" "}
                          <span id="email">{companyEmail}</span>
                        </h6>
                        <h6>
                          <span className="text-muted fw-normal">Website:</span>{" "}
                          <a
                            href={companyWebsite}
                            className="link-primary"
                            target="_blank"
                            id="website"
                          >
                            {companyWebsite}
                          </a>
                        </h6>
                        <h6 className="mb-0">
                          <span className="text-muted fw-normal">
                            Contact No:{" "}
                          </span>
                          <span id="contact-no"> {companyPhoneNumber}</span>
                        </h6>
                      </div>
                      <div className="mt-3 text-end">
                        <h6 className="text-muted text-uppercase fw-semibold">
                          Billing Address
                        </h6>
                        <p className="fw-medium mb-2">
                          {invoice?.customer_name}
                        </p>
                        <p className="text-muted mb-1" id="billing-address">
                          {invoice?.customer_address?.line1}
                        </p>
                        <p
                          className="text-muted mb-1"
                          id="billing-address-city"
                        >
                          {invoice?.customer_address?.city},{" "}
                          {invoice?.customer_address?.state}
                        </p>
                        <p className="text-muted mb-1">
                          <span>Phone: </span>
                          <span id="billing-phone-no">
                            {invoice?.customer_phone}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/*end card-header*/}
              </div>
              {/*end col*/}
              <div className="col-lg-12">
                <div className="card-body p-4">
                  <div className="row g-3">
                    <div className="col-lg-3 col-6">
                      <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Invoice No
                      </p>
                      <h5 className="fs-14 mb-0">
                        <span id="invoice-no">{invoice?.number}</span>
                      </h5>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-6">
                      <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Date
                      </p>
                      <h5 className="fs-14 mb-0">
                        <span id="invoice-date">{createdDate}</span>{" "}
                        <small className="text-muted" id="invoice-time">
                          {createdTime}
                        </small>
                      </h5>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-6">
                      <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Payment Status
                      </p>
                      <span
                        className="badge bg-success-subtle text-success fs-11"
                        id="payment-status"
                      >
                        {invoice?.status
                          ? capitalizeFirstLetterOfEachWord(invoice.status)
                          : "N/A"}
                      </span>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-6">
                      <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Period Start
                      </p>
                      <h5 className="fs-14 mb-0">
                        <span>
                          {subscription?.current_period_start
                            ? formatDate(subscription.current_period_start)
                            : "N/A"}
                        </span>
                      </h5>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-6">
                      <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Period End
                      </p>
                      <h5 className="fs-14 mb-0">
                        <span>
                          {subscription?.current_period_end
                            ? formatDate(subscription.current_period_end)
                            : "N/A"}
                        </span>
                      </h5>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-3 col-6">
                      <p className="text-muted mb-2 text-uppercase fw-semibold">
                        Due Date
                      </p>
                      <h5 className="fs-14 mb-0">
                        <span>
                          {invoice?.due_date
                            ? formatDate(invoice.due_date)
                            : "N/A"}
                        </span>
                      </h5>
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                </div>
                {/*end card-body*/}
              </div>
              {/*end col*/}

              {/*end col*/}
              <div className="col-lg-12">
                <div className="card-body p-4">
                  <div className="border-top border-top-dashed mt-2 col-lg-12">
                    <table
                      className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                      style={{ width: "250px" }}
                    >
                      <tbody>
                        <tr>
                          <td>Amount Due</td>
                          <td className="text-end">
                            ${((invoice?.amount_due || 0) / 100).toFixed(2)}
                          </td>
                        </tr>

                        <tr>
                          <td>Amount Remaining</td>
                          <td className="text-end">
                            $
                            {((invoice?.amount_remaining || 0) / 100).toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr className="border-top border-top-dashed fs-15">
                          <th scope="row">Total Paid</th>
                          <th className="text-end">
                            ${((invoice?.amount_paid || 0) / 100).toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    {/*end table*/}
                  </div>

                  <div className="row">
                    <div className="col-md-6 hstack gap-2 justify-content-start d-print-none mt-4">
                      <a
                        href={process.env.REACT_APP_STRIPE_MANAGE_BILLING_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                      >
                        <i className="ri-bank-card-line align-bottom me-1"></i>
                        Manage Subscription
                      </a>
                    </div>

                    <div className="col-md-6 hstack gap-2 justify-content-end d-print-none mt-4">
                      <a
                        href={invoice?.hosted_invoice_url}
                        className="btn btn-secondary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ri-eye-fill align-bottom me-1"></i> View
                        Invoice
                      </a>

                      <a
                        onClick={() => window.print()}
                        className="btn btn-success"
                      >
                        <i className="ri-printer-line align-bottom me-1"></i>
                        Print
                      </a>
                      <a
                        href={invoice?.invoice_pdf}
                        className="btn btn-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <i className="ri-download-2-line align-bottom me-1"></i>
                        Download
                      </a>
                    </div>
                  </div>
                </div>
                {/*end card-body*/}
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end card*/}
        </div>
        {/*end col*/}
      </div>
      {/*end row*/}
    </React.Fragment>
  );
};

export default BillingHeader;
