import React, { useState, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import {
  createLinkToken,
  exchangePublicToken,
  getTransactions,
} from "../../../../../../services/bank/bankManager";
import { toast } from "react-toastify";

const BankAccountIssuesResolver = ({ onResolve, propertyUnitId }) => {
  const [linkToken, setLinkToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLinkToken = async () => {
      try {
        const token = await createLinkToken();
        setLinkToken(token);
      } catch (error) {
        console.error("Error creating link token:", error);
        toast.error("Failed to initialize re-authentication process. Please try again.");
      }
    };

    fetchLinkToken();
  }, []);

  const handleOnSuccess = async (public_token, metadata) => {
    console.log("Success", public_token, metadata);
    setIsLoading(true);
    try {
      await exchangePublicToken(public_token);
      toast.success("Bank accounts updated successfully");

      // Fetch transactions after successful re-authentication
      const endDate = new Date();
      const startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - 1);

      const formatDate = (date) => {
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      };

      const transactions = await getTransactions(
        propertyUnitId,
        formatDate(startDate),
        formatDate(endDate)
      );

      onResolve(transactions);
    } catch (error) {
      console.error(
        "Error updating Plaid account details or fetching transactions:",
        error
      );
      if (error.response && error.response.data) {
        console.error("Error details:", error.response.data);
      }
      toast.error(
        "Failed to update bank accounts or fetch transactions. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const config = {
    token: linkToken,
    onSuccess: handleOnSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <div className="bank-account-issues">
      <h3>Accounts Requiring Attention</h3>
      <p>
        Some of your connected bank accounts require re-authentication. Click
        the button below to update your account information.
      </p>
      <button
        className="btn btn-primary"
        onClick={() => open()}
        disabled={!ready || !linkToken || isLoading}
      >
        {isLoading ? "Processing..." : "Re-authenticate Accounts"}
      </button>
    </div>
  );
};

export default BankAccountIssuesResolver;
