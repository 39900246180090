import http from "../../services/general/httpService";
import config from "../../constants/config.json";

//TODO: create the create Ticket Form then the Ticket Detail Form then the Ticket List View
//after a ticket is created you navigate to the new ticket to confirm the details and start adding comments etc
///unit_ticket/create_or_update_unit_ticket
//going to need a HTML textfield

export async function createOrUpdateUnitTicket(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/create_or_update_unit_ticket`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }

//upload_ticket_attachments
export async function uploadTicketAttachments(attachments) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/upload_ticket_attachments`;
     const {data: result} = await http.post(fullApiEndpoint, attachments);
    
     return result;
    }

//update_unit_ticket
export async function updateUnitTicket(data) {    
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/update_unit_ticket`;
     const {data: result} = await http.post(fullApiEndpoint, data);

     return result;
     }

//create_or_update_unit_tickets
export async function createOrUpdateUnitTickets(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/create_or_update_unit_tickets`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }


//create_or_update_unit_ticket_tag
export async function createOrUpdateUnitTicketTag(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/create_or_update_unit_ticket_tag`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }

//create_or_update_unit_ticket_tags

export async function createOrUpdateUnitTicketTags(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/create_or_update_unit_ticket_tags`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }

//get_unit_tickets_by_property_unit_id
export async function getUnitTicketsByPropertyUnitId(property_unit_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_tickets_by_property_unit_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id});
    
     return result;
    }

//get_new_tickets_by_property_unit_id
export async function getNewTicketsByPropertyUnitId(property_unit_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_new_tickets_by_property_unit_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id});
    
     return result;
    }

//get_in_progress_tickets_by_property_unit_id
export async function getInProgressTicketsByPropertyUnitId(property_unit_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_in_progress_tickets_by_property_unit_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id});
    
     return result;
    }

//get_pending_tickets_by_property_unit_id
export async function getPendingTicketsByPropertyUnitId(property_unit_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_pending_tickets_by_property_unit_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id});
    
     return result;
    }

//get_completed_tickets_by_property_unit_id
export async function getCompletedTicketsByPropertyUnitId(property_unit_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_completed_tickets_by_property_unit_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id});
    
     return result;
    }


//get_unit_tickets_by_ticket_type
export async function getUnitTicketsByTicketType(ticket_type) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_tickets_by_ticket_type`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_type: ticket_type});
    
     return result;
    }

//get_unit_tickets_by_ticket_status
export async function getUnitTicketsByTicketStatus(ticket_status) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_tickets_by_ticket_status`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_status: ticket_status});
    
     return result;
    }

//get_unit_tickets_by_ticket_priority
/*//need the ticket_id
export async function getUnitTicketsByTicketPriority(ticket_priority) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_tickets_by_ticket_priority`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_id: ticket_id,ticket_priority: ticket_priority});
    
     return result;
    }
    */

//get_unit_tickets_by_ticket_number
export async function getUnitTicketsByTicketNumber(ticket_number) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_tickets_by_ticket_number`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_number: ticket_number});
    
     return result;
    }


//get_unit_tickets_by_ticket_title
export async function getUnitTicketsByTicketTitle(ticket_title) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_tickets_by_ticket_title`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_title: ticket_title});
    
     return result;
    }

//get_unit_tickets_by_ticket_description
export async function getUnitTicketsByTicketDescription(ticket_description) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_tickets_by_ticket_description`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_description: ticket_description});
    
     return result;
    }

//get_unit_ticket_by_ticket_id
export async function getUnitTicketByTicketId(ticket_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_unit_ticket_by_ticket_id`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_id: ticket_id});
    
     return result;
    }

//delete_ticket_media
export async function deleteTicketAttachment(itemToDelete) {

    const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/delete_ticket_media`;
    const {data: result} = await http.post(fullApiEndpoint, itemToDelete);

    return result;
}


//update_unit_ticket_status
export async function updateUnitTicketStatus(ticket_id, ticket_status_id, ticket_status) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/update_unit_ticket_status`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_id: ticket_id, ticket_status_id: ticket_status_id, ticket_status: ticket_status});
    
     return result;
    }

    //update_unit_ticket_priority
export async function updateUnitTicketPriority(ticket_id, ticket_priority_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/update_unit_ticket_priority`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_id: ticket_id, ticket_priority_id: ticket_priority_id});
    
     return result;
    }


//create_ticket_comment
export async function createTicketComment(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/create_ticket_comment`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }

//create_ticket_media
export async function createTicketMedia(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/create_ticket_media`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }

//create_comment_media
export async function createCommentMedia(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/create_comment_media`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }

//get_ticket_comments_by_ticket_id
export async function getTicketCommentsByTicketId(ticket_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_ticket_comments_by_ticket_id`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_id: ticket_id});
    
     return result;
    }

//NOT IMPLEMETED
//upload an image for a ticket
//upload_ticket_image
/*
export async function uploadTicketImage(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/upload_ticket_image`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }
    */

    //assign_ticket_to_user
export async function assignTicketToUser(_data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/assign_ticket_to_user`;
     const {data: result} = await http.post(fullApiEndpoint, _data);
    
     return result;
    }

    //get_users_assigned_to_ticket
export async function getUsersAssignedToTicket(ticket_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_users_assigned_to_ticket`;
     const {data: result} = await http.post(fullApiEndpoint, {ticket_id: ticket_id});
    
     return result;
    }


//get_tickets_assigned_to_contractor
export async function getTicketsAssignedToContractor(contractor_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_tickets_assigned_to_contractor`;
     const {data: result} = await http.post(fullApiEndpoint, {contractor_id: contractor_id});
    
     return result;
    }

    //All tickets view
//get all the tickets for the units you own or manage
//get_tickets_by_user_id
export async function getAllTicketsInPortfolioByUserId() {
      const user_id = localStorage.getItem(config.user_id);
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_tickets_by_user_id`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     return result;
    }    

//get_new_tickets_by_user_id
export async function getNewTicketsByUserId() {
      const user_id = localStorage.getItem(config.user_id);
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_new_tickets_by_user_id`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     //console.log("# of new tickets: ", result.length);
     return result;
    }

//get_in_progress_tickets_by_user_id
export async function getInProgressTicketsByUserId() {
      const user_id = localStorage.getItem(config.user_id);
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_in_progress_tickets_by_user_id`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     return result;
    }

//get_pending_tickets_by_user_id
export async function getPendingTicketsByUserId() {
      const user_id = localStorage.getItem(config.user_id);
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_pending_tickets_by_user_id`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     return result;
    }

//get_completed_tickets_by_user_id
export async function getCompletedTicketsByUserId() {
      const user_id = localStorage.getItem(config.user_id);
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_completed_tickets_by_user_id`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     return result;
    }

//tickets by property
//get_all_tickets_by_property_id
export async function getAllTicketsByPropertyId(property_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_all_tickets_by_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
    
     return result;
    }

//get_new_tickets_by_property_id
export async function getNewTicketsByPropertyId(property_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_new_tickets_by_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
    
     return result;
    }


//get_in_progress_tickets_by_property_id
export async function getInProgressTicketsByPropertyId(property_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_in_progress_tickets_by_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
    
     return result;
    }

//get_pending_tickets_by_property_id
export async function getPendingTicketsByPropertyId(property_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_pending_tickets_by_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
    
     return result;
    }

//get_completed_tickets_by_property_id
export async function getCompletedTicketsByPropertyId(property_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_completed_tickets_by_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
    
     return result;
    }


//get_all_tickets_by_portfolio_id
export async function getAllTicketsByPortfolioId(portfolio_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_ticket/get_all_tickets_by_portfolio_id`;
     const {data: result} = await http.post(fullApiEndpoint, {portfolio_id: portfolio_id});
    
     return result;
    }