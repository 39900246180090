import http from "../../../general/httpService";
import config from "../../../../constants/config.json";

///unit_expense/create_or_update_unit_expense

export async function createOrUpdateUnitExpense(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_expense/create_or_update_unit_expense`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
    }
//upload_attachment_to_unit_expense
export async function uploadAttachmentToUnitExpense(data) {

     const fullApiEndpoint = config.apiEndpoint + `/unit_expense/upload_attachment_to_unit_expense`;
     const {data: result} = await http.post(fullApiEndpoint, data);

     return result;
     }

//delete_expense_attachment
export async function deleteExpenseAttachment(attachmentToDelete) {
          
      const fullApiEndpoint = config.apiEndpoint + `/unit_expense/delete_expense_attachment`;
      const {data: result} = await http.post(fullApiEndpoint, attachmentToDelete);
     
      return result;
     }


//get_unit_expense_by_id
export async function getUnitExpenseById(unit_expense_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_expense/get_unit_expense_by_id/${unit_expense_id}`;
     const {data: result} = await http.get(fullApiEndpoint);
    
     return result;
    }

//get_all_expenses_for_property_unit_year
export async function getAllExpensesForPropertyUnitYear(property_unit_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_expense/get_all_expenses_for_property_unit_year`;
     //get the current year
     const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id,expense_year : new Date().getFullYear()});
    
     return result;
    }


//get_all_expenses_for_property_unit_bank_account
export async function getAllExpensesForPropertyUnitBankAccount(property_unit_id,property_bank_account_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_expense/get_all_expenses_for_property_unit_bank_account`;
     //get the current year
     const {data: result} = await http.post(fullApiEndpoint, {property_unit_id: property_unit_id,property_bank_account_id: property_bank_account_id});
    
     return result;
    }

    //get_all_expenses_for_contractor
export async function getAllExpensesForContractor(contractor_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/unit_expense/get_all_expenses_for_contractor`;
     //get the current year
     const {data: result} = await http.post(fullApiEndpoint, {contractor_id: contractor_id});
    
     return result;
    }


         //get_5_recent_expenses_for_property_unit_month_year
export async function get5RecentExpensesForPropertyUnitMonthYear(property_id) {
          
      const fullApiEndpoint = config.apiEndpoint + `/unit_expense/get_5_recent_expenses_for_property_unit_month_year`;
      //get the current year
      const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
     
      return result;
     }
     
//get_5_recent_expenses_for_property_unit_month_year_individual
export async function get5RecentExpensesForPropertyUnitMonthYearIndividual(property_id) {
          
      const fullApiEndpoint = config.apiEndpoint + `/unit_expense/get_5_recent_expenses_for_property_unit_month_year_individual`;
      //get the current year
      const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
     
      return result;
     }
