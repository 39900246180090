import http from "../../services/general/httpService";
import config from "../../constants/config.json";
import { toast } from "react-toastify";
//bank_account/create_link_token
export async function createLinkToken() {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/create_link_token`;
  const { data: result } = await http.post(fullApiEndpoint, { user_id });
  return result.link_token;
}

// Function to handle the success of the Plaid Link flow
export async function exchangePublicToken(public_token) {
  const user_id = localStorage.getItem(config.user_id);
  const ownership_id = user_id; // Assuming ownership_id is the same as user_id

  const fullApiEndpoint = `${config.apiEndpoint}/bank_account/exchange_public_token`;
  const { data: result } = await http.post(fullApiEndpoint, {
    public_token: public_token,
    user_id: user_id,
    ownership_id: ownership_id,
  });

  console.log("Response:", result);
  return result;
}

//bank_account/get_bank_accounts
export async function getBankAccounts() {
  //user_id
  const user_id = localStorage.getItem(config.user_id);

  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/get_bank_accounts`;
  const { data: result } = await http.post(fullApiEndpoint, {
    ownership_id: user_id,
  });

  return result;
}

//bank_account/create_bank_and_bank_account
export async function createBankAndBankAccountManually(bank_data) {
  const ownership_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/create_bank_and_bank_account`;
  const { data: result } = await http.post(fullApiEndpoint, {
    bank_account: bank_data,
    ownership_id: ownership_id,
  });

  return result;
}

//bank_account/create_or_update_property_bank_account
export async function createOrUpdatePropertyBankAccount(property_bank_account) {
  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/create_or_update_property_bank_account`;
  const { data: result } = await http.post(
    fullApiEndpoint,
    property_bank_account
  );

  return result;
}

//get_transactions
export async function getTransactions(property_unit_id, start_date, end_date) {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint = config.apiEndpoint + `/bank_account/get_transactions`;

  const requestData = {
    property_unit_id: property_unit_id,
    user_id: user_id,
    start_date: start_date,
    end_date: end_date,
  };

  try {
    const { data: result } = await http.post(fullApiEndpoint, requestData);
    return result;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    if (error.response && error.response.status === 422) {
      console.error("Unprocessable Entity:", error.response.data);
    }
    throw error;
  }
}

//get_property_unit_bank_accounts
export async function getPropertyUnitBankAccounts(property_unit_id) {
  try {
    const fullApiEndpoint =
      config.apiEndpoint +
      `/bank_account/get_property_unit_bank_accounts/${property_unit_id}`;
    const { data: result } = await http.get(fullApiEndpoint);

    if (!result || result.length === 0) {
      toast.info("Please link a bank account to this unit");
    }

    return result;
  } catch (error) {
    console.error("Error fetching property unit bank accounts:", error);

    // Handle specific error cases
    if (!error.response) {
      // Network error or server not reachable
      toast.error(
        "Unable to connect to the server. Please check your internet connection."
      );
    }

    if (error.response?.status === 500) {
      toast.error("Server encountered an error. Please try again later.");
    }

    // For any other errors, throw with a generic message
    toast.error("Failed to fetch bank accounts. Please try again later.");
  }
}

//get_property_bank_account_details
export async function getPropertyBankAccountByPropBankId(
  property_bank_account_id
) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/bank_account/get_property_bank_account_details_by_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_bank_account_id: property_bank_account_id,
  });

  return result;
}

//get_bank_account_details_by_id
export async function getBankAccountDetailsById(bank_account_id) {
  const fullApiEndpoint =
    config.apiEndpoint +
    `/bank_account/get_bank_account_details_by_id/${bank_account_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

export async function updatePlaidAccountDetails(public_token) {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    config.apiEndpoint + `/bank_account/create_or_update_plaid_account_details`;
  const { data: result } = await http.post(fullApiEndpoint, {
    public_token,
    user_id,
  });
  return result;
}
