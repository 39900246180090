import React from "react";
import { Link } from "react-router-dom";
import ShimmerEffect from "../../../reusable/loading/ShimmerEffect";
import { formatStripeAmount } from "../../../../services/utils/currencyUtils";
import { formatDate } from "../../../../services/utils/dateUtils";

const SubscriptionStatusCard = ({
  isLoadingUserInfo,
  subscriptionStatus,
  isDarkMode,
  firstName,
}) => {
  return (
    <div className="card">
      <div className="card-body p-0">
        {isLoadingUserInfo ? (
          <ShimmerEffect
            type="user-info"
            height="300px"
            darkMode={isDarkMode}
          />
        ) : subscriptionStatus?.data ? (
          <>
            {/* Subscription Banner */}
            {(() => {
              const now = Math.floor(Date.now() / 1000);

              const isTrialing =
                subscriptionStatus.data.status === "trialing" &&
                now < subscriptionStatus.data.trial_end;
              const isActive = subscriptionStatus.data.status === "active";
              const isExpired =
                (subscriptionStatus.data.status === "trialing" &&
                  now >= subscriptionStatus.data.trial_end) ||
                subscriptionStatus.data.status === "canceled" ||
                subscriptionStatus.data.status === "unpaid";

              if (isTrialing) {
                return (
                  <div className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center">
                    <i className="ri-timer-line text-warning me-2 fs-16"></i>
                    <div className="flex-grow-1 text-truncate">
                      Trial ends on{" "}
                      {formatDate(subscriptionStatus.data.trial_end * 1000)}
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        to="/paywall"
                        className="text-reset text-decoration-underline"
                      >
                        <b>Upgrade</b>
                      </Link>
                    </div>
                  </div>
                );
              } else if (isActive) {
                return (
                  <div className="alert alert-success border-0 rounded-0 m-0 d-flex align-items-center">
                    <i className="ri-checkbox-circle-line text-success me-2 fs-16"></i>
                    <div className="flex-grow-1 text-truncate">
                      Active Subscription
                    </div>
                  </div>
                );
              } else if (isExpired) {
                return (
                  <div className="alert alert-danger border-0 rounded-0 m-0 d-flex align-items-center">
                    <i className="ri-error-warning-line text-danger me-2 fs-16"></i>
                    <div className="flex-grow-1 text-truncate">
                      Your subscription has expired
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        to="/paywall"
                        className="text-reset text-decoration-underline"
                      >
                        <b>Renew Now</b>
                      </Link>
                    </div>
                  </div>
                );
              }
            })()}

            <div className="row align-items-end">
              <div className="col-sm-12">
                <div className="p-4">
                  <h5 className="fs-18 lh-base mb-1">
                    Welcome back, {firstName || "User"}!
                  </h5>

                  {/* Subscription Status and Plan Details */}
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <span
                      className={`badge ${
                        subscriptionStatus.data.status === "active"
                          ? "bg-success"
                          : "bg-warning"
                      }`}
                    >
                      {subscriptionStatus.data.status === "active"
                        ? "Active"
                        : "Trial"}
                    </span>
                    {subscriptionStatus.data?.plan && (
                      <span className="text-muted fs-14">
                        {formatStripeAmount(
                          subscriptionStatus.data.plan.amount
                        )}
                        /{subscriptionStatus.data.plan.interval}
                      </span>
                    )}
                  </div>

                  {/* Billing Details */}
                  <div className="d-flex flex-column gap-2">
                    {subscriptionStatus.data.status === "trialing" && (
                      <div className="d-flex justify-content-between">
                        <span className="text-muted">Trial Period:</span>
                        <span className="fw-semibold">
                          {formatDate(
                            subscriptionStatus.data?.trial_start * 1000
                          )}{" "}
                          -{" "}
                          {formatDate(
                            subscriptionStatus.data?.trial_end * 1000
                          )}
                        </span>
                      </div>
                    )}

                    {subscriptionStatus.data.status === "active" && (
                      <div className="d-flex justify-content-between">
                        <span className="text-muted">Next Billing Date:</span>
                        <span className="fw-semibold">
                          {formatDate(
                            subscriptionStatus.data?.current_period_end * 1000
                          )}
                        </span>
                      </div>
                    )}

                    <div className="d-flex justify-content-between">
                      <span className="text-muted">Billing Method:</span>
                      <span className="fw-semibold text-capitalize">
                        {subscriptionStatus.data?.collection_method
                          ? subscriptionStatus.data.collection_method.replace(
                              "_",
                              " "
                            )
                          : "Not set"}
                      </span>
                    </div>
                  </div>

                  {subscriptionStatus.data.status === "trialing" && (
                    <div className="mt-4">
                      <Link to="/paywall" className="btn btn-warning w-100">
                        <i className="ri-shield-star-line me-1"></i>
                        Upgrade Before Trial Ends
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-4 text-center">
            <div className="mb-4">
              <i
                className="ri-building-line text-primary"
                style={{ fontSize: "48px" }}
              ></i>
            </div>
            <h5 className="fs-18 mb-3">Get Started with Premium Features</h5>
            <p className="text-muted mb-4">
              Unlock advanced property management tools and grow your portfolio
              with our subscription plans.
            </p>
            <Link to="/paywall" className="btn btn-primary">
              <i className="ri-rocket-line me-1"></i>
              View Plans
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionStatusCard;
