import React, { createContext, useContext, useState } from "react";

const SubscriptionBillingContext = createContext();

export function SubscriptionBillingProvider({ children }) {
  //need to get the invoice data and set this
  const [billingDetails, setBillingDetails] = useState({
    stripe_customer_id: "",
    customer: "",
    customer_address: "",
    customer_email: "",
    customer_name: "",
    customer_phone: "",
    customer_tax_exempt: "",
    customer_tax_ids: "",
    paid: false,
    amount_due: 0,
    amount_paid: 0,
    amount_remaining: 0,
    status: "",
    attempt_count: 0,
    created: "",
    description: "",
    discount: "",
    due_date: "",
    currency: "",
    hosted_invoice_url: "",
    invoice_pdf: "",
    id: "",
    lines: [],
    next_payment_attempt: "",
    number: "",
    period_end: "",
    period_start: "",
    receipt_number: "",
    payment_intent: "",
    payment_settings: "",
  });

  return (
    <SubscriptionBillingContext.Provider
      value={{ billingDetails, setBillingDetails }}
    >
      {children}
    </SubscriptionBillingContext.Provider>
  );
}

export function useSubscriptionBilling() {
  return useContext(SubscriptionBillingContext);
}
