//Register landlord next and let the questions come from the api

import React, { useEffect, useState, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User, UserProfile } from "../../../services/authentication/model/user";
//import load_img from "../../../styletheme/assets/img/svgicons/loader.png";
//import fav_img from "../../../styletheme/assets/img/brand/favicon.png";

import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Form from "../../reusable/form/form";
import Joi from "joi-browser";
import {
  findEmailByLoginCode,
  loginUser,
  sendLoginRequest,
} from "../../../services/authentication/auth";
import Input from "../../reusable/form/Input";

import { toast } from "react-toastify";
import LoaderView from "../../reusable/loading/loaderView";
import logo from "../../../styletheme/logos/logo.png";
import ImageCarousel from "../../reusable/carousel/imageCarousel";
import config from "../../../constants/config.json";
import { slideShowImages as defaultSlideShowImages } from "../../../constants/slideShowImages";
//import "react-toastify/dist/ReactToastify.css";
//TODO: set the background to be gray so that the nav bar is visble
const LoginPage = () => {
  //for login
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { loginCode } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    emailAddress: "",
    loginCode: "",
  });

  const [errors, setErrors] = useState({});
  const schema = {
    emailAddress: Joi.string().email().required().label("Email Address"), //use joi in node as well
    loginCode: Joi.string().min(7).required().label("Login Code"),
  };

  //this is the home page (only authenticated people)
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: "Relevant news about Gurenter and its offerings",
  });

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  const [isRequestEmailSent, setIsRequestEmailSent] = useState(false);

  useEffect(() => {
    // Check if user is already logged in using config constant
    const userToken = localStorage.getItem(config.access_token);
    if (userToken) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);

    getAdditionalInfo();

    //after loading
    setLoading({ isLoading: false });
  }, []);

  const getAdditionalInfo = async () => {
    if (loginCode) {
      //alert('Login Code: ' + loginCode);
      //find the email address based on the login code
      //findEmailByLoginCode
      const response = await findEmailByLoginCode(loginCode);

      if (response.isError) {
        toast.error(response.message);
        return;
      }
      //email
      data.emailAddress = response.message;

      //email was sent
      setIsRequestEmailSent(true);
      //set the login code
      data.loginCode = loginCode;
    }
  };

  //TODO: Form Validation (Need to find a way to make this more streamlined)
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    //console.log(errors);

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = ({ currentTarget: input }) => {
    //input validation

    const errorMessage = validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    //data seccount (model)
    //const data = { ...data };

    data[input.name] = input.value;

    //toast.error(input.value);
    //setData({ data: data });
    setErrors({ errors });
  };

  const sendLoginCodeRequest = async () => {
    //login function
    try {
      //payload
      const user_id = "";
      const first_name = "";
      const last_name = "";
      const email_address = data.emailAddress;

      let userPayload = new User(user_id, first_name, last_name, email_address);

      const message = await sendLoginRequest(userPayload);

      //console.log('API Response on UI');
      //console.log(message);
      if (message.is_error) {
        toast.error(message.server_message);
        return;
      } else if (message.is_error === false) {
        toast.success(message.server_message);
        //if successful then set that the email has been sent
        setIsRequestEmailSent(true);
      }
    } catch (ex) {
      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.emailAddress = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  const doSubmit = async () => {
    //login function
    try {
      //const { data } = this.state;
      //payload
      const user_id = "";
      const first_name = "";
      const last_name = "";
      const email_address = data.emailAddress;
      const login_code = data.loginCode;

      let loginPayLoad = new User(
        user_id,
        first_name,
        last_name,
        email_address,
        null,
        null,
        null,
        null,
        login_code
      );

      //console.log(loginPayLoad);
      //alert("Login called");
      //return;
      const response = await loginUser(loginPayLoad);

      //alert(response.message);
      //get and set the profile
      if (response.isError) {
        toast.error(response.server_message);
        return;
      } else if (response.isError === false) {
        //force update
        //forceUpdate();

        // Navigate to the dashboard
        //navigate('/dashboard');

        forceUpdate();

        toast.success(response.server_message);
        //force refresh the nav bar
        //window.location.reload();

        window.location = "/dashboard";
      }

      //Not working
      // alert(`Login called ${test}`);

      //toast.success('Logged In Successfully');
    } catch (ex) {
      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.emailAddress = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };
  //for the slide show
  const [slideShowImages, setSlideShowImages] = useState(
    defaultSlideShowImages
  );

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && <LoaderView />}
      {/*/Loader */}

      <div className="page-content">
        <div className="container-fluid">
          <div className="row no-gutter">
            <div className="col-xl-12">
              <div className="row justify-content-center">
                <div
                  id="centerLoginForm"
                  className="col-md-4 col-lg-3 col-xl-3 center-login"
                >
                  {/* Login Form */}
                  <div className="card mt-4 card-bg-fill">
                    <div className="card-body p-4">
                      <div className="text-center mt-2 d-flex flex-column align-items-center">
                        <img
                          src={logo}
                          width="60"
                          height="60"
                          alt="Gurenter Logo"
                          className="mb-3"
                        />
                        <h5 className="text-primary mb-2">Welcome Back !</h5>
                        <p className="text-muted mb-0">
                          Sign in to continue to Gurenter.
                        </p>
                      </div>
                      <div className="p-2 mt-4">
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <Input
                              name="emailAddress"
                              type="email"
                              error={errors.emailAddress}
                              value={data.emailAddress}
                              placeholder="Write your Email Address."
                              label="Email Address"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-3">
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              {isRequestEmailSent && (
                                <div className="form-group">
                                  <Input
                                    name="loginCode"
                                    type="password"
                                    error={errors.loginCode}
                                    value={data.loginCode}
                                    placeholder="Write your Login Code."
                                    label="Login Code"
                                    onChange={handleChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="mt-4">
                            {isRequestEmailSent && (
                              <button
                                //disabled={validate()}
                                className="btn btn-success w-100"
                              >
                                Sign In
                              </button>
                            )}

                            {!isRequestEmailSent && (
                              <button
                                //disabled={validate()}
                                onClick={() => {
                                  sendLoginCodeRequest();
                                }}
                                className="btn btn-success w-100"
                              >
                                Send Login Code
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    {/*end card body*/}
                  </div>
                  {/*end card*/}

                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      Don't have an account ?{" "}
                      <Link
                        to="/signup"
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        {" "}
                        Sign Up{" "}
                      </Link>{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-8 col-lg-4 col-xl-4">
                  {/* Slides Reusable */}
                  <ImageCarousel slideShowImages={slideShowImages} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
