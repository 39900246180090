import config from '../../../constants/config.json';

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  UNPAID: 'unpaid'
};

export const checkSubscriptionStatus = () => {
  const isPaid = localStorage.getItem(config.is_paid) === 'true';
  const subscriptionStatus = localStorage.getItem(config.subscription_status);
  const trialDaysLeft = parseInt(localStorage.getItem(config.number_of_free_trial_days_left) || '0');
  
  const now = Math.floor(Date.now() / 1000);
  
  if (!subscriptionStatus) return false;
  
  return (
    isPaid && 
    (subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
    (subscriptionStatus === SUBSCRIPTION_STATUS.TRIALING && trialDaysLeft > 0))
  );
};

export const getSubscriptionDetails = () => {
  return {
    isPaid: localStorage.getItem(config.is_paid) === 'true',
    status: localStorage.getItem(config.subscription_status),
    trialDaysLeft: parseInt(localStorage.getItem(config.number_of_free_trial_days_left) || '0'),
    subscriptionName: localStorage.getItem(config.subscription_name),
    subscriptionId: localStorage.getItem(config.subscription_id)
  };
}; 