import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import Box from "@mui/material/Box";
import NoRecordsFound from "../../../tickets/list/sub/noRecordsFound";
import { getRentalContractsForPendingContractsByUnitId } from "../../../../../services/rent/tenant/invite/tenantRentalContractService";
import { formatCurrency } from "../../../../../services/utils/currencyUtils";

import { ThemeProvider, createTheme } from "@mui/material/styles";

//this is for the pending tenant request for existing tenants (created prior to Gurenter)
const TenantPendingRequest = ({ isDarkMode }) => {
  const navigate = useNavigate();
  //propertyUnitId param
  const { propertyUnitId } = useParams();

  const [pendingContracts, setPendingContracts] = useState([
    {
      contract_detail: "",
      contract_template_id: "",
      created_at: "",
      end_date: "",
      is_active: true,
      is_deleted: false,
      monthly_rent: 0,
      owner_manager_email: "",
      owner_manager_name: "",
      owner_manager_user_id: "",
      pet_deposit: 0,
      pet_rent: 0,
      portfolio_id: "",
      portfolio_name: "",
      portfolio_ownership_id: "",
      property_id: "",
      property_name: "",
      property_unit_id: "",
      property_unit_name: "",
      rental_contract_id: "",
      security_deposit: 0,
      start_date: "",
      tenant_id: "",
      updated_at: "",
      tenant: {
        campaign_id: "",
        created_at: "",
        email_address: "",
        expirees_at: "",
        first_name: "",
        is_active: true,
        is_deleted: false,
        is_verified: false,
        last_name: "",
        middle_name: "",
        phone_number: "",
        profile_img_url: "",
        stripe_customer_id: "",
        tenant_id: "",
        updated_at: "",
      },
    },
  ]);

  //Click the row to get the contract details, tenant details, property details, and owner/manager details
  //have the ability to set is_active to true or false or delete it or resend the contract
  //can edit the contract on this screen and resend it (the email and let the user know what changed)
  //need to style the email to look nice
  const pendingContractsColumns = [
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Link to={`/tenant/${params.row.tenant_id}`}>
          {params.row?.tenant?.first_name}
        </Link>
      ),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Link to={`/tenant/${params.row.tenant_id}`}>
          {params.row?.tenant?.last_name}
        </Link>
      ),
    },
    {
      field: "email_address",
      headerName: "Email Address",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Link to={`/tenant/${params.row.tenant_id}`}>
          {params.row?.tenant?.email_address}
        </Link>
      ),
    },
    {
      field: "property_name",
      headerName: "Property Name",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Link to={`/property/${params.row.property_id}`}>
          {params.row.property_name}
        </Link>
      ),
    },
    {
      field: "property_unit_name",
      headerName: "Unit Name",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Link to={`/propertyunit/${params.row.property_unit_id}`}>
          {params.row.property_unit_name}
        </Link>
      ),
    },
    {
      field: "monthly_rent",
      headerName: "Monthly Rent",
      width: 150,
      editable: false,
      renderCell: (params) => `${formatCurrency(params.row.monthly_rent)}`,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <span>{new Date(params.row.start_date).toLocaleDateString()}</span>
      ),
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <span>{new Date(params.row.end_date).toLocaleDateString()}</span>
      ),
    },

    {
      field: "owner_manager_name",
      headerName: "Owner/Manager",
      width: 200,
      editable: false,
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <span>
          {params.row.is_active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Inactive</span>
          )}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              navigate(`/contract/${params.row.rental_contract_id}`);
            }}
          >
            <i className="ri-eye-fill align-middle me-1"></i> View
          </button>
        </div>
      ),
    },
  ];

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllPendingContractsForExistingTenants();
  }, []);

  const fetchAllPendingContractsForExistingTenants = async () => {
    //fetch all pending contracts for existing tenants
    const contractsFound = await getRentalContractsForPendingContractsByUnitId(
      propertyUnitId
    );
    console.log("Contracts Found");
    console.log(contractsFound);

    if (contractsFound) {
      setPendingContracts(contractsFound);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card" id="ticketsList">
          <div className="card-header border-0">
            <div className="d-flex align-items-center">
              <h5 className="card-title mb-0 flex-grow-1">
                Pending Tenant Contract for Existing Tenants
              </h5>
            </div>
            <p className="text-muted">
              This is for the pending tenant requests for existing tenants
              (created prior to Gurenter)
            </p>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <ThemeProvider theme={theme}>
                <Box sx={{ height: 650, width: "100%" }}>
                  <DataGrid
                    rows={pendingContracts || []}
                    getRowId={(row) => row.rental_contract_id || 0}
                    columns={pendingContractsColumns}
                    pageSize={5}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    onRowClick={(row) => {
                      //Define this next
                      navigate(`/contract/${row.row.rental_contract_id}`);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                      noResultsOverlay: NoRecordsFound,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </Box>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantPendingRequest;
