import http from "../../services/general/httpService";
import config from "../../constants/config.json";

///portfolio/create_or_update_portfolio
export async function createOrUpdatePortfolio(portfolio) {
    const fullApiEndpoint = config.apiEndpoint + `/portfolio/create_or_update_portfolio`;
    const {data: result} = await http.post(fullApiEndpoint, portfolio);

    return result;
}

///portfolio/get_portfolio_by_ownership_id is a post
export async function getPortfolioByOwnershipId(ownershipId) {
    const fullApiEndpoint = config.apiEndpoint + `/portfolio/get_portfolio_by_ownership_id`;
    const {data: result} = await http.post(fullApiEndpoint, {ownership_id :ownershipId});

    return result;
}

//get_portfolio_by_portfolio_id
export async function getPortfolioByPortfolioId(portfolioId) {
    const fullApiEndpoint = config.apiEndpoint + `/portfolio/get_portfolio_by_portfolio_id`;
    const {data: result} = await http.post(fullApiEndpoint, {portfolio_id :portfolioId});

    return result;
}
