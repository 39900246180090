import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  Check,
  X,
  Upload,
  FileText,
  Clock,
  CheckCircle,
  Circle,
  XCircle,
  AlertCircle,
  ChevronDown,
  ChevronUp,
  Sparkles,
} from "lucide-react";
import {
  addPrivateNotesToApplication,
  analyzeTenant,
  deletePrivateNoteForTenantRentalApplicationId,
  getAllPrivateNotesForTenantRentalApplicationId,
  getTenantForTenantId,
  getTenantFullApplication,
  updateTenantApplicationStatus,
} from "../../../../../../../../services/propertyUnits/tenantScreening/prospectives/tenantRentalApplicationManagement";
import { Tab, Nav, Button, ProgressBar, Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import "./sub/ProspectiveTenantProfileDetail.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import { getAdvertisementListingByCampaignId } from "../../../../../../../../services/propertyUnits/advertisment/advertismentManagement";
import { getScreeningPackById } from "../../../../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement";
import PageTitleBox from "../../../../../../../reusable/title/pageTitleBox";
import { devLog } from "../../../../../../../../services/utils/logger";
import LoaderView from "../../../../../../../reusable/loading/loaderView";
import DocumentUploadSection from "./sub/tabSections/documentUploadSection";
import { formatDate } from "../../../../../../../../services/utils/dateUtils";
import config from "../../../../../../../../constants/config.json";
import PrivateNotesSection from "./sub/PrivateNotesSection";

const screeningPhases = [
  { name: "Pre-screening", field: "has_passed_pre_screening" },
  { name: "Viewing", field: "has_passed_viewing_screening" },
  { name: "Application", field: "has_passed_application_screening" },
  { name: "Background Check", field: "has_passed_background_check" },
  { name: "Final Steps", field: "has_been_selected_for_the_rental" },
];

const ProspectiveTenantProfileDetail = ({ isDarkMode }) => {
  const pageTitle = "Prospective Tenant Profile";
  const { tenantId } = useParams();

  const navigate = useNavigate();
  const [tenantData, setTenantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("personal");
  const [privateComment, setPrivateComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [advertisement, setAdvertisement] = useState(null);
  const [screeningPack, setScreeningPack] = useState(null);
  const [isAiSummaryExpanded, setIsAiSummaryExpanded] = useState(false);

  //the tenant full application
  const [tenantFullApplication, setTenantFullApplication] = useState({
    tenant_rental_application_id: "",
    tenant_id: "",
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",

    property_unit_id: "",
    campaign_id: "",
    co_applicant_link_code: "",
    application_status: "",
    application_date: "",
    has_passed_pre_screening: false,
    has_passed_viewing_screening: false,
    has_passed_application_screening: false,
    has_passed_application_screening_date: "",
    has_paid_application_fee: false,
    has_signed_lease: false,
    has_paid_first_month_rent: false,
    has_paid_last_month_rent: false,
    has_paid_security_deposit: false,
    needs_guarantor: false,
    has_passed_background_check: false,
    has_passed_credit_check: false,
    has_passed_eviction_check: false,
    has_passed_criminal_check: false,
    has_been_selected_for_viewing: false,
    has_been_selected_for_the_rental: false,
    has_been_hard_rejected: false,
    //to tell the application is ready to be submitted start
    has_completed_application: false,
    campaign_completed_at: "",
    signed_acceptance_of_terms: false,
    signed_acceptance_of_terms_at: "",
    signed_acceptance_of_terms_name: "",
    //to tell the application is ready to be submitted end
    has_completed_the_universal_list: false,
    co_applicant_connections: [
      {
        co_applicant_link_code: "",
        co_applicant_link_codes: [],
        primary_applicant_link_code: "",
        total_income: 0,
        created_at: new Date(),
        updated_at: new Date(),

        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        relationship: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
        is_over_18: true,
        date_of_birth: new Date(),
      },
    ],
    prescreening_info: {
      duration_of_current_residence: 0,
      total_monthly_income: 0,
      sources_of_income: [],
      number_of_occupants: 0,
      reason_for_leaving: "",
      desired_move_in_date: "",
      does_any_one_smoke: false,
      has_pets: false,
      describe_pets: "",
    },
    unit_viewing: {
      unit_viewing_id: "",
      campaign_id: "",
      duration: 0,
      end_time: "",
      note: "",
      start_time: "",
      status: "",
      tenant_id: "",
      tenant_name: "",
      is_expired: "",
      is_cancelled: "",
      created_at: "",
      modified_at: "",
      has_been_viewed: false,
    },
    personal_references: [
      {
        name: "",
        phone_number: "",
        email: "",
        relationship: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
      },
    ],
    emergency_contacts: [
      {
        name: "",
        phone_number: "",
        email: "",
        relationship: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
      },
    ],
    current_addresses: [
      {
        address_type: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
        duration_of_residence: 0,
        reason_for_leaving: "",
        is_current_residence: false,
        date_of_residence_start: new Date(),
        date_of_residence_end: new Date(),
        landlord_name: "",
        landlord_phone_number: "",
        landlord_email: "",
        monthly_rent: 0,
      },
    ],
    employment_income_info: [
      {
        employer_name: "",
        employer_address: "",
        employer_city: "",
        employer_state: "",
        employer_zip_code: "",
        employer_phone_number: "",
        employer_email: "",
        employer_website: "",
        employer_start_date: new Date(),
        employer_end_date: new Date(),
        employer_reason_for_leaving: "",
        employer_supervisor_name: "",
        employer_supervisor_phone_number: "",
        employer_supervisor_email: "",
        job_category_id: "",
        job_category: "",
        annual_income: 0,
      },
    ],
    previous_addresses: [
      {
        address_type: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
        duration_of_residence: 0,
        reason_for_leaving: "",
        is_current_residence: false,
        date_of_residence_start: new Date(),
        date_of_residence_end: new Date(),
        landlord_name: "",
        landlord_phone_number: "",
        landlord_email: "",
        monthly_rent: 0,
      },
    ],
    tenant_signature: {
      signed_by: "",
      signature_url: "",
      date: "",
    },
    vehicles: [
      {
        vehicle_type: "",
        vehicle_year: "",
        vehicle_make: "",
        vehicle_model: "",
        vehicle_vin: "",
        vehicle_license_plate: "",
        vehicle_license_plate_state: "",
      },
    ],
    media_proof_references: [
      {
        proof_reference_id: "",
        proof_reference_url: "",
        proof_reference_type: "",
        proof_reference_description: "",
        uploaded_at: new Date(),
        uploaded_by: "",
      },
    ],
    //existing attachments
    attachments: [
      {
        url: "",
        file_name: "",
        file_size: 0,
        file_size_formatted: "",
        file_type: "",
        unique_file_name: "",
      },
    ],
    attachment_uploads: [
      {
        file_name: "",
        base64_data: "",
      },
    ],
  });

  const [aiSummary, setAiSummary] = useState({
    tenant_id: "",
    campaign_id: "",
    summary: "",
    highlights: [],
    concerns: [],
    generated_at: "",
    ml_model_name: "",
    cached: false,
  });

  const [privateNotes, setPrivateNotes] = useState([
    {
      node_id: "",
      landlord_id: "",
      tenant_rental_application_id: "",
      property_unit_id: "",
      property_id: "",
      portfolio_id: "",
      property_manager_id: "",
      reviewer_role: "",
      notes: "",
      timestamp: new Date(),
    },
  ]);

  const generateAiSummary = async (_tenant_id, _campaign_id) => {
    const result = await analyzeTenant(_tenant_id, _campaign_id);
    setAiSummary(result);
    devLog("ai summary", result);
  };

  //fetch all private notes
  const fetchAllPrivateNotes = async (_tenant_rental_application_id) => {
    const result = await getAllPrivateNotesForTenantRentalApplicationId(
      _tenant_rental_application_id
    );
    if (result) {
      devLog("private notes", result);
      setPrivateNotes(result);
    }
  };

  const deletePrivateNote = async (_tenant_rental_application_id, _note_id) => {
    const result = await deletePrivateNoteForTenantRentalApplicationId(
      _tenant_rental_application_id,
      _note_id
    );
    if (result) {
      devLog("private note deleted", result);
      toast.success("Private note deleted successfully");
      fetchAllPrivateNotes(_tenant_rental_application_id);
    } else {
      toast.error("Failed to delete private note. Please try again.");
    }
  };

  useEffect(() => {
    fetchTenantData();
  }, [tenantId]);

  const fetchTenantData = async () => {
    try {
      const result = await getTenantForTenantId(tenantId);
      if (result.error) {
        throw new Error(result.error);
      }
      setTenantData(result);
      setApplicationStatus(result.application.application_status);

      // Fetch advertisement using campaign_id
      const advert = await getAdvertisementListingByCampaignId(
        result.application.campaign_id
      );
      setAdvertisement(advert);

      // Fetch screening pack using screening_pack_id from advertisement
      if (advert && advert.screening_pack_id) {
        const screeningPackData = await getScreeningPackById(
          advert.screening_pack_id
        );
        setScreeningPack(screeningPackData);

        //fetch the tenant full application
        fetchTenantFullApplication(tenantId, advert.campaign_id);
      }

      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to load tenant data. Please try again later.");
      setLoading(false);
    }
  };

  const fetchTenantFullApplication = async (_tenant_id, _campaign_id) => {
    const result = await getTenantFullApplication(_campaign_id, _tenant_id);
    if (result.error) {
      throw new Error(result.error);
    }
    if (result) {
      devLog("tenant full application", result);
      setTenantFullApplication(result);
      fetchAllPrivateNotes(result.tenant_rental_application_id);

      //get the ai summary only if the tenant has completed the application
      if (result.has_completed_application) {
        generateAiSummary(_tenant_id, _campaign_id);
      }
    }
  };

  const handleUpdateStatus = async (action) => {
    try {
      let updateData = { tenant_id: tenantId };

      switch (action) {
        case "approve_prescreening":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: false,
            has_passed_application_screening: false,
            has_passed_background_check: false,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
            has_been_hard_rejected: false,
          };
          break;
        case "approve_viewing":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: false,
            has_passed_background_check: false,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
            has_been_hard_rejected: false,
          };
          break;
        case "approve_application":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: true,
            has_passed_application_screening_date: new Date().toISOString(),
            has_completed_application: true,
            has_passed_background_check: false,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
            has_been_hard_rejected: false,
          };
          break;
        case "approve_background_check":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: true,
            has_passed_background_check: true,
            has_passed_credit_check: true,
            has_passed_eviction_check: true,
            has_passed_criminal_check: true,
            has_been_selected_for_the_rental: false,
            application_status: "in_progress",
            has_been_hard_rejected: false,
          };
          break;
        case "approve_rental":
          updateData = {
            ...updateData,
            has_passed_pre_screening: true,
            has_been_selected_for_viewing: true,
            has_passed_viewing_screening: true,
            has_passed_application_screening: true,
            has_passed_background_check: true,
            has_passed_credit_check: true,
            has_passed_eviction_check: true,
            has_passed_criminal_check: true,
            has_been_selected_for_the_rental: true,
            application_status: "approved",
            has_been_hard_rejected: false,
          };
          break;
        case "reject":
          updateData = {
            ...updateData,
            has_been_hard_rejected: true,
            application_status: "rejected",
          };
          break;
        default:
          throw new Error("Invalid action");
      }

      await updateTenantApplicationStatus(updateData);
      const updatedTenant = await getTenantForTenantId(tenantId);
      setTenantData(updatedTenant);
      setApplicationStatus(updatedTenant.application.application_status);
      toast.success("Application status updated successfully");
      setShowModal(false);
    } catch (err) {
      console.error("Error updating application status:", err);
      toast.error("Failed to update application status. Please try again.");
    }
  };

  const handlePrivateCommentSubmit = async (e) => {
    e.preventDefault();
    console.log("Private comment submitted:", privateComment);
    /*
   landlord_id: Optional[str] = None
    tenant_rental_application_id: str
    property_unit_id: str
    property_id: Optional[str] = None
    portfolio_id: Optional[str] = None
    property_manager_id: Optional[str] = None
    reviewer_role: Optional[str] = None
    notes: str
    */
    const landlord_id = localStorage.getItem(config.user_id);

    const newPrivateNote = {
      landlord_id: landlord_id,
      tenant_rental_application_id:
        tenantFullApplication.tenant_rental_application_id,
      property_unit_id: advertisement.property_unit_id,
      property_id: advertisement.property_unit.property_id,
      portfolio_id: "", //TODO: set in the future
      property_manager_id: "", //TODO: set in the future
      reviewer_role: "landlord",
      notes: privateComment,
    };
    const result = await addPrivateNotesToApplication(newPrivateNote);

    if (result.success) {
      setPrivateNotes([...privateNotes, result]);
      setPrivateComment("");
      fetchAllPrivateNotes(tenantFullApplication.tenant_rental_application_id);
      toast.success("Private comment added successfully");
    } else {
      toast.error("Failed to add private comment. Please try again.");
    }
  };

  const handleActionClick = (action) => {
    setModalAction(action);
    setShowModal(true);
  };

  const getRejectionPhase = () => {
    if (applicationStatus === "rejected") {
      for (let i = 0; i < screeningPhases.length; i++) {
        if (!application[screeningPhases[i].field]) {
          return i;
        }
      }
    }
    return -1;
  };

  // Application Status Modes:
  // 1. REJECTED - Application has been rejected
  // 2. APPROVED - Application has been fully approved
  // 3. AWAITING PRE-SCREENING - Application is in the initial pre-screening phase
  // 4. SCHEDULED FOR VIEWING - Applicant has passed pre-screening and is scheduled for a viewing
  // 5. APPLICATION UNDER REVIEW - Full application is being reviewed
  // 6. BACKGROUND CHECK IN PROGRESS - Background check is being conducted
  // 7. FINAL APPROVAL PENDING - All checks passed, waiting for final approval
  // 8. IN PROGRESS - Default status when the application is being processed but doesn't fit other categories

  const getStatusDisplay = () => {
    if (applicationStatus === "rejected") {
      return "REJECTED";
    } else if (applicationStatus === "approved") {
      return "APPROVED";
    } else {
      const currentPhase = getCurrentPhase();
      switch (screeningPhases[currentPhase]?.name) {
        case "Pre-screening":
          return "AWAITING PRE-SCREENING";
        case "Viewing":
          return "SCHEDULED FOR VIEWING";
        case "Application":
          return "APPLICATION UNDER REVIEW";
        case "Background Check":
          return "BACKGROUND CHECK IN PROGRESS";
        case "Final Steps":
          return "FINAL APPROVAL PENDING";
        default:
          return "IN PROGRESS";
      }
    }
  };

  const handleStartInterview = () => {
    // Navigate to the interview mode screen
    //This screen will make quick acccess to the information easy and fast for making notes and so on
    console.log("Starting interview mode");
    // navigate('/interview-mode/' + tenantId);  // Uncomment and adjust path as needed
  };

  const handlePrepareContract = () => {
    // Navigate to the contract preparation screen or open a modal
    console.log("Preparing rental contract");
    // navigate('/prepare-contract/' + tenantId);  // Uncomment and adjust path as needed
  };

  const renderCriteriaIcon = (meets) => {
    return meets ? (
      <Tooltip title="Meets requirement">
        <CheckCircleIcon
          color="success"
          fontSize="small"
          style={{ marginLeft: "8px" }}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Does not meet requirement">
        <CancelIcon
          color="error"
          fontSize="small"
          style={{ marginLeft: "8px" }}
        />
      </Tooltip>
    );
  };

  if (loading) return <LoaderView />;
  if (error) return <div className="alert alert-danger mt-5">{error}</div>;
  if (!tenantData)
    return (
      <div className="alert alert-warning mt-5">No tenant data found.</div>
    );

  const { application, profile } = tenantData;

  const calculateProgress = () => {
    if (applicationStatus === "rejected") {
      return 100;
    }
    const completedSteps = screeningPhases.filter(
      (phase) => application[phase.field]
    ).length;
    return (completedSteps / screeningPhases.length) * 100;
  };

  const getCurrentPhase = () => {
    if (applicationStatus === "rejected") {
      return screeningPhases.length;
    }
    for (let i = screeningPhases.length - 1; i >= 0; i--) {
      if (application[screeningPhases[i].field]) {
        return i + 1;
      }
    }
    return 0;
  };

  return (
    <div className="page-content" data-bs-theme={isDarkMode ? "dark" : "light"}>
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={pageTitle}
          previousPageTitle="Applicant Pool"
          previousPageLink={`/applicantPool/${application.campaign_id}`}
        />
        <div className="row">
          <div className="col-md-3">
            <div className="card mb-4">
              <div className="card-body text-center">
                <div className="d-flex justify-content-center mb-3">
                  <img
                    src={
                      profile.profile_img_url ||
                      "https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/defaultImages%2FNoImageFound.jpg1_.png?alt=media&token=75d79486-de11-4096-8b7d-d8b1945d71cf"
                    }
                    alt={profile.first_name}
                    className="rounded-circle img-fluid"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <h4>{`${profile.first_name} ${profile.last_name}`}</h4>
                <p className="text-muted">Prospective Tenant</p>
                <p className="text-muted mb-4">{profile.email_address}</p>
                {applicationStatus === "rejected" && (
                  <div className="alert alert-danger mb-3">
                    <strong>Application Rejected</strong>
                  </div>
                )}
                <div className="screening-progress-summary mb-3">
                  <ProgressBar
                    now={calculateProgress()}
                    label={`${Math.round(calculateProgress())}%`}
                    variant={
                      applicationStatus === "rejected" ? "danger" : "primary"
                    }
                  />
                </div>
                <p className="small text-muted mb-2">Application Progress</p>
                <p className="text-center">
                  Current Phase:{" "}
                  <strong>
                    {applicationStatus === "rejected"
                      ? `Rejected at ${
                          screeningPhases[getRejectionPhase()]?.name
                        }`
                      : screeningPhases[getCurrentPhase()]?.name ||
                        "Not Started"}
                  </strong>
                </p>
                {applicationStatus && (
                  <p className="text-center">
                    Status: <strong>{getStatusDisplay()}</strong>
                  </p>
                )}
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">Quick Actions</h5>
                <div className="d-grid gap-2">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleActionClick("approve_prescreening")}
                  >
                    Approve Pre-screening
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleActionClick("approve_viewing")}
                  >
                    Approve for Viewing
                  </Button>
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => handleActionClick("approve_application")}
                  >
                    Approve Application
                  </Button>
                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() =>
                      handleActionClick("approve_background_check")
                    }
                  >
                    Approve Background Check
                  </Button>
                  <Button
                    variant="outline-warning"
                    size="sm"
                    onClick={() => handleActionClick("approve_rental")}
                  >
                    Approve for Rental
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleActionClick("reject")}
                  >
                    Reject Application
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h3 className="card-title">Tenant Profile</h3>
                  <div>
                    <Button
                      variant="outline-primary"
                      className="me-2"
                      disabled={!application.has_passed_pre_screening}
                      onClick={() => handleStartInterview()}
                    >
                      Start Interview
                    </Button>
                    <Button
                      variant="outline-success"
                      disabled={!application.has_been_selected_for_the_rental}
                      onClick={() => handlePrepareContract()}
                    >
                      Prepare Rental Contract
                    </Button>
                  </div>
                </div>
                <Tab.Container
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Nav variant="tabs" className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="personal">
                        Personal Information
                      </Nav.Link>
                    </Nav.Item>
                    {/* Current and Previous Addresses */}
                    <Nav.Item>
                      <Nav.Link eventKey="addresses">Address History</Nav.Link>
                    </Nav.Item>
                    {/* Other Occupants */}
                    {application.prescreening_info.number_of_occupants > 1 && (
                      <Nav.Item>
                        <Nav.Link eventKey="otherOccupants">
                          Other Occupants
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    <Nav.Item>
                      <Nav.Link eventKey="employment">
                        Employment Information
                      </Nav.Link>
                    </Nav.Item>
                    {/* References */}
                    <Nav.Item>
                      <Nav.Link eventKey="references">References</Nav.Link>
                    </Nav.Item>
                    {/* Documents */}
                    <Nav.Item>
                      <Nav.Link eventKey="documents">Documents</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="screening">
                        Screening Progress
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="comments">Private Comments</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="personal">
                      <h5>Personal Information</h5>
                      {aiSummary && (
                        <div className="alert alert-info mb-3">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center">
                              <Sparkles size={24} className="me-2" />
                              <strong>AI-Generated Summary:</strong>
                            </div>
                            <button
                              className="btn btn-link p-0"
                              onClick={() =>
                                setIsAiSummaryExpanded(!isAiSummaryExpanded)
                              }
                            >
                              {isAiSummaryExpanded ? (
                                <ChevronUp size={20} />
                              ) : (
                                <ChevronDown size={20} />
                              )}
                            </button>
                          </div>
                          <p className="mb-2">{aiSummary.summary}</p>
                          {isAiSummaryExpanded && (
                            <>
                              {aiSummary.highlights &&
                                aiSummary.highlights.length > 0 && (
                                  <div className="mt-3">
                                    <strong>Highlights:</strong>
                                    <ul className="mb-0">
                                      {aiSummary.highlights.map(
                                        (highlight, index) => (
                                          <li key={index}>{highlight}</li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )}
                              {aiSummary.concerns &&
                                aiSummary.concerns.length > 0 && (
                                  <div className="mt-3">
                                    <strong>Concerns:</strong>
                                    <ul className="mb-0">
                                      {aiSummary.concerns.map(
                                        (concern, index) => (
                                          <li key={index}>{concern}</li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )}
                              <div className="mt-3">
                                <strong>Generated at:</strong>{" "}
                                {formatDate(aiSummary.generated_at)}
                              </div>
                            </>
                          )}
                          <p className="text-muted small mb-0 mt-2">
                            <em>
                              Note: This AI-generated summary may contain
                              inaccuracies. Please verify all information
                              independently.
                            </em>
                          </p>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <strong>Full Name:</strong>{" "}
                            {`${profile.first_name} ${profile.last_name}`}
                          </p>
                          <p>
                            <strong>Email:</strong> {profile.email_address}
                          </p>
                          <p>
                            <strong>Phone:</strong>{" "}
                            {profile.phone_number
                              ? profile.phone_number
                              : tenantFullApplication.phone_number}
                          </p>
                          <p>
                            <strong>Number of Occupants:</strong>{" "}
                            {application.prescreening_info.number_of_occupants}
                            {screeningPack &&
                              renderCriteriaIcon(
                                application.prescreening_info
                                  .number_of_occupants <=
                                  screeningPack.maximum_number_of_occupants
                              )}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <strong>Current Residence Duration:</strong>{" "}
                            {
                              application.prescreening_info
                                .duration_of_current_residence
                            }{" "}
                            years
                          </p>
                          <p>
                            <strong>Desired Move-in Date:</strong>{" "}
                            {new Date(
                              application.prescreening_info.desired_move_in_date
                            ).toLocaleDateString()}
                          </p>
                          <p>
                            <strong>Smoking:</strong>{" "}
                            {application.prescreening_info.does_any_one_smoke
                              ? "Yes"
                              : "No"}
                            {screeningPack &&
                              renderCriteriaIcon(
                                !application.prescreening_info
                                  .does_any_one_smoke ||
                                  screeningPack.is_smoking_allowed
                              )}
                          </p>
                          <p>
                            <strong>Pets:</strong>{" "}
                            {application.prescreening_info.has_pets
                              ? "Yes"
                              : "No"}
                            {screeningPack &&
                              renderCriteriaIcon(
                                !application.prescreening_info.has_pets ||
                                  screeningPack.is_pet_allowed
                              )}
                          </p>
                        </div>
                      </div>
                    </Tab.Pane>
                    {/* Address History */}
                    <Tab.Pane eventKey="addresses">
                      <h5 className="mb-4">Address History</h5>

                      {/* Current Address */}
                      <h6 className="mb-3">Current Address</h6>
                      {tenantFullApplication.current_addresses &&
                      tenantFullApplication.current_addresses.length > 0 ? (
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Address</th>
                              <th>Duration</th>
                              <th>Monthly Rent</th>
                              <th>Landlord Information</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tenantFullApplication.current_addresses.map(
                              (address, index) => (
                                <tr key={index}>
                                  <td>
                                    {address.address}
                                    <br />
                                    {address.city}, {address.state}{" "}
                                    {address.zip_code}
                                  </td>
                                  <td>
                                    {new Date(
                                      address.date_of_residence_start
                                    ).toLocaleDateString()}{" "}
                                    - Present
                                    <br />(
                                    {Math.floor(
                                      (new Date() -
                                        new Date(
                                          address.date_of_residence_start
                                        )) /
                                        (365.25 * 24 * 60 * 60 * 1000)
                                    )}{" "}
                                    years)
                                  </td>
                                  <td>${address.monthly_rent}</td>
                                  <td>
                                    {address.landlord_name}
                                    <br />
                                    {address.landlord_phone_number}
                                    <br />
                                    {address.landlord_email}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <p>No current address information available.</p>
                      )}

                      {/* Previous Addresses */}
                      <h6 className="mt-4 mb-3">Previous Addresses</h6>
                      {tenantFullApplication.previous_addresses &&
                      tenantFullApplication.previous_addresses.length > 0 ? (
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Address</th>
                              <th>Duration</th>
                              <th>Monthly Rent</th>
                              <th>Landlord Information</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tenantFullApplication.previous_addresses.map(
                              (address, index) => (
                                <tr key={index}>
                                  <td>
                                    {address.address}
                                    <br />
                                    {address.city}, {address.state}{" "}
                                    {address.zip_code}
                                  </td>
                                  <td>
                                    {new Date(
                                      address.date_of_residence_start
                                    ).toLocaleDateString()}{" "}
                                    -{" "}
                                    {new Date(
                                      address.date_of_residence_end
                                    ).toLocaleDateString()}
                                    <br />(
                                    {Math.floor(
                                      (new Date(address.date_of_residence_end) -
                                        new Date(
                                          address.date_of_residence_start
                                        )) /
                                        (365.25 * 24 * 60 * 60 * 1000)
                                    )}{" "}
                                    years)
                                  </td>
                                  <td>${address.monthly_rent}</td>
                                  <td>
                                    {address.landlord_name}
                                    <br />
                                    {address.landlord_phone_number}
                                    <br />
                                    {address.landlord_email}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <p>No previous address information available.</p>
                      )}
                    </Tab.Pane>
                    {/* Other Occupants */}
                    {application.prescreening_info.number_of_occupants > 1 && (
                      <Tab.Pane eventKey="otherOccupants">
                        <h5 className="mb-4">Other Occupants</h5>
                        {tenantFullApplication.co_applicant_connections &&
                        tenantFullApplication.co_applicant_connections.length >
                          0 ? (
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Relationship</th>
                                <th>Contact Information</th>
                                <th>Age</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tenantFullApplication.co_applicant_connections.map(
                                (occupant, index) => (
                                  <tr key={index}>
                                    <td>{`${occupant.first_name} ${occupant.last_name}`}</td>
                                    <td>{occupant.relationship}</td>
                                    <td>
                                      {occupant.phone_number && (
                                        <div>
                                          <strong>Phone:</strong>{" "}
                                          {occupant.phone_number}
                                        </div>
                                      )}
                                      {occupant.email && (
                                        <div>
                                          <strong>Email:</strong>{" "}
                                          {occupant.email}
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      {occupant.is_over_18 ? (
                                        <>
                                          Adult
                                          <br />
                                          <small>
                                            {new Date(
                                              occupant.date_of_birth
                                            ).toLocaleDateString()}
                                          </small>
                                        </>
                                      ) : (
                                        <>
                                          Minor
                                          <br />
                                          <small>
                                            {new Date(
                                              occupant.date_of_birth
                                            ).toLocaleDateString()}
                                          </small>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        ) : (
                          <p>No other occupants listed.</p>
                        )}
                      </Tab.Pane>
                    )}
                    <Tab.Pane eventKey="employment">
                      <h5>Employment Information</h5>
                      <div className="row pt-2">
                        <div className="col-md-2">
                          <p className="mb-0">
                            <strong>Job Category:</strong>{" "}
                            {application.prescreening_info.sources_of_income[0]
                              .job_category || "N/A"}
                          </p>
                        </div>
                        <div className="col-md-2">
                          <p className="mb-0">
                            <strong>Total Monthly Income:</strong> $
                            {application.prescreening_info.total_monthly_income.toLocaleString()}
                            {screeningPack &&
                              advertisement &&
                              renderCriteriaIcon(
                                application.prescreening_info
                                  .total_monthly_income >=
                                  screeningPack.minimum_income_multiple *
                                    advertisement.rental_unit_price
                              )}
                          </p>
                        </div>
                      </div>
                      {tenantFullApplication &&
                        tenantFullApplication.employment_income_info &&
                        tenantFullApplication.employment_income_info.length >
                          0 && (
                          <>
                            <h6 className="mt-4 mb-3">Employment History</h6>
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th>Employer</th>
                                  <th>Position</th>
                                  <th>Duration</th>
                                  <th>Annual Income</th>
                                  <th>Contact</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tenantFullApplication.employment_income_info.map(
                                  (job, index) => (
                                    <tr key={index}>
                                      <td>
                                        <strong>{job.employer_name}</strong>
                                        <br />
                                        <small>{job.employer_address}</small>
                                        {job.is_current_employer && (
                                          <span className="badge bg-primary ms-2">
                                            Current
                                          </span>
                                        )}
                                      </td>
                                      <td>{job.job_category || "N/A"}</td>
                                      <td>
                                        {new Date(
                                          job.employer_start_date
                                        ).toLocaleDateString()}{" "}
                                        -
                                        {job.is_current_employer
                                          ? "Present"
                                          : new Date(
                                              job.employer_end_date
                                            ).toLocaleDateString()}
                                      </td>
                                      <td>
                                        ${job.annual_income.toLocaleString()}
                                      </td>
                                      <td>
                                        {job.employer_phone_number}
                                        <br />
                                        {job.employer_email}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </>
                        )}
                    </Tab.Pane>
                    {/* References */}
                    <Tab.Pane eventKey="references">
                      <h5 className="mb-4">References</h5>
                      {tenantFullApplication.personal_references &&
                      tenantFullApplication.personal_references.length > 0 ? (
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Relationship</th>
                              <th>Contact Information</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tenantFullApplication.personal_references.map(
                              (reference, index) => (
                                <tr key={index}>
                                  <td>{reference.name}</td>
                                  <td>{reference.relationship}</td>
                                  <td>
                                    <strong>Phone:</strong>{" "}
                                    {reference.phone_number}
                                    <br />
                                    <strong>Email:</strong> {reference.email}
                                    {reference.address && (
                                      <>
                                        <br />
                                        <strong>Address:</strong>{" "}
                                        {reference.address}
                                        {reference.city &&
                                          `, ${reference.city}`}
                                        {reference.state &&
                                          `, ${reference.state}`}
                                        {reference.zip_code &&
                                          ` ${reference.zip_code}`}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <p>No personal references available.</p>
                      )}
                    </Tab.Pane>
                    {/* Documents */}
                    <Tab.Pane eventKey="documents">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Documents</h5>
                      </div>
                      {/* TODO: render the documents here put in its own component */}
                      {tenantFullApplication &&
                        tenantFullApplication.attachments &&
                        tenantFullApplication.attachments.length > 0 && (
                          <DocumentUploadSection
                            attachments={tenantFullApplication.attachments}
                            attachmentUploads={[]}
                            handleConfirmDocuments={() => {}}
                            handleDeleteAttachment={() => {}}
                          />
                        )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="screening">
                      <h5 className="mb-4">Screening Progress</h5>
                      <div className="screening-progress mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                          {screeningPhases.map((phase, index) => {
                            const rejectionPhase = getRejectionPhase();
                            let icon;
                            if (applicationStatus === "rejected") {
                              if (index < rejectionPhase) {
                                icon = (
                                  <CheckCircle
                                    className="text-success mb-2"
                                    size={32}
                                  />
                                );
                              } else if (index === rejectionPhase) {
                                icon = (
                                  <XCircle
                                    className="text-danger mb-2"
                                    size={32}
                                  />
                                );
                              } else {
                                icon = (
                                  <Circle
                                    className="text-secondary mb-2"
                                    size={32}
                                  />
                                );
                              }
                            } else {
                              if (index < getCurrentPhase()) {
                                icon = (
                                  <CheckCircle
                                    className="text-success mb-2"
                                    size={32}
                                  />
                                );
                              } else if (index === getCurrentPhase()) {
                                icon = (
                                  <Circle
                                    className="text-primary mb-2"
                                    size={32}
                                    fill="currentColor"
                                  />
                                );
                              } else {
                                icon = (
                                  <Circle
                                    className="text-secondary mb-2"
                                    size={32}
                                  />
                                );
                              }
                            }
                            return (
                              <div
                                key={phase.field}
                                className="d-flex flex-column align-items-center"
                                style={{ width: "20%" }}
                              >
                                {icon}
                                <span
                                  className={`text-center ${
                                    (applicationStatus === "rejected" &&
                                      index === rejectionPhase) ||
                                    (applicationStatus !== "rejected" &&
                                      index === getCurrentPhase())
                                      ? "fw-bold"
                                      : ""
                                  }`}
                                >
                                  {phase.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="comments">
                      <h5>Private Comments</h5>
                      <form onSubmit={handlePrivateCommentSubmit}>
                        <div className="form-group mb-3">
                          <textarea
                            className="form-control"
                            rows="3"
                            value={privateComment}
                            onChange={(e) => setPrivateComment(e.target.value)}
                            placeholder="Add a private comment..."
                          ></textarea>
                        </div>
                        <Button type="submit" variant="primary">
                          Add Comment
                        </Button>
                      </form>
                      {privateNotes && privateNotes.length > 0 && (
                        <PrivateNotesSection
                          privateNotes={privateNotes}
                          deletePrivateNote={deletePrivateNote}
                        />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4"></div>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to {modalAction.replace("_", " ")} this
            application?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleUpdateStatus(modalAction)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ProspectiveTenantProfileDetail;
