import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUniversalList } from "../../../../services/task/universalListManagement";
import PageTitleBox from "../../../reusable/title/pageTitleBox";
import LoaderView from "../../../reusable/loading/loaderView";
import { Card, ListGroup, Badge } from "react-bootstrap";
import { getPropertyUnitAdvertListingByCampaignId } from "../../../../services/propertyUnits/advertisment/advertismentManagement";
import { actionTypeMap } from "../../../../services/utils/actionTypes";

const UniversalListDetail = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const { universalListId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [universalList, setUniversalList] = useState({
    universal_list: {
      universal_list_id: "",
      name: "",
      description: "",
      created_by: null,
      created_at: "",
      updated_at: "",
      is_deleted: false,
      list_type: "",
      campaign_ids: [],
      user_id: "",
    },
    universal_list_items: [],
  });
  const [error, setError] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [firstPropertyId, setFirstPropertyId] = useState("");

  const listTypeMap = {
    general: "General",
    tenant_screening: "Tenant Screening",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    move_in: "Move-In",
    move_out: "Move-Out",
    maintenance: "Maintenance",
    seasonal: "Seasonal",
    emergency: "Emergency",
    legal_compliance: "Legal Compliance",
    financial: "Financial",
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUniversalList();
  }, [universalListId]);

  const fetchUniversalList = async () => {
    try {
      const result = await getUniversalList(universalListId);
      console.log("Fetched universal list:", result);
      setUniversalList(result);

      // Call fetchCampaigns after setting the universal list
      if (result.universal_list && result.universal_list.campaign_ids) {
        fetchCampaigns(result.universal_list.campaign_ids);
      }
    } catch (error) {
      console.error("Error fetching universal list:", error);
      setError("Failed to load universal list details.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCampaigns = async (campaignIds) => {
    try {
      const campaignPromises = campaignIds.map((id) =>
        getPropertyUnitAdvertListingByCampaignId(id)
      );
      const results = await Promise.all(campaignPromises);
      const flattenedResults = results.flat();
      console.log("Fetched campaigns:", flattenedResults);
      setCampaigns(flattenedResults);

      // Find the first valid property_id
      const firstPropertyId = flattenedResults.reduce((acc, campaign) => {
        if (acc) return acc; // If we've already found a valid ID, return it
        if (campaign.property_unit && campaign.property_unit.property_id) {
          return campaign.property_unit.property_id;
        }
        return null;
      }, null);

      if (firstPropertyId) {
        setFirstPropertyId(firstPropertyId);
        console.log("First property ID:", firstPropertyId);
      } else {
        console.warn("No valid property_id found in the campaigns");
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      setError("Failed to load campaigns.");
    }
  };

  if (isLoading) return <LoaderView />;
  if (error) return <div className="alert alert-danger">{error}</div>;
  if (!universalList.universal_list)
    return <div className="alert alert-info">No universal list found.</div>;

  const { universal_list, universal_list_items } = universalList;

  return (
    <div data-bs-theme={isDarkMode ? "dark" : "light"} className="page-content">
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={universal_list.name}
          previousPageTitle={firstPropertyId ? "Property Details" : null}
          previousPageLink={
            firstPropertyId ? `/universalList/${firstPropertyId}` : null
          }
        />
        <div className="row">
          <div className="col-md-4 mb-4">
            <Card>
              <Card.Body>
                <Card.Title>List Details</Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <strong>Description:</strong> {universal_list.description}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Type:</strong>{" "}
                    {listTypeMap[universal_list.list_type] ||
                      universal_list.list_type}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Created:</strong>{" "}
                    {new Date(universal_list.created_at).toLocaleString()}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Last Updated:</strong>{" "}
                    {new Date(universal_list.updated_at).toLocaleString()}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-8">
            <Card>
              <Card.Body>
                <Card.Title>List Items</Card.Title>
                {universal_list_items.length > 0 ? (
                  <ListGroup>
                    {universal_list_items.map((item, index) => (
                      <ListGroup.Item
                        key={item.universal_list_item_id}
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            {index + 1}. {item.name}
                          </div>
                          {item.description}
                        </div>
                        <Badge bg="primary" pill>
                          {actionTypeMap[item.action_type] || item.action_type}
                        </Badge>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                ) : (
                  <p className="text-muted">No items found in this list.</p>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversalListDetail;
