import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchAllPropertiesById } from "../../../services/property/retrieve/propertyRetrievalService";
import Dropdown from "react-bootstrap/Dropdown";
//import img_2 from "../../../styletheme/assets/img/photos/2.jpg";
import user_img from "../../../styletheme/images/user-icon/avatar.png";
import config from "../../../constants/config.json";
import loader from "../../../styletheme/images/loading/loader.svg";
import { Link } from "react-router-dom";
import HeaderLabelBreadCrumb from "../../reusable/label/headerLabelBreadCrumb";
import { getPropertiesOwnedByUserId } from "../../../services/portfolio/ownership/portfolioOwnershipManagement";
import defaultHouseImg from "../../../styletheme/images/buildings/house.png";
import LoaderView from "../../reusable/loading/loaderView";
import PageTitleBox from "../../reusable/title/pageTitleBox";
import ShimmerEffect from "../../reusable/loading/ShimmerEffect";

//TODO: next show all the poperties that the logged in user has a partnership owner structure with
const MyProperties = ({ isDarkMode }) => {
  const [headerTitleDetail] = useState({
    title: "My Properties",
    subHeadline: "showing all your properties private and publicly available",
  });
  const [isLoading, setIsLoading] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [properties, setProperties] = useState([
    {
      property: {
        city_town: "",
        country: "",
        country_id: "",
        portfolio_id: "",
        property_id: "",
        state_parish: "",
        street_address_1: "",
        zip_postal_code: "",
        currency_id: "",
        property_alias_name: "",
        property_type_id: "",
        street_address_2: "",
        sub_property_type_id: "",
      },
      portfolio: {
        ownership_id: "",
        portfolio_id: "",
        portfolio_name: "",
      },
      ownership_structures: [
        {
          ownership_structure_id: "",
          user_id: "",
          first_name: "",
          last_name: "",
          owner_email: "",
          owner_phone: "",
          portfolio_id: "",
          ownership_percentage: "",
          ownership_type: null,
          is_controlling_partner: false,
          partnership_type: "",
          ownership_start_date: "",
          ownership_end_date: null,
          created_at: null,
        },
      ],
    },
  ]);

  const [filteredProperties, setFilteredProperties] = useState([
    {
      property: {
        city_town: "",
        country: "",
        country_id: "",
        portfolio_id: "",
        property_id: "",
        state_parish: "",
        street_address_1: "",
        zip_postal_code: "",
        currency_id: "",
        property_alias_name: "",
        property_type_id: "",
        street_address_2: "",
        sub_property_type_id: "",
      },
      portfolio: {
        ownership_id: "",
        portfolio_id: "",
        portfolio_name: "",
      },
      ownership_structures: [
        {
          ownership_structure_id: "",
          user_id: "",
          first_name: "",
          last_name: "",
          owner_email: "",
          owner_phone: "",
          portfolio_id: "",
          ownership_percentage: "",
          ownership_type: null,
          is_controlling_partner: false,
          partnership_type: "",
          ownership_start_date: "",
          ownership_end_date: null,
          created_at: null,
        },
      ],
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);

    getAllMyProperties();
  }, []);

  //TODO: Update this to get it the new way
  const getAllMyProperties = async () => {
    try {
      setIsLoading(true);

      const properties = await getPropertiesOwnedByUserId();

      console.log(properties);
      setProperties(properties);

      setFilteredProperties(properties);

      setIsLoading(false);
    } catch (error) {
      toast.error("Error loading properties");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}

      {isLoading && <LoaderView />}

      <div
        className={`page-content tw-py-8`}
        data-bs-theme={isDarkMode ? "dark" : "light"}
      >
        {/* start page title */}
        <PageTitleBox
          pageTitle={headerTitleDetail.title}
          previousPageTitle=""
        />
        {/* end page title */}

        <div className="container-fluid">
          <div className="row g-4 mb-3">
            <div className="col-sm-auto">
              <div>
                <Link
                  to={"/newproperty"}
                  className="btn btn-success"
                  data-bs-placement="top"
                  data-bs-toggle="tooltip"
                  title=""
                >
                  <i className="ri-add-line align-bottom me-1"></i>
                  Add New Property
                </Link>
              </div>
            </div>
            <div
              className="col-sm-auto"
              style={{ justifyContent: "flex-end!important" }}
              hidden
            >
              <select
                className="form-control w-md"
                data-choices
                data-choices-search-false
                defaultValue="Yesterday"
              >
                <option value="All">All</option>
                <option value="Today">Today</option>
                <option value="Yesterday">Yesterday</option>
                <option value="Last 7 Days">Last 7 Days</option>
                <option value="Last 30 Days">Last 30 Days</option>
                <option value="This Month">This Month</option>
                <option value="Last Year">Last Year</option>
              </select>
            </div>
            <div hidden className="col-sm">
              <div className="d-flex justify-content-sm-end gap-2">
                <div className="search-box ms-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </div>
            </div>
          </div>

          {/* row */}
          <div className="row " style={{ paddingTop: "1%" }}>
            {/*  Loop start*/}

            {isLoading ? (
              // Show shimmer effect while loading
              <>
                {[...Array(8)].map((_, index) => (
                  <div key={index} className="col-xxl-3 col-sm-6 project-card">
                    <div className="card card-height-100">
                      <div className="card-body">
                        <ShimmerEffect
                          type="card"
                          height="200px"
                          darkMode={isDarkMode}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : filteredProperties &&
              filteredProperties.length > 0 &&
              filteredProperties[0].property.property_id !== "" ? (
              <>
                {filteredProperties
                  .filter((property) =>
                    property?.property.property_alias_name
                      ?.toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  )
                  .map((port_property, index) => (
                    <div
                      key={index}
                      className="col-xxl-3 col-sm-6 project-card"
                    >
                      <div className="card card-height-100">
                        <div className="card-body">
                          <div className="d-flex flex-column h-100">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted mb-4">
                                  {port_property.portfolio.portfolio_name}
                                </p>
                              </div>
                              <div className="d-flex gap-1 align-items-center">
                                <Dropdown className="dropdown">
                                  <Dropdown.Toggle
                                    id="dropdown-autoclose-true"
                                    className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-6"
                                    style={{
                                      backgroundColor: "transparent ",
                                    }}
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                  >
                                    Action
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href={`/property/${port_property.property.property_id}`}
                                      className="dropdown-item"
                                    >
                                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                      View
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item
                                      href={`/updateProperty/${port_property.property.property_id}`}
                                      className="dropdown-item"
                                    >
                                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                      Edit
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      href="#"
                                      className="dropdown-item"
                                      hidden
                                    >
                                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                      Remove
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="d-flex mb-2">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar-sm">
                                  <span className="avatar-title bg-info-subtle rounded">
                                    <img
                                      src={defaultHouseImg}
                                      style={{ width: "100%", height: "100%" }}
                                      alt=""
                                      className="img-fluid p-1"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="mb-1 fs-15">
                                  <Link
                                    to={`/property/${port_property.property.property_id}`}
                                    className="text-body"
                                  >
                                    {port_property.property.property_alias_name}
                                  </Link>
                                </h5>
                                <p className="text-muted text-truncate-two-lines mb-3 ">
                                  {port_property.property.street_address_1}
                                  {","}
                                  {port_property.property.street_address_2}
                                  {port_property.property.street_address_2
                                    ? ","
                                    : ""}
                                  {port_property.property.city_town}
                                  {","}
                                  {port_property.property.state_parish}
                                  {","}
                                  {port_property.property.zip_postal_code}
                                  {","}
                                  {port_property.property.country}
                                </p>
                              </div>
                            </div>
                            <div className="mt-auto" hidden>
                              <div className="d-flex mb-2">
                                <div className="flex-grow-1">
                                  <div>Tasks</div>
                                </div>
                                <div className="flex-shrink-0">
                                  <div>
                                    <i className="ri-list-check align-bottom me-1 text-muted"></i>{" "}
                                    18/42
                                  </div>
                                </div>
                              </div>
                              <div className="progress progress-sm animated-progress">
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  aria-valuenow="34"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "34%" }}
                                ></div>
                                {/* /.progress-bar */}
                              </div>
                              {/* /.progress */}
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                        {/*TODO: ownership_structures to populate the list and update ethe scehena */}
                        <div
                          hidden
                          className="card-footer bg-transparent border-top-dashed py-2"
                        >
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <div className="avatar-group">
                                <a
                                  href="#"
                                  onClick={(event) => event.preventDefault()}
                                  className="avatar-group-item material-shadow"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  data-bs-placement="top"
                                  title="Darline Williams"
                                >
                                  <div className="avatar-xxs">
                                    <img
                                      src={user_img}
                                      alt=""
                                      className="rounded-circle img-fluid"
                                    />
                                  </div>
                                </a>
                                <a
                                  href="#"
                                  onClick={(event) => event.preventDefault()}
                                  className="avatar-group-item material-shadow"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  data-bs-placement="top"
                                  title="Add Members"
                                >
                                  <div className="avatar-xxs">
                                    <div className="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                      +
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="flex-shrink-0" hidden>
                              <div className="text-muted">
                                <i className="ri-calendar-event-fill me-1 align-bottom"></i>{" "}
                                10 Jul, 2021
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end card footer */}
                      </div>
                      {/* end card */}
                    </div>
                  ))}
              </>
            ) : (
              <>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-center">
                        <img src={loader} alt="Loader" className="avatar-md" />
                        <h4 className="mt-3">No Properties Found</h4>
                        <p className="text-muted">
                          You have not added any properties yet
                        </p>
                        <Link
                          to="/newproperty"
                          className="btn btn-success mt-2"
                        >
                          Add Property
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/*  Loop end*/}
          </div>
          {/*  Loop end*/}
        </div>
        {/* /row */}
      </div>
    </React.Fragment>
  );
};

export default MyProperties;
