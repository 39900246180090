import { ChatBubbleIcon, TrashIcon } from "@radix-ui/react-icons";

const PrivateNotesSection = ({ privateNotes = [], deletePrivateNote }) => {
  const handleDelete = (_noteId, _tenantRentalApplicationId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this note? This action cannot be undone."
      )
    ) {
      deletePrivateNote(_tenantRentalApplicationId, _noteId);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-bg-white dark:tw-bg-gray-800 tw-rounded-lg tw-shadow-sm">
      {/* Header */}
      <div className="tw-px-4 tw-py-3 tw-border-b tw-border-gray-200 dark:tw-border-gray-700">
        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
          Private Notes
        </h3>
      </div>

      {/* Notes Content */}
      <div className="tw-flex-1 tw-overflow-y-auto tw-max-h-[350px]">
        {privateNotes.length === 0 ? (
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-py-6">
            <ChatBubbleIcon className="tw-mb-2 tw-w-5 tw-h-5 tw-text-gray-300 dark:tw-text-gray-600" />
            <p className="tw-text-sm tw-text-gray-400 dark:tw-text-gray-500">
              No notes yet
            </p>
          </div>
        ) : (
          <div className="tw-p-4 tw-space-y-4">
            {privateNotes.map((note, index) => (
              <div
                key={index}
                className="tw-border tw-border-gray-200 dark:tw-border-gray-700 tw-rounded-lg tw-p-3 tw-space-y-2"
              >
                <div className="tw-flex tw-items-start tw-justify-between">
                  <div className="tw-flex tw-items-center tw-space-x-2">
                    <div className="tw-w-8 tw-h-8 tw-rounded-full tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-bg-gray-200 dark:tw-bg-gray-700">
                      {note?.landlord_profile?.profile_img ? (
                        <img
                          src={note.landlord_profile.profile_img}
                          alt={`${note.landlord_profile.first_name} ${note.landlord_profile.last_name}`}
                          className="tw-w-full tw-h-full tw-object-cover"
                          onError={(e) => {
                            e.target.src = "https://via.placeholder.com/32"; // Fallback image
                          }}
                        />
                      ) : (
                        <span className="tw-text-sm tw-text-gray-500 dark:tw-text-gray-400">
                          {note?.landlord_profile?.first_name?.[0]?.toUpperCase() ||
                            "?"}
                        </span>
                      )}
                    </div>
                    <span className="tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-gray-100">
                      {note?.landlord_profile?.first_name}{" "}
                      {note?.landlord_profile?.last_name}
                    </span>
                    <span className="tw-text-xs tw-text-gray-500">
                      {new Date(note.timestamp).toLocaleString()}
                    </span>
                  </div>
                  <button
                    onClick={() =>
                      handleDelete(
                        note.note_id,
                        note.tenant_rental_application_id
                      )
                    }
                    className="tw-p-1 tw-text-gray-400 hover:tw-text-red-500 dark:tw-text-gray-500 dark:hover:tw-text-red-400 tw-rounded-full hover:tw-bg-gray-100 dark:hover:tw-bg-gray-700"
                    title="Delete note"
                  >
                    <TrashIcon className="tw-w-4 tw-h-4" />
                  </button>
                </div>
                <p className="tw-text-sm tw-text-gray-600 dark:tw-text-gray-300 tw-whitespace-pre-wrap">
                  {note.notes}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivateNotesSection;
