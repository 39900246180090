export function formatDate(dateString) {
  let dateObject = new Date(dateString);
  let formattedDate = dateObject.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return formattedDate;
}

export function formatDateTime(dateString) {
  let dateObject = new Date(dateString);
  let formattedDateTime = dateObject.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return formattedDateTime;
}

//datetime with timezone
export function formatDateTimeWithTimezone(dateString, timezone) {
  let dateObject = new Date(dateString);
  return dateObject.toLocaleString("en-US", {
    timeZoneName: "short",
    timeZone: timezone,
  });
}
