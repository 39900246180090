import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import PageTitleBox from "../../../../reusable/title/pageTitleBox";
import Input from "../../../../reusable/form/Input";
import Select from "react-select";
import config from "../../../../../constants/config.json";

import Form from "react-bootstrap/Form";
import { createScreeningPack } from "../../../../../services/propertyUnits/tenantScreening/screeningPack/screeningPackManagement";

//todo continue from here and test it
//need to convert to checkbox so its a easy toggle
const CreateNewScreeningPack = ({ isDarkMode }) => {
  const pageTitle = "Create New Screening Pack";
  const navigate = useNavigate();
  const user_id = localStorage.getItem(config.user_id);

  const [screeningPackData, setScreeningPackData] = useState({
    screening_pack_id: "",
    user_creator_id: "",
    ownership_group_id: "",
    pack_name: "",
    pack_description: "",
    minimum_income_multiple: 1.0,
    minimum_credit_score: 630,
    maximum_number_of_occupants: 1,
    allow_declared_bankruptcy: false, //can set defaults in settings
    allow_declared_eviction: false,
    must_provide_landlord_reference: false,
    allow_has_been_convicted_of_crime: false,
    is_smoking_allowed: false,
    is_pet_allowed: false,
    is_hard_reject: false,
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setScreeningPackData({ ...screeningPackData, [name]: value });
  };

  const screeningPackSchema = {
    screening_pack_id: Joi.string().allow(""),
    user_creator_id: Joi.string().allow(""),
    ownership_group_id: Joi.string().allow(""),
    pack_name: Joi.string().required().label("Name"),
    pack_description: Joi.string().required().label("Description"),
    minimum_income_multiple: Joi.number().required().label("Income Multiple"),
    minimum_credit_score: Joi.number().required().label("Minimum Credit Score"),
    maximum_number_of_occupants: Joi.number()
      .required()
      .label("Maximum Number of Occupants"),
    allow_declared_bankruptcy: Joi.boolean()
      .required()
      .label("Allow Declared Bankruptcy"),
    allow_declared_eviction: Joi.boolean()
      .required()
      .label("Allow Declared Eviction"),
    must_provide_landlord_reference: Joi.boolean()
      .required()
      .label("Must Provide Landlord Reference"),
    allow_has_been_convicted_of_crime: Joi.boolean()
      .required()
      .label("Allow Convicted of Crime"),
    is_smoking_allowed: Joi.boolean().required().label("Smoking Allowed"),
    is_pet_allowed: Joi.boolean().required().label("Pet Allowed"),
    is_hard_reject: Joi.boolean().required().label("Hard Reject"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(
      screeningPackData,
      screeningPackSchema,
      options
    );

    if (!error) return null;

    const newErrors = {};
    for (let item of error.details) newErrors[item.path[0]] = item.message;

    return newErrors;
  };

  //TODO: next
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    //Call the backend to create the screening pack
    //If successful, redirect to the screening pack list
    if (!screeningPackData) {
      toast.error("Screening Pack is empty, please fill out the form");
      return;
    }

    console.log("Screening Pack Data to submit");
    console.log(screeningPackData);

    //toast.success('Screening Pack Created');
    const response = createScreeningPack(screeningPackData);
    console.log(response);

    if (response) {
      toast.success("Screening Pack Created");
      //redirect to the screening pack list
      navigate("/screeningPacks");
    } else {
      toast.error("Error creating Screening Pack");
      return;
    }
  };

  useEffect(() => {
    initiallySetScreeningPackData();
  }, []);

  const initiallySetScreeningPackData = async () => {
    setScreeningPackData({
      ...screeningPackData,
      screening_pack_id: uuidv4(),
      user_creator_id: user_id,
      ownership_group_id: "", //TODO: not set initially till shared (should be about to duplicate the pack)
    });
  };

  return (
    <div className="page-content">
      <PageTitleBox pageTitle={pageTitle} previousPageTitle="" />

      <div className="card">
        <div className="card-body">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <Input
                  name="pack_name"
                  label="Name"
                  value={screeningPackData.pack_name}
                  onChange={handleInputChange}
                  error={errors.pack_name}
                />
              </div>
              <div className="col-6">
                <Input
                  name="pack_description"
                  label="Description"
                  value={screeningPackData.pack_description}
                  onChange={handleInputChange}
                  error={errors.pack_description}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  type={"number"}
                  name="minimum_income_multiple"
                  label="Income Multiple"
                  value={screeningPackData.minimum_income_multiple}
                  onChange={handleInputChange}
                  error={errors.minimum_income_multiple}
                />
              </div>
              <div className="col-6">
                <Input
                  type={"number"}
                  name="minimum_credit_score"
                  label="Minimum Credit Score"
                  value={screeningPackData.minimum_credit_score}
                  onChange={handleInputChange}
                  error={errors.minimum_credit_score}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  type={"number"}
                  name="maximum_number_of_occupants"
                  label="Maximum Number of Occupants"
                  value={screeningPackData.maximum_number_of_occupants}
                  onChange={handleInputChange}
                  error={errors.maximum_number_of_occupants}
                />
              </div>
              <div className="col-2">
                <label>
                  {screeningPackData.allow_declared_bankruptcy
                    ? "Allow Declared Bankruptcy"
                    : "Dont Allow Bankruptcy"}
                </label>
                <div className="default-checkbox">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="allow_declared_bankruptcy"
                    label=""
                    checked={screeningPackData.allow_declared_bankruptcy}
                    onChange={(e) => {
                      setScreeningPackData({
                        ...screeningPackData,
                        allow_declared_bankruptcy: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-2">
                <label>
                  {screeningPackData.allow_declared_eviction
                    ? "Allow Declared Eviction"
                    : "Dont Allow Eviction"}
                </label>
                <div className="default-checkbox">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="allow_declared_eviction"
                    label=""
                    checked={screeningPackData.allow_declared_eviction}
                    onChange={(e) => {
                      setScreeningPackData({
                        ...screeningPackData,
                        allow_declared_eviction: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-2">
                <label>
                  {screeningPackData.must_provide_landlord_reference
                    ? "Must Provide Landlord Reference"
                    : "Dont Provide Landlord Reference"}
                </label>
                <div className="default-checkbox">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="must_provide_landlord_reference"
                    label=""
                    checked={screeningPackData.must_provide_landlord_reference}
                    onChange={(e) => {
                      setScreeningPackData({
                        ...screeningPackData,
                        must_provide_landlord_reference: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-2">
                <label>
                  {screeningPackData.allow_has_been_convicted_of_crime
                    ? "Allow Convicted of Crime"
                    : "Dont Allow Convicted of Crime"}
                </label>
                <div className="default-checkbox">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="allow_has_been_convicted_of_crime"
                    label=""
                    checked={
                      screeningPackData.allow_has_been_convicted_of_crime
                    }
                    onChange={(e) => {
                      setScreeningPackData({
                        ...screeningPackData,
                        allow_has_been_convicted_of_crime: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-2">
                <label>
                  {screeningPackData.is_smoking_allowed
                    ? "Smoking Allowed"
                    : "Smoking Not Allowed"}
                </label>
                <div className="default-checkbox">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="is_smoking_allowed"
                    label=""
                    checked={screeningPackData.is_smoking_allowed}
                    onChange={(e) => {
                      setScreeningPackData({
                        ...screeningPackData,
                        is_smoking_allowed: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-2">
                <label>
                  {screeningPackData.is_pet_allowed
                    ? "Pet Allowed"
                    : "Pet Not Allowed"}
                </label>
                <div className="default-checkbox">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="is_pet_allowed"
                    label=""
                    checked={screeningPackData.is_pet_allowed}
                    onChange={(e) => {
                      setScreeningPackData({
                        ...screeningPackData,
                        is_pet_allowed: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-2">
                <label>
                  {screeningPackData.is_hard_reject
                    ? "Hard Reject"
                    : "Allow to Apply"}
                </label>
                <div className="default-checkbox">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="is_hard_reject"
                    label=""
                    checked={screeningPackData.is_hard_reject}
                    onChange={(e) => {
                      setScreeningPackData({
                        ...screeningPackData,
                        is_hard_reject: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row gy-4 mt-3">
              <div className="col-12 d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => navigate("/screeningPacks")}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  disabled={validate()}
                  className="btn btn-success"
                >
                  Create
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateNewScreeningPack;
