import http from "../../general/httpService";
import config from "../../../constants/config.json";

///portfolio/update_property
export async function updateProperty(property) {
    const user_id = localStorage.getItem(config.user_id);
    const fullApiEndpoint = config.apiEndpoint + `/portfolio/update_property`;
    const {data: result} = await http.post(fullApiEndpoint, {property_data: property,user_id:user_id });

    return result;
}