import React, { useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'quill/dist/quill.snow.css';
import '../ckeditor/editorStyles.css';

// Create a new blot that inherits from Quill's existing Link blot
let Link = Quill.import('formats/link');
class ExternalLink extends Link {
  static create(value) {
    let node = super.create(value);
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
    return node;
  }
}
// Name our blot and inherit from Link blot
ExternalLink.blotName = 'external_link';
ExternalLink.tagName = 'A';
// Register our new blot
Quill.register(ExternalLink);

function ContractEditorComponent({
  content,
  handleChange,
  height = '200px',
  handleSave,
  handleClear,
}) {
  const quillRef = useRef(null);

  //find a way to make this reusable for any text value i want
  const insertTextAtCursor = (text) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    if (range && range.length > 0) {
      // Replace the selected text with the provided text
      editor.deleteText(range.index, range.length);
      editor.insertText(range.index, text);
    } else {
      // If no text is selected, just insert the provided text at the cursor position
      editor.insertText(range.index, text);
    }
  };
  const modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image', 'video'],
        ['clean'],
        [{ save: 'Save' }, { clear: 'Clear' }], // Custom buttons
      ],
      handlers: {
        save: handleSave,
        clear: handleClear,
      },
    },
  };

  return (
    <div>
      <div className='ql-toolbar'>
        {/* *** Custom buttons need to be reuable *** */}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@Tenant');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @Tenant
        </button>

        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@Landlord');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @Landlord
        </button>

        {/* Address*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@Address');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @Address
        </button>

        {/* Start Date*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@StartDate');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @StartDate
        </button>

        {/* End Date*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@EndDate');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @EndDate
        </button>

        {/* Lease Term*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@LeaseTerm');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @LeaseTerm
        </button>

        {/* Rent Amount*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@RentAmount');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @RentAmt
        </button>

        {/* Deposit Amount*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@DepositAmount');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @DepositAmt
        </button>

        {/* Security Deposit*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@SecurityDeposit');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @SecurityDep
        </button>

        {/* Return Days*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@ReturnDays');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @ReturnDays
        </button>

        {/* Cure Days*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@CureDays');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @CureDays
        </button>

        {/* Late Fee*/}
        <button
          onClick={(event) => {
            event.preventDefault();
            insertTextAtCursor('@LateFee');
          }}
          style={{ width: '100px' }}
          className='btn btn-light btn-sm'
        >
          @LateFee
        </button>
      </div>
      <ReactQuill
        ref={quillRef}
        className='snow-editor'
        placeholder={'Write something'}
        value={content}
        onChange={handleChange}
        style={{ height: height }}
        modules={modules}
        formats={[
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'video',
          'external_link', // Add our new format here
        ]}
      />
    </div>
  );
}

export default ContractEditorComponent;
