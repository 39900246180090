import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import NoRecordsFound from "../sub/noRecordsFound";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSettings } from "../../../../../services/authentication/contexts/settings/settingsContext";

const TicketsList = ({
  isKanbanView,
  toggleView,
  propertyUnitId,
  unitTickets,
  ticketColumns,
}) => {
  const navigate = useNavigate();
  const { settings } = useSettings();

  console.log("TicketsList isDarkMode:", settings.isDarkMode);

  const theme = createTheme({
    palette: {
      mode: settings.isDarkMode ? "dark" : "light",
    },
  });

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card" id="ticketsList">
          <div className="card-header border-0">
            <div className="d-flex align-items-center">
              <h5 className="card-title mb-0 flex-grow-1">Tickets</h5>
              <div className="d-flex flex-wrap gap-2">
                <button
                  onClick={toggleView}
                  className="btn btn-outline-primary me-2" // Added margin to the right
                >
                  {isKanbanView ? (
                    <span>
                      <i
                        className="ri-list-check"
                        title="Switch to List View"
                      ></i>{" "}
                      List View
                    </span>
                  ) : (
                    <span>
                      <i
                        className="ri-layout-grid-fill"
                        title="Switch to Kanban View"
                      ></i>{" "}
                      Kanban View
                    </span>
                  )}
                </button>
                <Link
                  to={
                    propertyUnitId
                      ? `/newUnitTicket/${propertyUnitId}`
                      : "/newUnitTicket"
                  }
                  className="btn btn-info add-btn"
                >
                  <i className="ri-add-line align-bottom me-1"></i> Create
                  Ticket
                </Link>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive table-card mb-4 px-3">
              <ThemeProvider theme={theme}>
                <Box sx={{ height: 650, width: "100%" }}>
                  <DataGrid
                    rows={unitTickets || []}
                    getRowId={(row) => row.ticket_id || 0}
                    columns={ticketColumns}
                    checkboxSelection={false}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    processRowUpdate={(updatedRow, originalRow) => {
                      console.log(updatedRow, originalRow);
                    }}
                    onProcessRowUpdateError={(error, updatedRow, originalRow) =>
                      console.log(error, updatedRow, originalRow)
                    }
                    onRowClick={(params) => {
                      // Navigate to the ticket detail page
                      // Assuming the route is '/ticket-detail/:ticketId'
                      navigate(`/ticket/${params.row.ticket_id}`);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                      noResultsOverlay: NoRecordsFound,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </Box>
              </ThemeProvider>
              <div className="noresult" style={{ display: "none" }}>
                <div className="text-center">
                  <lord-icon
                    src="https://cdn.lordicon.com/msoeawqm.json"
                    trigger="loop"
                    colors="primary:#121331,secondary:#08a88a"
                    style={{ width: "75px", height: "75px" }}
                  ></lord-icon>
                  <h5 className="mt-2">Sorry! No Result Found</h5>
                  <p className="text-muted mb-0">
                    We've searched more than 150+ Tickets We did not find any
                    Tickets for you search.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <div className="pagination-wrap hstack gap-2">
                <a className="page-item pagination-prev disabled" href="#">
                  Previous
                </a>
                <ul className="pagination listjs-pagination mb-0"></ul>
                <a className="page-item pagination-next" href="#">
                  Next
                </a>
              </div>
            </div>

            {/* Modal */}
            <div
              className="modal fade flip"
              id="deleteOrder"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body p-5 text-center">
                    <lord-icon
                      src="https://cdn.lordicon.com/gsqxdxog.json"
                      trigger="loop"
                      colors="primary:#405189,secondary:#f06548"
                      style={{ width: "90px", height: "90px" }}
                    ></lord-icon>
                    <div className="mt-4 text-center">
                      <h4>You are about to delete a order ?</h4>
                      <p className="text-muted fs-14 mb-4">
                        Deleting your order will remove all of your information
                        from our database.
                      </p>
                      <div className="hstack gap-2 justify-content-center remove">
                        <button
                          className="btn btn-link link-success fw-medium text-decoration-none"
                          id="deleteRecord-close"
                          data-bs-dismiss="modal"
                        >
                          <i className="ri-close-line me-1 align-middle"></i>{" "}
                          Close
                        </button>
                        <button className="btn btn-danger" id="delete-record">
                          Yes, Delete It
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end modal */}
          </div>
          {/*end card-body*/}
        </div>
        {/*end card*/}
      </div>
      {/*end col*/}
    </div>
  );
};

export default TicketsList;
