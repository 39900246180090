import http from "../general/httpService";
import config from "../../constants/config.json";
import api from "../../constants/config.json";

//authentication/fetch_user_by_user_id
export async function fetchOwnerProfileRecord(owner_manager_user_id) {
  const fullApiEndpoint =
    api.apiEndpoint + `/authentication/fetch_user_by_user_id`;
  const { data } = await http.post(fullApiEndpoint, {
    owner_manager_user_id: owner_manager_user_id,
  });
  return data;
}

//user_profile/update_profile_photo
export async function updateProfilePhoto(profile_img_upload) {
  const user_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    api.apiEndpoint + `/user_profile/update_profile_photo`;
  const { data } = await http.post(fullApiEndpoint, {
    user_id: user_id,
    profile_img_upload: profile_img_upload,
  });
  return data;
}
