import React, { useCallback, useState } from "react";
import { Upload, X } from "lucide-react";
import { toast } from "react-toastify";

const SingleImageUploader = ({
  selectedFile,
  setSelectedFile,
  base64Data,
  setBase64Data,
  handleUpload,
  uploadBtnText = "Upload Image",
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (!file || !file.type.startsWith("image/")) return;

      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(file);
    },
    [setSelectedFile, setBase64Data]
  );

  const removeFile = useCallback(
    (e) => {
      e.preventDefault();
      setSelectedFile(null);
      setBase64Data(null);
    },
    [setSelectedFile, setBase64Data]
  );

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const file = e.dataTransfer.files[0];
      if (!file || !file.type.startsWith("image/")) return;

      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(file);
    },
    [setSelectedFile, setBase64Data]
  );

  const fileInputId = `file-input-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div className="tw-w-full">
      <div className="tw-mb-3">
        <input
          type="file"
          onChange={handleFileChange}
          className="tw-hidden"
          id={fileInputId}
          accept="image/*"
        />
        <label
          htmlFor={fileInputId}
          className={`tw-w-full tw-text-center tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4 tw-border-2 tw-border-dashed ${
            isDragging
              ? "tw-border-primary tw-bg-primary/10"
              : "tw-border-gray-300"
          }`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Upload className="tw-mb-2" size={32} />
          <p className="tw-mb-0">
            {isDragging
              ? "Drop image here"
              : "Click to select or drag and drop an image"}
          </p>
        </label>
      </div>

      {selectedFile && base64Data && (
        <div className="tw-space-y-4">
          <div className="tw-relative tw-w-32 tw-h-32 tw-mx-auto">
            <img
              src={base64Data}
              alt="Preview"
              className="tw-w-full tw-h-full tw-object-cover tw-rounded"
            />
            <button
              onClick={removeFile}
              className="tw-absolute tw-top-0 tw-right-0 tw-p-1 tw-bg-red-500 tw-rounded-full tw-text-white hover:tw-bg-red-600"
            >
              <X size={16} />
            </button>
          </div>

          <p className="tw-text-sm tw-text-gray-600 tw-truncate tw-w-full tw-text-center">
            {selectedFile.name}
          </p>

          {/* Single Upload Button */}
          <button
            onClick={() =>
              handleUpload({
                file_name: selectedFile.name,
                base64_data: base64Data,
              })
            }
            className="tw-w-full tw-bg-green-500 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-flex tw-items-center tw-justify-center tw-gap-2 hover:tw-bg-green-600"
          >
            <Upload size={16} />
            {uploadBtnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleImageUploader;
