import http from "../../services/general/httpService";
import config from "../../constants/config.json";

///user_rental_contract_template/create_or_update_user_rental_contract_template
export async function createOrUpdateUserRentalContractTemplate(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/create_or_update_user_rental_contract_template`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
}

//update_user_rental_contract_template
export async function updateUserRentalContractTemplate(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/update_user_rental_contract_template`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
}

//use this to show all rental contract templates for the current user
//get_user_rental_contract_template_by_user_id
export async function getUserRentalContractTemplateByUserId(user_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/get_user_rental_contract_template_by_user_id`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     return result;
}

//get_user_rental_contract_template_by_portfolio_id
export async function getUserRentalContractTemplateByPortfolioId(portfolio_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/get_user_rental_contract_template_by_portfolio_id`;
     const {data: result} = await http.post(fullApiEndpoint, {portfolio_id: portfolio_id});
    
     return result;
}

//get_user_rental_contract_template_by_property_id
export async function getUserRentalContractTemplateByPropertyId(property_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/get_user_rental_contract_template_by_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
    
     return result;
}

//get_user_rental_contract_template_by_user_id_and_portfolio_id
export async function getUserRentalContractTemplateByUserIdAndPortfolioId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/get_user_rental_contract_template_by_user_id_and_portfolio_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_user_rental_contract_template_by_id
export async function getUserRentalContractTemplateById(contract_template_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/get_user_rental_contract_template_by_id`;
     const {data: result} = await http.post(fullApiEndpoint, {contract_template_id: contract_template_id});
    
     return result;
}

//get_user_rental_contract_templates_by_user_id
export async function getUserRentalContractTemplatesByUserId(user_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/user_rental_contract_template/get_user_rental_contract_templates_by_user_id`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     return result;
}