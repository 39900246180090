export const selectCustomStyle = (isDarkMode) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: isDarkMode ? "#111827" : "#F9FAFB", // tw-bg-gray-900 : tw-bg-gray-50
    color: isDarkMode ? "#F9FAFB" : "#111827", // text color
    border: `1px solid ${isDarkMode ? "#374151" : "#E5E7EB"}`, // darker/lighter border
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: isDarkMode
      ? state.isSelected
        ? "#374151" // darker selected bg for dark mode
        : "#111827" // dark mode bg
      : state.isSelected
      ? "#E5E7EB" // lighter selected bg for light mode
      : "#F9FAFB", // light mode bg
    color: isDarkMode ? "#F9FAFB" : "#111827",
    ":hover": {
      backgroundColor: isDarkMode ? "#1F2937" : "#E5E7EB", // hover states
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: isDarkMode ? "#111827" : "#F9FAFB",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: isDarkMode ? "#F9FAFB" : "#111827",
  }),
});
