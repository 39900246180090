import React, { createContext, useState, useContext, useEffect } from "react";
import config from "../../../../constants/config.json";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const initialSettings = {
    ...config.settings,
    isDarkMode: localStorage.getItem(config.is_dark_mode) === "true" || false,
  };

  const [settings, setSettings] = useState(initialSettings);

  useEffect(() => {
    localStorage.setItem(config.is_dark_mode, settings.isDarkMode);
  }, [settings.isDarkMode]);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export function useSettings() {
  return useContext(SettingsContext);
}
