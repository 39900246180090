import http from "../../../general/httpService";
import config from "../../../../constants/config.json";

///expense_category/get_expense_categories
export async function getExpenseCategories() {
  
    const fullApiEndpoint = config.apiEndpoint + `/expense_category/get_expense_categories`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}

//get_expense_category
export async function getExpenseCategory(expense_category_id) {
  
    const fullApiEndpoint = config.apiEndpoint + `/expense_category/get_expense_category/${expense_category_id}`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}