// src/utils/currencyUtils.js

export const formatCurrency = (
  amount,
  currency = "USD",
  language = "en-US"
) => {
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
  }).format(amount);
};

export const formatStripeAmount = (amount, currency = "usd") => {
  const dollars = amount / 100;
  return formatCurrency(dollars, currency);
};
