//https://help.calendly.com/hc/en-us/articles/1500004754122-Managing-additional-rules-for-your-availability#managing-additional-rules-for-your-availability-0-0
import React, { useState, useCallback, useMemo } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import {
  format,
  parse,
  startOfWeek,
  getDay,
  addMinutes,
  setHours,
  setMinutes,
  isSameDay,
  isAfter,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PageTitleBox from '../../../../../reusable/title/pageTitleBox';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createUnitViewing } from '../../../../../../services/propertyUnits/advertisment/unitViewing/unitViewingManagement';
import { v4 as uuidv4 } from 'uuid';

const locales = { 'en-US': enUS };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CustomEvent = ({ event }) => (
  <div style={{ fontSize: '0.8em', lineHeight: '1.2' }}>
    {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
    {event.tenant_name && <div>Tenant: {event.tenant_name}</div>}
    {event.status !== 'available' && <div>Status: {event.status}</div>}
  </div>
);

const CalendarManagement = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [events, setEvents] = useState([]);
  const [bulkStartDate, setBulkStartDate] = useState('');
  const [bulkEndDate, setBulkEndDate] = useState('');
  const [bulkStartTime, setBulkStartTime] = useState('09:00');
  const [bulkEndTime, setBulkEndTime] = useState('17:00');

  const [view, setView] = useState(Views.WEEK);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);

  const [slotDuration, setSlotDuration] = useState(15);

  const { calendarStep, calendarTimeslots } = useMemo(() => {
    switch (slotDuration) {
      case 15:
        return { calendarStep: 15, calendarTimeslots: 1 };
      case 30:
        return { calendarStep: 30, calendarTimeslots: 1 };
      case 45:
        return { calendarStep: 45, calendarTimeslots: 1 };
      case 60:
        return { calendarStep: 60, calendarTimeslots: 1 };
      default:
        return { calendarStep: 60, calendarTimeslots: 1 };
    }
  }, [slotDuration]);

  const handleBulkCreate = () => {
    if (!bulkStartDate || !bulkEndDate || !bulkStartTime || !bulkEndTime) {
      alert('Please fill in all date and time fields');
      return;
    }

    const start = new Date(`${bulkStartDate}T${bulkStartTime}`);
    const end = new Date(`${bulkEndDate}T${bulkEndTime}`);

    if (isAfter(start, end)) {
      toast.warning('Start date must be before end date');
      return;
    }

    const newEvents = [];

    let currentDate = start;
    while (currentDate <= end) {
      let slotStart = setHours(
        setMinutes(currentDate, parseInt(bulkStartTime.split(':')[1])),
        parseInt(bulkStartTime.split(':')[0])
      );
      const dayEnd = setHours(
        setMinutes(currentDate, parseInt(bulkEndTime.split(':')[1])),
        parseInt(bulkEndTime.split(':')[0])
      );

      while (slotStart < dayEnd) {
        const slotEnd = addMinutes(slotStart, slotDuration);
        newEvents.push({
          start: slotStart,
          end: slotEnd,
          title: 'Available',
        });
        slotStart = slotEnd;
      }

      currentDate = addMinutes(currentDate, 24 * 60); // Move to next day
    }

    setEvents((prevEvents) => [...prevEvents, ...newEvents]);
  };

  const handleDeleteEvent = useCallback((event) => {
    setEvents((prevEvents) => prevEvents.filter((e) => e !== event));
  }, []);

  const handleSelectSlot = useCallback(
    ({ start }) => {
      const selectedDate = format(start, 'yyyy-MM-dd');

      if (!isSelectingEndDate) {
        setBulkStartDate(selectedDate);
        setBulkEndDate(selectedDate);
        setIsSelectingEndDate(true);
      } else {
        setBulkEndDate(selectedDate);
        setIsSelectingEndDate(false);
      }
    },
    [isSelectingEndDate]
  );

  const handleClearSelection = () => {
    setBulkStartDate('');
    setBulkEndDate('');
    setIsSelectingEndDate(false);
  };

  const handleClearAllSlots = () => {
    setEvents([]);
  };

  const handleSubmitSlots = async () => {
    if (events.length === 0) {
      toast.warning('No slots to submit');
      return;
    }

    const formattedSlots = events.map((event) => ({
      unit_viewing_id: uuidv4(),
      start_time: event.start.toISOString(),
      end_time: event.end.toISOString(),
      duration: slotDuration,
      campaign_id: campaignId,
      status: 'available',
      tenant_id: null,
      tenant_name: '',
      note: '',
    }));

    const response = await createUnitViewing(formattedSlots);
    if (response) {
      toast.success('Slots submitted successfully');

      // Clear the events after submission
      setEvents([]);

      navigate(`/advertisementCampaign/${campaignId}`);
    } else {
      toast.error('Failed to submit slots');
    }
  };

  //update to be the form on the left and the calendar on the right and style it

  return (
    <div className='page-content' data-bs-theme={isDarkMode ? 'dark' : 'light'}>
      <div className='container-fluid'>
        <PageTitleBox
          pageTitle='Calendar Management'
          previousPageTitle='Advertisement Detail'
          previousPageLink={`/property/${campaignId}`}
        />
        <div className=''>
          <div className='row'>
            <div className='col-md-3 col-12'>
              <div className='card p-2'>
                <h3>Bulk Create Time Slots</h3>
                <form>
                  <div className='row mb-3'>
                    <div className='col'>
                      <label htmlFor='bulkStartDate' className='form-label'>
                        Start Date
                      </label>
                      <input
                        type='date'
                        id='bulkStartDate'
                        className='form-control'
                        value={bulkStartDate}
                        onChange={(e) => setBulkStartDate(e.target.value)}
                      />
                    </div>
                    <div className='col'>
                      <label htmlFor='bulkEndDate' className='form-label'>
                        End Date
                      </label>
                      <input
                        type='date'
                        id='bulkEndDate'
                        className='form-control'
                        value={bulkEndDate}
                        onChange={(e) => setBulkEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <label htmlFor='bulkStartTime' className='form-label'>
                        Start Time
                      </label>
                      <input
                        type='time'
                        id='bulkStartTime'
                        className='form-control'
                        value={bulkStartTime}
                        onChange={(e) => setBulkStartTime(e.target.value)}
                      />
                    </div>
                    <div className='col'>
                      <label htmlFor='bulkEndTime' className='form-label'>
                        End Time
                      </label>
                      <input
                        type='time'
                        id='bulkEndTime'
                        className='form-control'
                        value={bulkEndTime}
                        onChange={(e) => setBulkEndTime(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <label htmlFor='slotDuration' className='form-label'>
                        Slot Duration
                      </label>
                      <select
                        id='slotDuration'
                        className='form-select'
                        value={slotDuration}
                        onChange={(e) =>
                          setSlotDuration(parseInt(e.target.value))
                        }
                      >
                        <option value={15}>15 minutes</option>
                        <option value={30}>30 minutes</option>
                        <option value={45}>45 minutes</option>
                        <option value={60}>60 minutes</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <button
                        type='button'
                        className='btn btn-primary w-100'
                        onClick={handleBulkCreate}
                      >
                        Create Slots
                      </button>
                    </div>
                    <div className='col'>
                      <button
                        type='button'
                        className='btn btn-secondary w-100'
                        onClick={handleClearSelection}
                      >
                        Clear Date Selection
                      </button>
                    </div>
                    <div className='col'>
                      <button
                        type='button'
                        className='btn btn-danger w-100'
                        onClick={handleClearAllSlots}
                      >
                        Clear All Slots
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='col-md-9 col-12'>
              <div className='card p-2'>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor='start'
                  endAccessor='end'
                  style={{ height: 500 }}
                  selectable
                  onSelectEvent={handleDeleteEvent}
                  onSelectSlot={handleSelectSlot}
                  step={calendarStep}
                  timeslots={calendarTimeslots}
                  view={view}
                  onView={setView}
                  components={{
                    event: CustomEvent,
                  }}
                  min={new Date(0, 0, 0, 6, 0, 0)} // Set minimum time to 6:00 AM
                  max={new Date(0, 0, 0, 22, 0, 0)} // Set maximum time to 10:00 PM
                />
                <button
                  onClick={handleSubmitSlots}
                  className='mt-3 col-md-2 mx-auto btn btn-success'
                >
                  Submit Slots
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarManagement;
