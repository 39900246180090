import React, { createContext, useContext, useState } from "react";

const SubscriptionStatusContext = createContext();

export function SubscriptionStatusProvider({ children }) {
  const [subscriptionStatus, setSubscriptionStatusState] = useState({
    subscription_detail_id: "",
    subscription_id: "",
    user_id: "",
    max_allowed_units: 0,
    amt_of_units_used: 0,
    subscription_start_date: "",
    subscription_end_date: "",
    subscription_name: "",
    is_on_free_trial: false,
    free_trial_start_date: "",
    free_trial_end_date: "",
    subscription_status: "",
    number_of_free_trial_days_left: 0,
    is_subscription_active: false,
    subscription_created_date: "",
    subscription_updated_date: "",
    subscription_cancelled_date: "",
    subscription_cancelled_by: "",
    subscription_cancelled_reason: "",
    subscription_cancelled_comment: "",
    first_subcription_payment_date: "",
    has_churned: false,
    subscription_updated: "",
    bulk_discount_rate: 0,
    bulk_discount_threshold: 0,
    cost_per_additional_unit: 0,
    product_id: "",
  });

  return (
    <SubscriptionStatusContext.Provider
      value={{ subscriptionStatus, setSubscriptionStatusState }}
    >
      {children}
    </SubscriptionStatusContext.Provider>
  );
}

export function useSubscriptionStatus() {
  return useContext(SubscriptionStatusContext);
}
/*
export const setSubscriptionStatus = (status) => {
  const { setSubscriptionStatusState } = useSubscriptionStatus();
  setSubscriptionStatusState(status);
};*/
