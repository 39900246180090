import React from 'react';

const FilePreview = ({
  file_name = '',
  file_type = '',
  url = '',
  width = '32px',
  height = '32px',
}) => {
  const getIconClassByFileType = (type) => {
    if (!type) return 'ri-file-line text-primary';

    switch (type.toLowerCase()) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return 'ri-image-line text-info';
      case 'application/pdf':
        return 'ri-file-pdf-line text-danger';
      case 'application/zip':
      case 'application/x-zip-compressed':
        return 'ri-file-zip-line text-warning';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'ri-file-ppt-2-line text-danger';
      default:
        return 'ri-file-line text-primary';
    }
  };

  const isImage = file_type && file_type.startsWith('image/');

  return (
    <div className='flex-shrink-0 avatar-sm'>
      <div
        className='avatar-title bg-light rounded d-flex align-items-center justify-content-center'
        style={{ width: width, height: height }}
      >
        {isImage && url ? (
          <img
            src={url}
            alt={file_name || 'File preview'}
            className='img-fluid'
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
          />
        ) : (
          <i className={`${getIconClassByFileType(file_type)} fs-20`}></i>
        )}
      </div>
    </div>
  );
};

export default FilePreview;
