import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  checkSubscriptionStatus,
  getSubscriptionDetails,
} from "../../../../services/authentication/subscription/subscriptionUtils";
import { toast } from "react-toastify";
import LoaderView from "../../../reusable/loading/loaderView";

const SubscriptionRoute = ({ children }) => {
  const [isChecking, setIsChecking] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const location = useLocation();
  //get the dark mode from the local storage
  const isDarkMode = localStorage.getItem("is_dark_mode") === "true";

  useEffect(() => {
    const quickCheck = checkSubscriptionStatus();
    setHasAccess(quickCheck);
    setIsChecking(false);
  }, []);

  if (isChecking) {
    return <LoaderView isDark={isDarkMode} />;
  }

  if (!hasAccess) {
    const subscriptionDetails = getSubscriptionDetails();

    // Show appropriate toast message based on subscription status
    if (
      subscriptionDetails.status === "trialing" &&
      subscriptionDetails.trialDaysLeft <= 0
    ) {
      toast.warning("Your trial has expired. Please upgrade to continue.", {
        autoClose: false,
      });
    } else if (!subscriptionDetails.status) {
      toast.info("Please subscribe to access this feature.", {
        autoClose: false,
      });
    }

    return <Navigate to="/paywall" state={{ from: location }} replace />;
  }

  return children;
};

export default SubscriptionRoute;
