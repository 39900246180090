import http from "../../services/general/httpService";
import config from "../../constants/config.json";

///reminder/get_reminder_by_user_id_and_property_unit_id

export async function getReminderManagerFromServer(property_unit_id) {
    const user_id = localStorage.getItem(config.user_id);
    const fullApiEndpoint = config.apiEndpoint + `/reminder/get_reminder_manager_by_user_id_and_property_unit_id`;
    const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id,property_unit_id: property_unit_id});

    return result;
}

//create_task_reminder
export async function createTaskReminder(reminder) {
    const fullApiEndpoint = config.apiEndpoint + `/reminder/create_task_reminder`;
    const {data: result} = await http.post(fullApiEndpoint, reminder);

    return result;
}

//get_task_reminders_by_reminder_id_and_user_id
export async function getTaskRemindersByReminderIdAndUserId(reminder_id) {
    const user_id = localStorage.getItem(config.user_id);
    const fullApiEndpoint = config.apiEndpoint + `/reminder/get_task_reminders_by_reminder_id_and_user_id`;
    const {data: result} = await http.post(fullApiEndpoint, {reminder_id: reminder_id,user_id: user_id});

    return result;
}

//get_task_reminders_by_task_reminder_id
export async function getTaskRemindersByTaskReminderId(task_reminder_id) {
    const fullApiEndpoint = config.apiEndpoint + `/reminder/get_task_reminders_by_task_reminder_id`;
    const {data: result} = await http.post(fullApiEndpoint, {task_reminder_id: task_reminder_id});

    return result;
}