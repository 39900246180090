import React from 'react';
import { Link } from 'react-router-dom';

const TicketCard = ({ ticket }) => {
  return (
    <div className='card tasks-box'>
      <div className='card-body'>
        <div className='d-flex mb-2'>
          <div className='flex-grow-1'>
            <h6 className='fs-15 mb-0 text-truncate task-title'>
              <Link
                to={`/ticket/${ticket.ticket_id}`}
                className='text-muted fw-medium fs-14 flex-grow-1'
              >
                #{ticket.ticket_number}
              </Link>
            </h6>
          </div>
        </div>
        <h6 className='fs-15 text-truncate task-title'>
          <Link
            to={`/ticket/${ticket.ticket_id}`}
            className='text-body d-block'
          >
            {ticket.ticket_title}
          </Link>
        </h6>
        <p className='text-muted'>
          {ticket && ticket.ticket_description.substring(0, 100) + '...'}
        </p>
        <div className='d-flex align-items-center'>
          <div className='flex-grow-1'>
            <span className='badge bg-primary-subtle text-primary me-2'>
              {ticket.ticket_status}
            </span>
            <span className='badge bg-primary-subtle text-primary me-2'>
              {ticket.ticket_type}
            </span>
            <span className='badge bg-primary-subtle text-primary'>
              {ticket.priority_info?.priority}
            </span>
          </div>
        </div>
      </div>
      <div className='card-footer border-top-dashed'>
        <div className='d-flex'>
          <div className='flex-grow-1'>
            <span className='text-muted'>
              <i className='ri-time-line align-bottom'></i>{' '}
              {new Date(ticket.updated_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </span>
          </div>
          <div className='flex-shrink-0'>
            <ul className='link-inline mb-0'>
              <li className='list-inline-item'>
                <Link to={`/ticket/${ticket.ticket_id}`} className='text-muted'>
                  <i className='ri-eye-line align-bottom'></i> 0
                </Link>
              </li>
              <li className='list-inline-item'>
                <Link to={`/ticket/${ticket.ticket_id}`} className='text-muted'>
                  <i className='ri-question-answer-line align-bottom'></i> 0
                </Link>
              </li>
              <li className='list-inline-item'>
                <Link to={`/ticket/${ticket.ticket_id}`} className='text-muted'>
                  <i className='ri-attachment-2 align-bottom'></i> 0
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
