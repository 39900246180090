import http from "../../general/httpService";
import api from "../../../constants/config.json";
import config from "../../../constants/config.json";
import { toast } from "react-toastify";
import { devLog } from "../../utils/logger";

// Subscription Status Management
export async function getSubscriptionStatusFromStripe() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);
  if (!customerStripeId) return null;

  try {
    const fullApiEndpoint = `${api.apiEndpoint}/subscription_manager/get_active_subscriptions_from_stripe`;
    const response = await http.post(fullApiEndpoint, {
      stripe_customer_id: customerStripeId,
    });

    // Check if we have a valid response with data
    if (
      !response.data ||
      response.data.message === "No active subscription found"
    ) {
      clearSubscriptionData();
      return null;
    }

    // Store relevant subscription data
    const subscriptionData = response.data;
    devLog("Subscription Data:", subscriptionData);

    localStorage.setItem(config.is_paid, true);
    localStorage.setItem(
      config.subscription_name,
      subscriptionData.data?.plan?.nickname || "Premium"
    );
    localStorage.setItem(
      config.subscription_status,
      subscriptionData.data?.status || ""
    );
    localStorage.setItem(
      config.subscription_id,
      subscriptionData.data?.id || ""
    );

    // If it's a trial, store trial information
    if (subscriptionData.data?.status === "trialing") {
      const trialEnd = subscriptionData.data.trial_end;
      const now = Math.floor(Date.now() / 1000);
      const daysLeft = Math.ceil((trialEnd - now) / (24 * 60 * 60));
      localStorage.setItem(config.number_of_free_trial_days_left, daysLeft);
    }

    return subscriptionData; // Return the full subscription data object
  } catch (error) {
    handleSubscriptionError(error);
    return null;
  }
}

// Billing Session Management
export async function createCheckoutSession(lookupKey) {
  const fullApiEndpoint = `${api.apiEndpoint}/subscription_manager/create-checkout-session`;
  const { data: sessionId } = await http.post(fullApiEndpoint, {
    lookup_key: lookupKey,
  });
  return sessionId;
}

export async function createPortalSession(sessionId) {
  if (!sessionId) return null;
  const fullApiEndpoint = `${api.apiEndpoint}/subscription_manager/create-portal-session`;
  const { data: portalSession } = await http.post(fullApiEndpoint, {
    session_id: sessionId,
  });
  return portalSession;
}

// Helper Functions
function handleNoActiveSubscription(setSubscriptionStatusCallback) {
  if (typeof setSubscriptionStatusCallback === "function") {
    setSubscriptionStatusCallback(null); // Set to null instead of empty object
  }
  toast.warning("No active subscription found. Please subscribe to continue.");
  clearSubscriptionData();
}

function handleSubscriptionError(error) {
  console.error("Subscription error:", error);
  toast.error("Failed to fetch subscription status. Please try again later.");
}

function clearSubscriptionData() {
  localStorage.setItem(config.is_paid, false);
  localStorage.setItem(config.subscription_name, "");
  localStorage.setItem(config.max_allowed_units, 0);
  localStorage.setItem(config.amt_of_units_used, 0);
  localStorage.setItem(config.number_of_free_trial_days_left, 0);
  localStorage.setItem(config.subscription_id, "");
  localStorage.setItem(config.subscription_status, "");
}

///TODO: webhook to be set up in stripe

///get_last_payment_status and is a post and takes a stripe_customer_id
export async function getBillingDetailsFromStripe() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);
  if (!customerStripeId) {
    return null;
  }

  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/get_last_payment_status`;

  try {
    const { data: paymentStatus } = await http.post(fullApiEndpoint, {
      stripe_customer_id: customerStripeId,
    });
    return paymentStatus;
  } catch (error) {
    console.log("Failed to fetch payment status:", error);
    return null; // or handle error as needed
  }
}

///create-customer-session
export async function createCustomerSession() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);
  //if customerStripeId is null, return null
  if (!customerStripeId) {
    return null;
  }

  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/create-customer-session`;
  const { data: customerSession } = await http.post(fullApiEndpoint, {
    stripe_customer_id: customerStripeId,
  });
  return customerSession; // This should be an object with a client_secret property
}

///get_all_subscription_history
export async function getAllSubscriptionHistory() {
  const customerStripeId = localStorage.getItem(config.stripe_customer_id);
  //if customerStripeId is null, return null
  if (!customerStripeId) {
    return null;
  }

  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/get_all_subscription_history`;
  const { data: subscriptionHistory } = await http.post(fullApiEndpoint, {
    stripe_customer_id: customerStripeId,
  });
  return subscriptionHistory;
}

//pause subscription

//cancel subscription
///cancel_subscription
export async function cancelSubscription(subscription_id) {
  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/cancel_subscription`;
  const { data: subscriptionStatus } = await http.post(fullApiEndpoint, {
    subscription_id: subscription_id,
  });
  return subscriptionStatus;
}

//get_or_create_subscription_detail
export async function getOrCreateSubscriptionDetail() {
  const user_id = localStorage.getItem(config.user_id);
  //if user_id is null, return null
  if (!user_id) {
    return null;
  }
  const fullApiEndpoint =
    api.apiEndpoint + `/subscription_manager/get_or_create_subscription_detail`;
  const { data: subscriptionDetail } = await http.post(fullApiEndpoint, {
    user_id: user_id,
  });
  return subscriptionDetail;
}
