import React, { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'quill/dist/quill.snow.css';
import '../ckeditor/editorStyles.css';

// Create a new blot that inherits from Quill's existing Link blot
let Link = Quill.import('formats/link');

class ExternalLink extends Link {
  static create(value) {
    let node = super.create(value);
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
    return node;
  }
}

// Name our blot and inherit from Link blot
ExternalLink.blotName = 'external_link';
ExternalLink.tagName = 'A';

// Register our new blot
Quill.register(ExternalLink);

function HTMLEditor({ content, handleChange, height = '200px' }) {
  return (
    <ReactQuill
      className='snow-editor'
      placeholder={'Write something'}
      value={content}
      onChange={handleChange}
      style={{ height: height }}
      modules={{
        toolbar: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          [
            'link',
            //'image', 'video' //re-enable these in the future
          ],
          ['clean'],
        ],
      }}
      formats={[
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'external_link', // Add our new format here
      ]}
    />
  );
}

export default HTMLEditor;
