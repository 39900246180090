import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { Upload, X, FileText, Plus } from "lucide-react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import FilePDFImagePreview from "../../../../../../../../tickets/detail/sub/filePdfImagePreview";

// Web Worker code (same as before)
const workerCode = `
  self.onmessage = function(e) {
    const file = e.data.file;
    const reader = new FileReader();
    reader.onload = function(event) {
      self.postMessage({ id: e.data.id, result: event.target.result });
    };
    reader.readAsDataURL(file);
  }
`;

const workerBlob = new Blob([workerCode], { type: "application/javascript" });
const workerUrl = URL.createObjectURL(workerBlob);

const PreviewExistingItem = React.memo(({ attachment }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const handleOpenPreviewModal = () => setShowPreviewModal(true);
  const handleClosePreviewModal = () => setShowPreviewModal(false);

  const isPDForImage =
    attachment.file_type === "application/pdf" ||
    attachment.file_type.startsWith("image/");

  return (
    <>
      <div className="card h-100">
        <div className="card-body p-3">
          <div className="d-flex align-items-center">
            <div
              className="position-relative me-3"
              style={{
                width: "80px",
                height: "80px",
                cursor: "pointer",
                flexShrink: 0,
              }}
              onClick={handleOpenPreviewModal}
            >
              {attachment.file_type.startsWith("image/") ? (
                <img
                  src={attachment.url}
                  alt={attachment.file_name}
                  className="w-100 h-100 object-fit-cover rounded"
                />
              ) : (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center bg-light rounded">
                  <FileText size={40} />
                </div>
              )}
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="mb-1 text-truncate" style={{ fontSize: "0.9rem" }}>
                {attachment.file_name}
              </p>
              <small className="text-muted" style={{ fontSize: "0.8rem" }}>
                {attachment.file_size_formatted}
              </small>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showPreviewModal} onHide={handleClosePreviewModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{attachment.file_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPDForImage ? (
            <FilePDFImagePreview
              itemToPreview={attachment}
              setItemToDelete={() => {}}
              setShowConfirm={() => {}}
              showDeleteBtn={false}
            />
          ) : (
            <div className="text-center">
              <FileText size={64} />
              <p>This file type cannot be previewed.</p>
              <a
                href={attachment.url}
                download
                className="btn btn-primary mt-2"
              >
                Download File
              </a>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePreviewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

const DocumentUploadSection = ({
  attachments,
  attachmentUploads,
  handleConfirmDocuments,
  handleDeleteAttachment,
  uploadBtnText = "Upload Documents",
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64Data, setBase64Data] = useState([]);
  const workerRef = useRef();
  const queueRef = useRef([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    workerRef.current = new Worker(workerUrl);
    workerRef.current.onmessage = (e) => {
      setBase64Data((prev) => [
        ...prev,
        { id: e.data.id, data: e.data.result },
      ]);
      processQueue();
    };
    return () => workerRef.current.terminate();
  }, []);

  const processQueue = useCallback(() => {
    if (queueRef.current.length > 0) {
      const { file, id } = queueRef.current.shift();
      workerRef.current.postMessage({ file, id });
    }
  }, []);

  const addFiles = useCallback(
    (files) => {
      const newFiles = Array.from(files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      newFiles.forEach((file, index) => {
        const id = Date.now() + index;
        queueRef.current.push({ file, id });
      });

      processQueue();
    },
    [setSelectedFiles]
  );

  const handleRemoveExistingAttachment = useCallback(
    (index) => {
      handleDeleteAttachment(attachments[index].unique_file_name);
    },
    [attachments, handleDeleteAttachment]
  );

  return (
    <div className="document-upload-section">
      <div className="existing-documents mt-4">
        <h6 className="mb-3">Existing Documents</h6>
        <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-2">
          {attachments.map((attachment, index) => (
            <div key={index} className="col">
              <PreviewExistingItem
                attachment={attachment}
                onRemove={() => {}}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentUploadSection;
