import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Modal, Badge, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { useNavigate, useParams } from "react-router-dom";

import PageTitleBox from "../../../reusable/title/pageTitleBox";
import Input from "../../../reusable/form/Input";
import {
  createOrUpdateUnitTicket,
  getUnitTicketByTicketId,
  updateUnitTicket,
} from "../../../../services/tickets/unitTicketsManagement";
import { fetchAllPriorities } from "../../../../services/systemDefined/priority/sysPriorityManagement";
import { fetchAllStatuses } from "../../../../services/systemDefined/status/sysStatusManagemetn";
import { getPropertyUnitByPropertyUnitId } from "../../../../services/propertyUnits/propertyUnitManagement";
import { selectCustomStyle } from "../../../../styletheme/custom/selectStyle";

//version when we know the property_unit_id and type where we dont know and they have to select the portfolio -> property -> unit
const EditUnitTicket = ({ isDarkMode }) => {
  const { ticketId } = useParams();
  const pageTitle = "Edit Unit Ticket";
  const [unit, setUnit] = useState({
    market_rent_amount: "",
    number_of_bathrooms: "",
    number_of_bedrooms: "",
    property_id: "",
    property_unit_id: "",
    square_footage: "",
    unit_name: "",
  });
  const [ticketData, setTicketData] = useState({
    ticket_type: "",
    ticket_title: "",
    ticket_description: "",
    ticket_priority_id: "",
    ticket_status_id: "",
    ticket_number: "",
    ticket_status: "",
    updated_at: Date.now(),
    created_at: Date.now(),
  });

  const [errors, setErrors] = useState({});

  const [ticketTypes, setTicketTypes] = useState([
    { value: "Maintenance", label: "Maintenance" },
    { value: "Repair", label: "Repair" },
    { value: "Other", label: "Other" },
  ]);

  //need to come from the API
  const [ticketPriorities, setTicketPriorities] = useState([
    { value: "", label: "", data: {} },
  ]);

  const [ticketStatuses, setTicketStatuses] = useState([
    { value: "", label: "", data: {} },
  ]);

  const [ticketTypesSelected, setTicketTypesSelected] = useState(null);
  const [ticketPrioritiesSelected, setTicketPrioritiesSelected] =
    useState(null);
  const [ticketStatusesSelected, setTicketStatusesSelected] = useState(null);

  const [ticketNumber, setTicketNumber] = useState("");

  const navigate = useNavigate();

  const schema = {
    ticket_id: Joi.string().allow("").optional(),
    property_unit_id: Joi.string().allow("").optional(),
    ticket_type: Joi.string().optional().label("Ticket Type"),
    ticket_title: Joi.string().required().label("Ticket Title"),
    ticket_description: Joi.string().required().label("Ticket Description"),
    ticket_priority_id: Joi.string().optional().label("Ticket Priority"),
    ticket_status_id: Joi.string().optional().label("Ticket Status"),
    ticket_number: Joi.string().required().label("Ticket Number"),
    ticket_status: Joi.string().optional().label("Ticket Status"),
    updated_at: Joi.date().optional().label("Updated At"),
    created_at: Joi.date().optional().label("Created At"),
  };

  const validate = () => {
    const options = { abortEarly: false };

    const { error } = Joi.validate(ticketData, schema, options);

    //console.log('error');
    //console.log(error);

    const errors = {};

    if (!error && Object.keys(errors).length === 0) return null;

    // Check if error is not null before trying to access error.details
    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const options = { abortEarly: false };
    const { error } = Joi.validate(obj, schemaLocal, options);

    return error ? error.details[0].message : null;
  };
  const handleChange = (input) => {
    const currentErrors = { ...errors };
    let name, value;

    // Check if input is an event object (has a currentTarget property)
    if (input && input.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    }
    const errorMessage = validateProperty({ name, value });

    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    const data = { ...ticketData };
    if (value !== undefined) {
      data[name] = value;
    }

    setTicketData(data);
    setErrors(currentErrors);
  };

  const handleTicketTypeChange = (ticketTypesSelected) => {
    setTicketTypesSelected(ticketTypesSelected);
    const data = { ...ticketData };
    data["ticket_type"] = ticketTypesSelected.value;
    setTicketData(data);
  };

  const handleTicketPriorityChange = (ticketPrioritiesSelected) => {
    setTicketPrioritiesSelected(ticketPrioritiesSelected);
    const data = { ...ticketData };
    data["ticket_priority_id"] = ticketPrioritiesSelected.value;

    setTicketData(data);
  };

  const handleTicketStatusChange = (ticketStatusesSelected) => {
    setTicketStatusesSelected(ticketStatusesSelected);
    const data = { ...ticketData };

    data["ticket_status_id"] = ticketStatusesSelected.value;
    data["ticket_status"] = ticketStatusesSelected.label;
    setTicketData(data);
  };

  //update the ticket
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();

    setErrors(errors || {});

    if (errors) return;

    try {
      //add ticket_id etc

      console.log("ticketData");
      console.log(ticketData);

      //update the updated_at
      ticketData.updated_at = Date.now();

      const result = await updateUnitTicket(ticketData);

      if (result) {
        toast.success("Unit Ticket Update Successfully");
        navigate(`/ticket/${ticketId}`); //the ticket
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...errors };
        errors.ticket_title = ex.response.data;
        setErrors(errors);
      }
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, []);

  async function fetchTicketDetails() {
    // await getAllPriorities();
    // await getAllStatuses();

    //get the ticket details
    getTicketDetails(ticketId);
  }

  const getAllPriorities = async (_ticket) => {
    const result = await fetchAllPriorities();
    console.log(result);
    const priorities = result.map((p) => {
      return { value: p.priority_id, label: p.priority, data: p };
    });

    setTicketPriorities(priorities);

    console.log("ticketPriorities");
    console.log(priorities);

    console.log("ticketPriority");
    console.log(_ticket.ticket_priority_id);
    // Set the ticket priorities
    const ticketPriority = _ticket.ticket_priority_id;
    const ticketPrioritySelected = priorities.find(
      (t) => t.value === ticketPriority
    );

    toast.info(ticketPrioritySelected);
    setTicketPrioritiesSelected(ticketPrioritySelected);
  };

  //get all statuses
  const getAllStatuses = async (_ticket) => {
    const result = await fetchAllStatuses();
    console.log(result);
    const statuses = result.map((s) => {
      return { value: s.status_id, label: s.status, data: s };
    });

    setTicketStatuses(statuses);

    // Set the ticket statuses
    const ticket_status_id = _ticket.ticket_status_id;
    const ticketStatusSelected = statuses.find(
      (t) => t.value === ticket_status_id
    );
    setTicketStatusesSelected(ticketStatusSelected);
  };

  //get the unit details
  const getUnitDetails = async (_propertyUnitId) => {
    //get the unit details

    const unit = await getPropertyUnitByPropertyUnitId(_propertyUnitId);
    console.log(unit);
    setUnit(unit);
  };

  //getAllTicketTypes
  const getAllTicketTypes = async (_ticket) => {
    //This should come from api in the future
    const ticketType = _ticket.ticket_type; // Assuming ticket type is identified by ticket_type_id
    const ticketTypeSelected = ticketTypes.find((t) => t.value === ticketType);
    setTicketTypesSelected(ticketTypeSelected);
  };

  const getTicketDetails = async (_ticketId) => {
    // Get the ticket details
    const ticket = await getUnitTicketByTicketId(_ticketId);
    console.log("ticket");
    console.log(ticket);
    setTicketData(ticket);

    // Fetch all priorities and statuses, and potentially types, before setting selected values
    await getAllPriorities(ticket);
    await getAllStatuses(ticket);
    // Assuming there's a function similar to getAllPriorities and getAllStatuses to fetch ticket types
    await getAllTicketTypes(ticket); // This function needs to be implemented

    // Set the ticket number
    setTicketNumber(ticket.ticket_number);

    // Get the unit details
    getUnitDetails(ticket.property_unit_id);
  };
  return (
    <React.Fragment>
      <div
        data-bs-theme={isDarkMode ? "dark" : "light"}
        className="page-content"
      >
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Ticket Detail"
            previousPageLink={`/ticket/${ticketId}`}
          />
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Edit Ticket</h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        <div className="col-xxl-3 col-md-6">
                          <Input
                            name="ticket_number"
                            label="Ticket Number"
                            value={ticketNumber}
                            onChange={handleChange}
                            error={errors.ticket_number}
                            isDisabled={true}
                          />
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <Input
                            name="ticket_title"
                            label="Ticket Title"
                            value={ticketData.ticket_title}
                            onChange={handleChange}
                            error={errors.ticket_title}
                          />
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <Input
                            name="ticket_description"
                            label="Ticket Description"
                            value={ticketData.ticket_description}
                            onChange={handleChange}
                            error={errors.ticket_description}
                          />
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <label htmlFor="ticket_type">Ticket Type</label>
                          <Select
                            name="ticket_type"
                            options={ticketTypes}
                            value={ticketTypesSelected}
                            onChange={handleTicketTypeChange}
                            styles={selectCustomStyle(isDarkMode)}
                          />
                        </div>
                        <div className="col-xxl-3 col-md-6">
                          <label htmlFor="ticket_priority_id">
                            Ticket Priority
                          </label>
                          <Select
                            name="ticket_priority_id"
                            options={ticketPriorities}
                            value={ticketPrioritiesSelected}
                            onChange={handleTicketPriorityChange}
                            styles={selectCustomStyle(isDarkMode)}
                          />
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <label htmlFor="ticket_status_id">
                            Ticket Status
                          </label>
                          <Select
                            name="ticket_status_id"
                            options={ticketStatuses}
                            value={ticketStatusesSelected}
                            onChange={handleTicketStatusChange}
                            styles={selectCustomStyle(isDarkMode)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row gy-4 mt-3">
                      <div className="col-xxl-3 col-md-6">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={validate()}
                        >
                          Update Unit Ticket
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditUnitTicket;
