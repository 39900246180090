import React from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../../services/utils/currencyUtils';

const TopRevenueCard = ({ unit, defaultHouseImg }) => {
  return (
    <div className='card card-body border'>
      <div className='d-flex align-items-start'>
        <div className='flex-shrink-0'>
          <Link to={`/propertyunit/${unit.property_unit_id}`}>
            <img
              src={defaultHouseImg}
              alt=''
              className='avatar-sm rounded material-shadow'
            />
          </Link>
        </div>
        <div className='flex-grow-1 ms-3'>
          <Link to={`/propertyunit/${unit.property_unit_id}`}>
            <h6 className='fs-15 text-truncate task-title'>{unit.unit_name}</h6>
          </Link>
          <div className='d-flex mb-2 align-items-center'>
            <div className='col-3 text-start'>
              <p className='text-muted'>
                <i className='ri-bill-line'></i> {unit.revenue_category}
              </p>
            </div>
            <div className='col-2 text-start'>
              <p className='text-muted'>
                <i className='bx bx-bed'></i> {unit.number_of_bedrooms}
              </p>
            </div>
            <div className='col-2 text-start'>
              <p className='text-muted'>
                <i className='bx bx-bath'></i> {unit.number_of_bathrooms}
              </p>
            </div>
            <div className='col-2 text-start'>
              <p className='text-muted'>
                <i className='bx bx-ruler'></i> {unit.square_footage}
              </p>
            </div>
            <div className='col-3 text-start'>
              <p className='text-muted fw-bold'>
                <i className='bx bx-money'></i> {formatCurrency(unit.revenue)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRevenueCard;
