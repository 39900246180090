import http from "../../general/httpService";
import api from "../../../constants/config.json";

//https://gurenterlandlordapiv2.ngrok.io/sys_property_type/property_types

export async function fetchAllPropertyTypes() {
    const fullApiEndpoint = api.apiEndpoint + `/sys_property_type/property_types`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}

//sub_property_types
export async function fetchAllSubPropertyTypes() {
    const fullApiEndpoint = api.apiEndpoint + `/sys_property_type/all_sub_property_types`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}

//sub_property_types by property_type_id
export async function fetchSubPropertyTypesByPropertyTypeId(propertyTypeId) {
    const fullApiEndpoint = api.apiEndpoint + `/sys_property_type/sub_property_types_by_property_type_id/${propertyTypeId}`;
    const {data: result} = await http.get(fullApiEndpoint);

    return result;
}