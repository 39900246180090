import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

const ImageCarousel = ({ slideShowImages }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  return (
    <>
      <div
        className="card mt-4 card-bg-fill"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <Carousel data-bs-theme="dark" fade>
          {slideShowImages.map((slide) => (
            <Carousel.Item key={slide.id} interval={3500}>
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  overflow: "hidden",
                  height: "550px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer", // Add pointer cursor
                }}
                onClick={() => handleImageClick(slide)}
              >
                <img
                  className="d-block"
                  src={slide.image}
                  alt={slide.title}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                    opacity: 0.95,
                  }}
                />
              </div>
              <Carousel.Caption
                className="carousel-caption-background"
                style={{
                  background: "rgba(0, 0, 0, 0.4)",
                  borderRadius: "8px",
                  padding: "20px",
                  bottom: "40px",
                  maxWidth: "80%",
                  margin: "0 auto",
                  left: "10%",
                  right: "10%",
                }}
              >
                <h3
                  style={{
                    fontWeight: 700,
                    marginBottom: "10px",
                    color: "#fff",
                  }}
                >
                  {slide.title}
                </h3>
                <p
                  style={{
                    color: "#f1f1f1",
                    fontSize: "1.1rem",
                    marginBottom: 0,
                  }}
                >
                  {slide.description}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      {/* Fullscreen Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedImage?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div
            style={{
              backgroundColor: "#f5f5f5",
              width: "100%",
              height: "90vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={selectedImage?.image}
              alt={selectedImage?.title}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageCarousel;
