import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Input from "../../../../../reusable/form/Input";
//config file
import config from "../../../../../../constants/config.json";
import { v4 as uuidv4 } from "uuid";
import { createOrUpdatePartnershipAndOwnership } from "../../../../../../services/portfolio/ownership/portfolioOwnershipManagement";
import { toast } from "react-toastify";
import { selectCustomStyle } from "../../../../../../styletheme/custom/selectStyle";

const PropertyOwnership = ({ isDarkMode, portfolio_id, handleSubmit }) => {
  //re-enable owned by others later
  const [ownershipTypes, setOwnershipTypes] = useState([
    {
      value: "ownedByMe",
      label: "Owned by me",
    },
    /*{
      value: 'ownedByOther',
      label: 'Owned by Someone else',
    },*/
  ]);
  const [selectedOwnershipType, setSelectedOwnershipType] = useState({
    value: "ownedByMe",
    label: "Owned by me",
  });

  const handleOwnershipTypeChange = (selectedOwnershipType) => {
    setSelectedOwnershipType(selectedOwnershipType);
  };

  //partnership type
  const [partnership_types, setpartnership_types] = useState([
    {
      value: "general",
      label: "General",
    },
    {
      value: "limited",
      label: "Limited",
    },
  ]);
  const [selectedpartnership_type, setSelectedpartnership_type] =
    useState(null);
  //handlepartnership_typeChange
  const handlepartnership_typeChange = (selectedpartnership_type) => {
    setSelectedpartnership_type(selectedpartnership_type);
  };

  const [errors, setErrors] = useState({});
  const [newOwner, setNewOwner] = useState({
    ownership_structure_id: "",
    user_id: "", //if its not the current user it will be set when they accept the invite
    first_name: "",
    last_name: "",
    owner_email: "",
    owner_phone: "",
    portfolio_id: "",
    is_controlling_partner: false,
    ownership_percentage: "",
    partnership_type: "",
    ownership_start_date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewOwner((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [existingOwners, setExistingOwners] = useState([
    {
      ownership_structure_id: "",
      user_id: "",
      first_name: "",
      last_name: "",
      owner_email: "",
      owner_phone: "",
      portfolio_id: "",
      is_controlling_partner: false,
      ownership_percentage: "",
      partnership_type: "",
      ownership_start_date: "",
      ownership_end_date: "",
    },
  ]);

  //creating a new owner
  const handleCreateOwner = () => {
    //validation only one can be the controlling partner
    //handle own by me first which is one -> owner by others as well
    console.log(newOwner);
  };

  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [ownerEmail, setOwnerEmail] = useState(null);

  const handleSingleOwner = async () => {
    const user_id = userId;
    const first_name = firstName;
    const last_name = lastName;
    const owner_email = ownerEmail;

    if (!ensureOwnerIsNotEmpty(user_id, first_name, last_name, owner_email)) {
      return;
    }

    var newOwner = {
      ownership_structure_id: uuidv4(),
      user_id: user_id,
      first_name: first_name,
      last_name: last_name,
      owner_email: owner_email,
      owner_phone: "",
      portfolio_id: portfolio_id,
      is_controlling_partner: true,
      ownership_percentage: 100,
      partnership_type: "general",
      ownership_start_date: new Date().toISOString(),
    };

    const owner_list = [newOwner];
    const updatedListOfOwners = await createOrUpdatePartnershipAndOwnership(
      owner_list
    );

    if (updatedListOfOwners) {
      setExistingOwners(updatedListOfOwners);
      toast.success("Owner created successfully");
      handleSubmit(); // Call handleSubmit to finish the process
    } else {
      toast.error("Owner creation failed");
    }
  };

  const ensureOwnerIsNotEmpty = (
    user_id,
    first_name,
    last_name,
    owner_email
  ) => {
    if (!user_id || !first_name || !last_name || !owner_email) {
      toast.error(
        "Your profile information is missing. Please try pressing the button again."
      );
      return false;
    }
    return true;
  };

  //could change in the future
  //fetch existing owners that are associated with logged with the portfolio associated with this property
  const getExistingOwners = () => {
    //todo
  };

  //go into the property to create the property manager and edit the property ownership

  useEffect(() => {
    setTheLoggedInUser();
    //getExistingOwners();
  }, []);

  const setTheLoggedInUser = () => {
    setUserId(localStorage.getItem(config.user_id));
    setFirstName(localStorage.getItem(config.first_name));
    setLastName(localStorage.getItem(config.last_name));
    setOwnerEmail(localStorage.getItem(config.email));
  };

  const saveOwnership = () => {
    // Implement the logic to save ownership
    // Return true if successful, false otherwise
  };

  return (
    <div className="card-body">
      <div className="live-preview">
        <div className="row gy-4">
          <div className="col-xxl-3 col-md-6">
            <div>
              <label htmlFor="form-grid-category">Ownership</label>
              <Select
                options={ownershipTypes}
                value={selectedOwnershipType}
                onChange={handleOwnershipTypeChange}
                isSearchable
                placeholder="Select Ownership"
                styles={selectCustomStyle(isDarkMode)}
              />
            </div>
          </div>
          {/* Assign portfolio*/}
          <div className="col-xxl-3 col-md-6"></div>
          {/* If owned by someone else show the list of owners and option to add a new owner*/}
          {/*Put the form inside a Modal */}
          {selectedOwnershipType?.value === "ownedByOther" && (
            <div className="row gy-4">
              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    type="text"
                    label="Owner First Name"
                    name="first_name"
                    placeholder="Enter First Name"
                    value={newOwner.first_name}
                    onChange={handleChange}
                    error={errors.first_name}
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    type="text"
                    label="Owner Last Name"
                    name="last_name"
                    placeholder="Enter Last Name"
                    value={newOwner.last_name}
                    onChange={handleChange}
                    error={errors.last_name}
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    type="text"
                    label="Owner Email"
                    name="owner_email"
                    placeholder="Enter Owner Email"
                    value={newOwner.owner_email}
                    onChange={handleChange}
                    error={errors.owner_email}
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    type="text"
                    label="Owner Phone"
                    name="owner_phone"
                    placeholder="Enter Owner Phone"
                    value={newOwner.owner_phone}
                    onChange={handleChange}
                    error={errors.owner_phone}
                  />
                </div>
              </div>

              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    type="text"
                    label="Ownership Percentage"
                    name="ownership_percentage"
                    placeholder="Enter Ownership Percentage"
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div>
                  <label htmlFor="form-grid-category">Partnership Type</label>
                  <Select
                    options={partnership_types}
                    value={selectedpartnership_type}
                    onChange={handlepartnership_typeChange}
                    isSearchable
                    placeholder="Select Partnership Type"
                    styles={selectCustomStyle(isDarkMode)}
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-md-6">
                <div>
                  <Input
                    type="text"
                    label="Ownership Start Date"
                    name="ownership_start_date"
                    placeholder="Enter Ownership Start Date"
                  />
                </div>
              </div>

              {/* Is the controlling Partner use check box*/}
              <div className="col-xxl-3 col-md-6">
                <div className="d-flex flex-column align-items-center">
                  <label>Is Controlling Partner</label>
                  <input
                    type="checkbox"
                    name="is_controlling_partner"
                    checked={newOwner.is_controlling_partner}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row gy-4 mt-3">
        <div className="col-xxl-3 col-md-6 ms-auto text-end">
          {!portfolio_id && (
            <p>Please create a portfolio first before assigning ownership</p>
          )}
          <button
            type="button"
            className="btn btn-success"
            disabled={!portfolio_id}
            onClick={handleSingleOwner}
          >
            Assign Ownership and Finish
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropertyOwnership;
