import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { Upload, X, FileText } from 'lucide-react';
import { toast } from 'react-toastify';

// Web Worker for processing images
const workerCode = `
  self.onmessage = function(e) {
    const file = e.data.file;
    const reader = new FileReader();
    reader.onload = function(event) {
      self.postMessage({ id: e.data.id, result: event.target.result });
    };
    reader.readAsDataURL(file);
  }
`;

const workerBlob = new Blob([workerCode], { type: 'application/javascript' });
const workerUrl = URL.createObjectURL(workerBlob);

const PreviewItem = React.memo(({ file, index, onRemove }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [file]);

  return (
    <div
      className='position-relative'
      style={{ width: '100px', height: '100px' }}
    >
      {file.type.startsWith('image/') ? (
        preview && (
          <img
            src={preview}
            alt={`Preview ${index}`}
            className='w-100 h-100 object-fit-cover rounded'
          />
        )
      ) : (
        <div className='w-100 h-100 d-flex align-items-center justify-content-center bg-light rounded'>
          <FileText size={32} />
        </div>
      )}
      <button
        onClick={(e) => onRemove(index, e)}
        className='btn btn-danger btn-sm position-absolute top-0 end-0 rounded-circle p-0'
        style={{ width: '24px', height: '24px' }}
        aria-label='Remove file'
      >
        <X size={14} />
      </button>
    </div>
  );
});

const MultiImageUploader = ({
  selectedFiles,
  setSelectedFiles,
  base64Data,
  setBase64Data,
  imagesToUpload,
  setImagesToUpload,
  uploadBtnText = 'Upload Files',
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const workerRef = useRef();
  const queueRef = useRef([]);
  const dropZoneRef = useRef(null);

  useEffect(() => {
    workerRef.current = new Worker(workerUrl);
    workerRef.current.onmessage = (e) => {
      setBase64Data((prev) => [
        ...prev,
        { id: e.data.id, data: e.data.result },
      ]);
      processQueue();
    };
    return () => workerRef.current.terminate();
  }, []);

  const processQueue = useCallback(() => {
    if (queueRef.current.length > 0) {
      const { file, id } = queueRef.current.shift();
      workerRef.current.postMessage({ file, id });
    }
  }, []);

  const addFiles = useCallback(
    (files) => {
      const newFiles = Array.from(files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      newFiles.forEach((file, index) => {
        const id = Date.now() + index;
        queueRef.current.push({ file, id });
      });

      processQueue();
    },
    [setSelectedFiles]
  );

  const handleFileChange = useCallback(
    (event) => {
      event.preventDefault();
      addFiles(event.target.files);
    },
    [addFiles]
  );

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      addFiles(e.dataTransfer.files);
    },
    [addFiles]
  );

  const removeFile = useCallback(
    (index, e) => {
      e.preventDefault();
      setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
      setBase64Data((prev) => prev.filter((_, i) => i !== index));
    },
    [setSelectedFiles, setBase64Data]
  );

  const handleUpload = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        console.log('Files to be uploaded:');
        base64Data.forEach((item, index) => {
          console.log(`File ${index}:`, item.data.substring(0, 50) + '...');
        });

        const imagesToUpload = base64Data.map((item, index) => ({
          base64_data: item.data,
          file_name: selectedFiles[index].name,
        }));

        setImagesToUpload(imagesToUpload);

        //number of attachments confirmed
        toast.info(imagesToUpload.length + ' attachments confirmed!');
      } catch (error) {
        console.error('Error during upload:', error);
        toast.error('Upload failed. Please try again.');
      }
    },
    [base64Data, selectedFiles, setImagesToUpload]
  );

  const fileInputId = useMemo(
    () => `file-input-${Math.random().toString(36).substr(2, 9)}`,
    []
  );

  return (
    <div style={{ maxWidth: '500px' }}>
      <div className='card-body'>
        <div
          className={`mb-3 p-4 border rounded ${
            isDragging ? 'border-primary' : 'border-secondary'
          }`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          ref={dropZoneRef}
        >
          <input
            type='file'
            multiple
            onChange={handleFileChange}
            className='d-none'
            id={fileInputId}
            accept='image/*,.pdf'
          />
          <label htmlFor={fileInputId} className='w-100 text-center'>
            <Upload className='mb-2' size={32} />
            <p className='mb-0'>
              {isDragging
                ? 'Drop files here'
                : 'Click to select images or PDFs, or drag and drop'}
            </p>
          </label>
        </div>
        {selectedFiles.length > 0 && (
          <div className='row row-cols-3 g-2 mb-3'>
            {selectedFiles.map((file, index) => (
              <div key={index} className='col'>
                <PreviewItem file={file} index={index} onRemove={removeFile} />
              </div>
            ))}
          </div>
        )}
        <button
          onClick={handleUpload}
          disabled={selectedFiles.length === 0}
          className='btn btn-primary w-100 d-flex align-items-center justify-content-center'
        >
          <Upload className='me-2' size={16} />
          {uploadBtnText} ({selectedFiles.length})
        </button>
      </div>
    </div>
  );
};

export default MultiImageUploader;
