import http from "../../../services/general/httpService";
import api from "../../../constants/config.json";
import config from "../../../constants/config.json";

//next to create the advert screens
///property_unit_advert/create_property_unit_advert_listing
export async function createPropertyUnitAdvertListing(propertyUnitAdvert) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/create_property_unit_advert_listing`;
  const { data: result } = await http.post(fullApiEndpoint, propertyUnitAdvert);

  return result;
}

//httpget get_property_unit_advert_listing
export async function getPropertyUnitAdvertListing(advert_id) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_property_unit_advert_listing/${advert_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_all_advertisement_listings_by_landlord_id
export async function getAllAdvertisementListingsByLandlordId() {
  const landlord_id = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_all_active_advertisement_listings_by_landlord_id/${landlord_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//get_advertisement_listing_by_campaign_id
export async function getAdvertisementListingByCampaignId(campaign_id) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_advertisement_listing_by_campaign_id/${campaign_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}

//update_property_unit_advert_listing
export async function updatePropertyUnitAdvertListing(propertyUnitAdvert) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/update_property_unit_advert_listing`;
  const { data: result } = await http.put(fullApiEndpoint, propertyUnitAdvert);

  return result;
}

//get_all_property_unit_advert_listing_by_landlord_id
export async function getAllPropertyUnitAdvertListingByLandlordId(landlordId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_all_property_unit_advert_listing_by_landlord_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    landlord_id: landlordId,
  });

  return result;
}

//get_all_property_unit_advert_listing_by_property_unit_id
export async function getAllPropertyUnitAdvertListingByPropertyUnitId(
  propertyUnitId
) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_all_property_unit_advert_listing_by_property_unit_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    property_unit_id: propertyUnitId,
  });

  return result;
}

//get_all_property_unit_advert_listing_by_pack_id
export async function getAllPropertyUnitAdvertListingByPackId(packId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_all_property_unit_advert_listing_by_pack_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    pack_id: packId,
  });

  return result;
}

//get_property_unit_advert_listing_by_advert_id
export async function getPropertyUnitAdvertListingByAdvertId(advertId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_property_unit_advert_listing_by_advert_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    advert_id: advertId,
  });

  return result;
}

//get_property_unit_advert_listing_by_date_advert_ends
export async function getPropertyUnitAdvertListingByDateAdvertEnds(date) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_property_unit_advert_listing_by_date_advert_ends`;
  const { data: result } = await http.post(fullApiEndpoint, {
    date_advert_ends: date,
  });

  return result;
}

//get_property_unit_advert_listing_by_campaign_id
export async function getPropertyUnitAdvertListingByCampaignId(campaignId) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_property_unit_advert_listing_by_campaign_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    campaign_id: campaignId,
  });

  return result;
}

//get_all_expired_property_unit_advert_listing_by_landlord_id
export async function getAllExpiredPropertyUnitAdvertListingByLandlordId(
  landlordId
) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_all_expired_property_unit_advert_listing_by_landlord_id`;
  const { data: result } = await http.post(fullApiEndpoint, {
    landlord_id: landlordId,
  });

  return result;
}

///get_all_campaign_id_for_landlord_id/{landlord_id}
export async function getAllCampaignIdForLandlordId() {
  const user_id = localStorage.getItem(config.user_id);

  const fullApiEndpoint =
    api.apiEndpoint +
    `/property_unit_advert/get_all_campaign_id_for_landlord_id/${user_id}`;
  const { data: result } = await http.get(fullApiEndpoint);

  return result;
}
