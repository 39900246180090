import React, { useEffect, useState } from "react";

import PageTitleBox from "../../../reusable/title/pageTitleBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getCompletedTicketsByPropertyUnitId,
  getInProgressTicketsByPropertyUnitId,
  getNewTicketsByPropertyUnitId,
  getPendingTicketsByPropertyUnitId,
  getUnitTicketsByPropertyUnitId,
} from "../../../../services/tickets/unitTicketsManagement";
import {
  getCompletedTicketCountByPropertyUnitId,
  getInProgressTicketCountByPropertyUnitId,
  getNewTicketCountByPropertyUnitId,
  getPendingTicketCountByPropertyUnitId,
} from "../../../../services/tickets/unitTicketsStatisticsManagement";
import TicketsList from "./table/ticketList";
import TicketsKanbanBoard from "./kanban/ticketsKanbanBoard";
import LoaderView from "../../../reusable/loading/loaderView";
import { devLog } from "../../../../services/utils/logger";

//lottie here

const AllUnitTickets = ({ isDarkMode }) => {
  const { propertyUnitId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [isKanbanView, setIsKanbanView] = useState(false);

  const toggleView = () => setIsKanbanView(!isKanbanView);

  const [unitTickets, setUnitTickets] = useState([
    {
      ticket_id: "",
      property_unit_id: "",
      ticket_type: "",
      ticket_title: "",
      ticket_description: "",
      ticket_priority_id: "",
      ticket_status_id: "",
      ticket_number: "",
      ticket_status: "",
      priority_info: {
        created_at: "",
        is_active: true,
        priority: "",
        priority_id: "",
        updated_at: "",
      },
    },
  ]);

  //new tickets
  const [newUnitTickets, setNewUnitTickets] = useState([]);

  //in progress tickets
  const [inProgressUnitTickets, setInProgressUnitTickets] = useState([]);

  //pending tickets
  const [pendingUnitTickets, setPendingUnitTickets] = useState([]);

  //completed tickets
  const [completedUnitTickets, setCompletedUnitTickets] = useState([]);

  const [newTicketsCount, setNewTicketsCount] = useState(0);
  const [inProgressTicketsCount, setInProgressTicketsCount] = useState(0);
  const [pendingTicketsCount, setPendingTicketsCount] = useState(0);
  const [completedTicketsCount, setCompletedTicketsCount] = useState(0);

  const [isNewTicketsLoading, setIsNewTicketsLoading] = useState(false);
  const [isInProgressTicketsLoading, setIsInProgressTicketsLoading] =
    useState(false);
  const [isPendingTicketsLoading, setIsPendingTicketsLoading] = useState(false);
  const [isCompletedTicketsLoading, setIsCompletedTicketsLoading] =
    useState(false);

  //table components start

  const ticketColumns = [
    {
      field: "ticket_number",
      headerName: "Number",
      width: 190,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_number}</strong>,
    },
    {
      field: "ticket_title",
      headerName: "Title",
      width: 300,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_title}</strong>,
    },
    {
      field: "ticket_status",
      headerName: "Status",
      width: 120,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_status}</strong>,
    },
    {
      field: "ticket_priority_id",
      headerName: "Priority",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <strong>{params.row.priority_info.priority}</strong>
      ),
    },
    {
      field: "ticket_type",
      headerName: "Type",
      width: 150,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_type}</strong>,
    },
    {
      field: "ticket_description",
      headerName: "Description",
      width: 800,
      editable: false,
      renderCell: (params) => <strong>{params.row.ticket_description}</strong>,
    },
  ];

  //table components end

  const fetchUnitTickets = async () => {
    try {
      const response = await getUnitTicketsByPropertyUnitId(propertyUnitId);
      setUnitTickets(response);

      setIsLoading(false);

      console.log(response);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  //statistic data start
  //get the number of new tickets
  const getNewTicketsCount = async () => {
    try {
      const response = await getNewTicketCountByPropertyUnitId(propertyUnitId);
      console.log(response);
      setNewTicketsCount(response);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  //get the number of in progress tickets
  const getInProgressTicketsCount = async () => {
    try {
      const response = await getInProgressTicketCountByPropertyUnitId(
        propertyUnitId
      );
      console.log(response);
      setInProgressTicketsCount(response);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  //get the number of pending tickets
  const getPendingTicketsCount = async () => {
    try {
      const response = await getPendingTicketCountByPropertyUnitId(
        propertyUnitId
      );
      console.log(response);
      setPendingTicketsCount(response);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  //get the number of completed tickets
  const getCompletedTicketsCount = async () => {
    try {
      const response = await getCompletedTicketCountByPropertyUnitId(
        propertyUnitId
      );
      console.log(response);
      setCompletedTicketsCount(response);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  //call all the statistics
  const fetchUnitTicketsStatistics = async () => {
    getNewTicketsCount();
    getInProgressTicketsCount();
    getPendingTicketsCount();
    getCompletedTicketsCount();
  };

  //statistic data end

  useEffect(() => {
    try {
      //scroll to top
      window.scrollTo(0, 0);
      //need loading animation
      setIsLoading(true);

      fetchUnitTicketsStatistics();
      fetchUnitTickets();

      getAllTicketStatus();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchAllNewTickets = async () => {
    try {
      setIsNewTicketsLoading(true);
      devLog(`New tickets loading: true`);
      const response = await getNewTicketsByPropertyUnitId(propertyUnitId);
      devLog(`New tickets: ${response}`);

      // Handle empty state
      if (!response || response.length === 0) {
        setNewUnitTickets([]);
        setNewTicketsCount(0);
      } else {
        setNewUnitTickets(response);
        setNewTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
      setNewUnitTickets([]);
      setNewTicketsCount(0);
      devLog(`New tickets: []`);
    } finally {
      setIsNewTicketsLoading(false);
      devLog(`New tickets loading: false`);
    }
  };

  const fetchAllInProgressTickets = async () => {
    try {
      setIsInProgressTicketsLoading(true);
      devLog(`In Progress tickets loading: true`);
      const response = await getInProgressTicketsByPropertyUnitId(
        propertyUnitId
      );
      devLog(`In Progress tickets: ${response}`);

      if (!response || response.length === 0) {
        setInProgressUnitTickets([]);
        setInProgressTicketsCount(0);
      } else {
        setInProgressUnitTickets(response);
        setInProgressTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
      setInProgressUnitTickets([]);
      setInProgressTicketsCount(0);
      devLog(`In Progress tickets: []`);
    } finally {
      setIsInProgressTicketsLoading(false);
      devLog(`In Progress tickets loading: false`);
    }
  };

  const fetchAllPendingTickets = async () => {
    try {
      setIsPendingTicketsLoading(true);
      devLog(`Pending tickets loading: true`);
      const response = await getPendingTicketsByPropertyUnitId(propertyUnitId);
      devLog(`Pending tickets: ${response}`);

      if (!response || response.length === 0) {
        setPendingUnitTickets([]);
        setPendingTicketsCount(0);
      } else {
        setPendingUnitTickets(response);
        setPendingTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
      setPendingUnitTickets([]);
      setPendingTicketsCount(0);
      devLog(`Pending tickets: []`);
    } finally {
      setIsPendingTicketsLoading(false);
      devLog(`Pending tickets loading: false`);
    }
  };

  const fetchAllCompletedTickets = async () => {
    try {
      setIsCompletedTicketsLoading(true);
      devLog(`Completed tickets loading: true`);
      const response = await getCompletedTicketsByPropertyUnitId(
        propertyUnitId
      );
      devLog(`Completed tickets: ${response}`);

      if (!response || response.length === 0) {
        setCompletedUnitTickets([]);
        setCompletedTicketsCount(0);
      } else {
        setCompletedUnitTickets(response);
        setCompletedTicketsCount(response.length);
      }
    } catch (error) {
      console.error(error);
      setCompletedUnitTickets([]);
      setCompletedTicketsCount(0);
      devLog(`Completed tickets: []`);
    } finally {
      setIsCompletedTicketsLoading(false);
      devLog(`Completed tickets loading: false`);
    }
  };

  const getAllTicketStatus = async () => {
    fetchAllNewTickets();
    fetchAllInProgressTickets();
    fetchAllPendingTickets();
    fetchAllCompletedTickets();

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      {/*UI Start */}
      <div
        data-bs-theme={isDarkMode ? "dark" : "light"}
        className="page-content"
      >
        <div className="container-fluid">
          <PageTitleBox
            pageTitle="All Unit Tickets"
            previousPageTitle="Property Unit"
            previousPageLink={`/propertyunit/${propertyUnitId}`}
          />

          {/*UI Start */}

          <div className="row">
            <div className="col-xxl-3 col-sm-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">New Tickets</p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value" data-target="547">
                          {newTicketsCount}
                        </span>
                      </h2>
                      <p className="mb-0 text-muted" hidden>
                        <span className="badge bg-light text-success mb-0">
                          {" "}
                          <i className="ri-arrow-up-line align-middle"></i>{" "}
                          17.32 %{" "}
                        </span>{" "}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                          <i className="ri-ticket-2-line"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>{" "}
              {/* end card*/}
            </div>
            <div className="col-xxl-3 col-sm-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">
                        In Progress Tickets
                      </p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value" data-target="107">
                          {inProgressTicketsCount}
                        </span>
                      </h2>
                      <p className="mb-0 text-muted" hidden>
                        <span className="badge bg-light text-danger mb-0">
                          {" "}
                          <i className="ri-arrow-down-line align-middle"></i>{" "}
                          3.87 %{" "}
                        </span>{" "}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                          <i className="ri-shopping-bag-line"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/*end col*/}
            {/*end col*/}
            <div className="col-xxl-3 col-sm-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">
                        Pending Tickets
                      </p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value" data-target="124">
                          {pendingTicketsCount}
                        </span>
                      </h2>
                      <p className="mb-0 text-muted" hidden>
                        <span className="badge bg-light text-danger mb-0">
                          {" "}
                          <i className="ri-arrow-down-line align-middle"></i>{" "}
                          0.96 %{" "}
                        </span>{" "}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                          <i className="mdi mdi-timer-sand"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/*end col*/}

            <div className="col-xxl-3 col-sm-6">
              <div className="card card-animate">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">
                        Completed Tickets
                      </p>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        <span className="counter-value" data-target="15.95">
                          {completedTicketsCount}
                        </span>
                      </h2>
                      <p className="mb-0 text-muted" hidden>
                        <span className="badge bg-light text-success mb-0">
                          {" "}
                          <i className="ri-arrow-up-line align-middle"></i> 1.09
                          %{" "}
                        </span>{" "}
                        vs. previous month
                      </p>
                    </div>
                    <div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-info-subtle text-info rounded-circle fs-4">
                          <i className="ri-delete-bin-line"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card body */}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          {isKanbanView ? (
            <TicketsKanbanBoard
              isKanbanView={isKanbanView}
              toggleView={toggleView}
              newTicketsCount={newTicketsCount}
              inProgressTicketsCount={inProgressTicketsCount}
              pendingTicketsCount={pendingTicketsCount}
              completedTicketsCount={completedTicketsCount}
              newUnitTickets={newUnitTickets}
              inProgressUnitTickets={inProgressUnitTickets}
              pendingUnitTickets={pendingUnitTickets}
              completedUnitTickets={completedUnitTickets}
              propertyUnitId={propertyUnitId}
              isNewTicketsLoading={isNewTicketsLoading}
              isInProgressTicketsLoading={isInProgressTicketsLoading}
              isPendingTicketsLoading={isPendingTicketsLoading}
              isCompletedTicketsLoading={isCompletedTicketsLoading}
            />
          ) : (
            <TicketsList
              isKanbanView={isKanbanView}
              toggleView={toggleView}
              unitTickets={unitTickets}
              ticketColumns={ticketColumns}
              propertyUnitId={propertyUnitId}
            />
          )}
          {/*UI End */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllUnitTickets;
