import http from "../../services/general/httpService";
import config from "../../constants/config.json";

///contractor/create_contractor
export async function createContractor(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/create_contractor`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
}

//update_contractor
export async function updateContractor(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/update_contractor`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
}

//get_contractor_by_id
export async function getContractorById(contractor_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractor_by_id`;
     const {data: result} = await http.post(fullApiEndpoint, {contractor_id: contractor_id});
    
     return result;
}

//get_contractor_by_email_address_and_portfolio_or_property_id
export async function getContractorByEmailAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractor_by_email_address_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_contractor_by_company_name_and_portfolio_or_property_id
export async function getContractorByCompanyNameAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractor_by_company_name_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_contractors_by_portfolio_id
export async function getContractorsByPortfolioId(portfolio_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_portfolio_id`;
     const {data: result} = await http.post(fullApiEndpoint, {portfolio_id: portfolio_id});
    
     return result;
}

//get_contractors_by_property_id
export async function getContractorsByPropertyId(property_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, {property_id: property_id});
    
     return result;
}

//get_contractors_by_company_license_and_portfolio_or_property_id
export async function getContractorsByCompanyLicenseAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_company_license_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_contractors_by_company_insurance_and_portfolio_or_property_id
export async function getContractorsByCompanyInsuranceAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_company_insurance_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_contractors_by_company_license_not_none_and_portfolio_or_property_id
export async function getContractorsByCompanyLicenseNotNoneAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_company_license_not_none_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_contractors_by_company_insurance_not_none_and_portfolio_or_property_id
export async function getContractorsByCompanyInsuranceNotNoneAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_company_insurance_not_none_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_contractors_by_company_insurance_expired_and_portfolio_or_property_id
export async function getContractorsByCompanyInsuranceExpiredAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_company_insurance_expired_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//get_contractors_by_company_license_near_expiration_and_portfolio_or_property_id
export async function getContractorsByCompanyLicenseNearExpirationAndPortfolioOrPropertyId(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_by_company_license_near_expiration_and_portfolio_or_property_id`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//create_sub_contractor
export async function createSubContractor(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/create_sub_contractor`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
}

//get_sub_contractor_by_details
export async function getSubContractorByDetails(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_sub_contractor_by_details`;
     const {data: result} = await http.post(fullApiEndpoint, data);

        return result;
}

//update_sub_contractor
export async function updateSubContractor(data) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/update_sub_contractor`;
     const {data: result} = await http.post(fullApiEndpoint, data);
    
     return result;
}

//get_sub_contractors_by_contractor_id
export async function getSubContractorsByContractorId(contractor_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_sub_contractors_by_contractor_id`;
     const {data: result} = await http.post(fullApiEndpoint, {contractor_id: contractor_id});
    
     return result;
}

//get_sub_contractor_by_id
export async function getSubContractorById(sub_contractor_id) {
      
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_sub_contractor_by_id`;
     const {data: result} = await http.post(fullApiEndpoint, {sub_contractor_id: sub_contractor_id});
    
     return result;
}

//get_contractors_linked_to_user
export async function getContractorsLinkedToUser() {
     const user_id = localStorage.getItem(config.user_id);
     const fullApiEndpoint = config.apiEndpoint + `/contractor/get_contractors_linked_to_user`;
     const {data: result} = await http.post(fullApiEndpoint, {user_id: user_id});
    
     return result;
}